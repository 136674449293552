import {
  Box,
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Separator } from "components/Separator/Separator";
import React from "react";

const styles = {
  cancelBtn: {
    lineHeight: "24px",
    fontWeight: "600",
    borderRadius: "15px",
    backgroundColor: "#edf2f7",
    height: "40px",
    alignItems: "center",
    padding: "0px 16px",
    fontSize: "16px",
    color: "#1a202c",
    fontFamily: "Inter",
  },
};

export const AreYouSureModal = ({
  handleSubmit,
  isOpen,
  onClose,
  status,
  msg,
  btnText,
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent p={"20px"} borderRadius={"15px"}>
          <ModalHeader p={"0px"} mb={"10px"}>
            Please confirm
          </ModalHeader>
          <Separator />
          <Box mt={"10px"}>
            <Text>{msg}</Text>
          </Box>
          <ModalFooter mt={"10px"}>
            <Button
              colorScheme="login"
              mr={3}
              onClick={() => {
                // //console.log(status);
                handleSubmit(status);
                onClose();
              }}
            >
              {btnText}
            </Button>
            <Button sx={styles.cancelBtn} mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
