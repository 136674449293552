import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalContent,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { styles } from "./index.style.module";
import { FilterBar } from "./components/filterBar/FilterBar";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "./slice";
import {actions as reviewAction,reducer as reviewReducer, sliceKey as reviewSliceKey} from "views/Workflow/pages/reviewWorkflow/slice"
import { myApprovalSaga } from "./saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import { useEffect } from "react";
import { auditorNameArray } from "views/Variables/variables";
import { Pagination, Table } from "@agnext/reactlib";
import { pendingApprovalHeaders } from "views/Variables/variables";
import { ActionIcon } from "components/Icons/Icons";
import { useWarehouseFilter } from "../../../Dashboard/context/WarehouseFilterContext";
import { useReviewAddFilter } from "views/Users/pages/myApprovals/context/ReviewAddFilterContext";
import { ActionBtnDropdown } from "./components/actionBtnDropdown/ActionBtnDropdown";
import { format } from "date-fns";
import { calculateTotalPages } from "utils/commonFunctions";
import { capitalizeStringUpdated } from "utils/commonFunctions";
import { Link } from "react-router-dom";
import { ViewReportIcon } from "components/Icons/Icons";
import reviewSvg from "assets/svg/review.svg";
import { workflowDataSaga } from "views/Workflow/pages/reviewWorkflow/saga";
export const Inspections = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myApprovalSaga });
  useInjectReducer({ key: reviewSliceKey, reducer: reviewReducer });
  useInjectSaga({ key: reviewSliceKey, saga: workflowDataSaga });
  // const [filterQuery, setFilterQuery] = useState({
  //   location: "",
  //   clientName: "",
  //   auditor: "",
  // });
  const [submittedPage, setSubmittedPage] = useState(0);

  // const { clientName, location, auditor } = filterQuery;
  const { selectedWarehouse } = useWarehouseFilter();
  const { query } = useReviewAddFilter();
  // const [modalState, setModalState] = useState({
  //   isOpen: false,
  //   inspectionId: null,
  // });
  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });

  // const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
  //   x: 0,
  //   y: 0,
  // });

  const { fromDate, toDate } = filters;

  // const handleOpenModal = (e, inspectionId, status, reportName) => {
  //   e.preventDefault(); // Prevent default link behavior
  //   e.stopPropagation();
  //   const iconPosition = e.currentTarget.getBoundingClientRect();

  //   setSelectedActionIconPosition({
  //     x: iconPosition.left,
  //     y: iconPosition.top,
  //   });
  //   setModalState({
  //     isOpen: true,
  //     inspectionId: inspectionId,
  //   });
  // };

  // const handleCloseModal = () => {
  //   setModalState({
  //     isOpen: false,
  //     inspectionId: null,
  //   });
  // };

  const handleFromDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };

  let warehouses = useSelector(selectors.selectWarehouseData) || [];

  let dispatch = useDispatch();

  useEffect(() => {
    const requiredWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouse
    );
    const warehouseId = selectedWarehouse ? requiredWarehouse.id : null;
    const forQueryParams = {
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
    };
    const submittedAnalyticsDataParams = {
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
      status: "submitted",
      page: submittedPage,
    };
    dispatch(
      actions.fetchSubmittedInspectionData({
        payload: submittedAnalyticsDataParams,
      })
    );
    dispatch(
      actions.fetchWarehouses({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchSubmittedDataWithoutPages({
        payload: submittedAnalyticsDataParams,
      })
    );
  }, [
    // clientName,
    // location,
    selectedWarehouse,
    fromDate,
    toDate,
    submittedPage,
    query,
  ]);
  useEffect(()=>{
    dispatch(
      reviewAction.clearData())
  },[])
  const submittedArray = useSelector(selectors.selectSubmittedInspectionData);
  const submittedArrayWithoutPages = useSelector(
    selectors.selectSubmittedDataWithoutPages
  );

  const submittedArrayTotalPages = calculateTotalPages(
    submittedArrayWithoutPages?.length,
    11
  );

  const handlePageSelect = (pageNumber) => {
    setSubmittedPage(pageNumber);
  };

  const handleNextPage = () => {
    if (submittedPage === submittedArrayTotalPages - 1) return;
    setSubmittedPage(submittedPage + 1);
  };

  const handlePreviousPage = () => {
    if (submittedPage === 0) return;
    setSubmittedPage(submittedPage - 1);
  };

  let headerArray = pendingApprovalHeaders;

  let tableData =
    submittedArray &&
    submittedArray.map((inspection, i) => {
      const {
        auditDate,
        originName,
        orgName,
        originLocation,
        auditorName,
      } = inspection;

      // Updating inspectionDate and removing the time
      const updatedAuditDate = format(new Date(auditDate), "yyyy-MM-dd");
      const updateOriginName = capitalizeStringUpdated(originName);
      const updatedOriginLocation = capitalizeStringUpdated(originLocation);
      const updatedOrgName = capitalizeStringUpdated(orgName);
      const updatedAuditorName = auditorName
        ? capitalizeStringUpdated(auditorName)
        : "NA";

      return {
        ...inspection,
        auditDate: updatedAuditDate,
        originName: updateOriginName,
        orgName: updatedOrgName,
        auditorName: updatedAuditorName,
        originLocation: updatedOriginLocation,
        action: (
          <Tooltip label="Review" alignSelf={"center"}>
            <Link
              to={`/admin/my-approval/inspections/reviewing/${inspection?.id}`}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Image src={reviewSvg} />
            </Link>
          </Tooltip>
        ),
      };
    });

  const handleApplyFilter = () => {
    setSubmittedPage(0);
  };

  return (
    <>
      <Box mt={"75px"} display={"flex"} flexDirection={"column"} gap={"36px"}>
        {/* <HStack justifyContent={"space-between"}> */}
        <Text sx={styles.pendingApprovals}>
          {`${submittedArrayWithoutPages?.length} Pending Approval`}
        </Text>
        {/* </HStack> */}
        <FilterBar
          warehouses={warehouses}
          auditorNameArray={auditorNameArray}
          filters={filters}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          submittedDropdown={submittedArrayWithoutPages}
          handleApplyFilter={handleApplyFilter}
        />
        <Box sx={styles.container}>
          <Table
            headerList={headerArray}
            dataList={tableData}
            overflow="auto"
            stickyColumn={["Actions"]}
          />
        </Box>
        {/* {modalState.isOpen && (
          <Modal
            isOpen={modalState.isOpen}
            onClose={handleCloseModal}
            display="flex"
          >
            <ModalContent
              sx={styles.viewReportDropdown}
              top={selectedActionIconPosition.y - 40 + "px"}
              left={selectedActionIconPosition.x - 260 + "px"}
              w="270px"
            >
              <ActionBtnDropdown
                handleCloseModal={handleCloseModal}
                inspectionId={modalState.inspectionId}
              />
            </ModalContent>
          </Modal>
        )} */}
        {/* {viewReportModal.isOpen && (
          <Modal
            isOpen={viewReportModal.isOpen}
            onClose={handleCloseViewReportModal}
            display="flex"
          >
            <ModalContent
              sx={styles.viewReportDropdown}
              top={selectedActionIconPosition.y - 40 + "px"}
              left={selectedActionIconPosition.x - 260 + "px"}
              w="270px"
            >
              <ActionBtnDropdownForViewReport
                handleCloseModal={handleCloseViewReportModal}
                inspectionId={modalState.inspectionId}
                reportName={viewReportModal.reportName}
                onClickViewReport={onClickViewReport}
              />
            </ModalContent>
          </Modal>
        )} */}
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} w={"100%"} mt={"10px"}>
        <Pagination
          page={submittedPage}
          numPages={submittedArrayTotalPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Box>
    </>
  );
};
