import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { CopyIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function Duplicate({ duplicateHandler }) {
  return (
    <>
      <Button
        leftIcon={<CopyIcon color="main.icon" />}
        colorScheme="login"
        variant="ghost"
        onClick={duplicateHandler}
        // label="Duplicate"
      />
    </>
  );
}

export default Duplicate;
