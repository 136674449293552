// Chakra imports
import { Box, ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Configurator from "components/Configurator/Configurator";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import routes from "routes.js";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
import { useSidebar } from "../context/SidebarContext";
import { userStorage } from "utils/helper";

export default function Dashboard(props) {
  const { sidebarOpen } = useSidebar();
  const { ...rest } = props;
  const history = useHistory();
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  let loginObject = userStorage.get();
  useEffect(() => {
    let sasToken = JSON.parse(localStorage.getItem("inspectionDashboard"));

    if (sasToken) {
      const now = new Date();
      const currentTime = now.setTime(now.getTime() + 0);
      const isTokenExpire = sasToken.expiry < currentTime;
      if (isTokenExpire) {
        localStorage.removeItem("inspectionDashboard");
        sasToken = null;
        history.push("/auth/signin");
      }
    }
    if (!sasToken) {
      history.push("/auth/signin");
    }
  }, []);
  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].name === "View Workflow") {
            return "View Workflow";
          }
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const userRoles = userStorage.get()?.roles || [];
  const isAuditor = userRoles.includes("auditor");
  const isClient = userRoles.includes("client");
  const isSuperAdmin = userRoles.includes("super-admin");
  const isManager = userRoles.includes("manager");
  const auditorArr = ["Bank Audit", "Account", "Bank Audit "];
  const clientArr = [
    "Dashboard",
    "Inspections",
    "Notifications",
    "Account",
    "Warehouses",
    "Collateral Managers",
  ];
  const managerArr = [...clientArr, "Pending Approvals", "Review Form"];
  const getRoutes = (routes) => {
    return routes.map((route, key) => {
      if (route.layout === "/admin") {
        if (isAuditor && auditorArr.includes(route.name)) {
          return (
            <Route
              path={route.layout + route.path}
              component={route.component}
              key={key}
            />
          );
        } else if (isClient && clientArr.includes(route.name)) {
          return (
            <Route
              path={route.layout + route.path}
              component={route.component}
              key={key}
            />
          );
        } else if (isSuperAdmin) {
          if (route.name !== "Bank Audit") {
            return (
              <Route
                path={route.layout + route.path}
                component={route.component}
                key={key}
              />
            );
          }
        } else if (isManager && managerArr.includes(route.name)) {
          return (
            <Route
              path={route.layout + route.path}
              component={route.component}
              key={key}
            />
          );
        }
      } else {
        return (
          <Route
            path={route.layout + route.path}
            component={route.component}
            key={key}
          />
        );
      }
    });
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  // Chakra Color Mode
  return (
    <ChakraProvider theme={theme} resetCss={false}>
      {/* { loginObject?.role  */}
      {/* {userStorage.get()?.id === "fae0fcc1-3935-4ffa-98b6-1ad63bca8819" ? (
        ""
      ) : ( */}
      <Sidebar
        // brandText={getActiveRoute(routes)}

        display="none"
        {...rest}
      />
      {/* )} */}
      <MainPanel
        className="main-panel"
        w={{
          base: "100%",
          xl:
            // loginObject?.role
            sidebarOpen ? "calc(100% - 265px)" : "93%",
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={getActiveRoute(routes)}
            // secondary={getActiveNavbar(routes)}
            fixed={fixed}
            {...rest}
          />
        </Portal>
        {getRoute() ? (
          <PanelContent>
            <Box p={{ sm: "16px", md: "20px" }}>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect
                    from="/admin"
                    to={
                      userStorage.get()?.roles[0] === "auditor"
                        ? "/admin/view-form"
                        : "/admin/dashboard"
                    }
                  />
                </Switch>
              </PanelContainer>
            </Box>
          </PanelContent>
        ) : null}
        {/* <Footer /> */}
        <Portal>
          <FixedPlugin
            // secondary={getActiveNavbar(routes)}
            fixed={fixed}
            onOpen={onOpen}
          />
        </Portal>
        <Configurator
          // secondary={getActiveNavbar(routes)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={(value) => {
            setFixed(value);
          }}
          onOpaque={() => setSidebarVariant("opaque")}
          onTransparent={() => setSidebarVariant("transparent")}
        />
      </MainPanel>
    </ChakraProvider>
  );
}
