import React, { useCallback, useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { workflowDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import HeaderGray from "../../components/headerGray";
import {
  HStack,
  VStack,
  Text,
  Box,
  Image,
  useMediaQuery,
  Stack,
  CircularProgress,
} from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import Section from "./section";
import qualixLogo from "assets/qualix_logo.svg";
import { convertToCamelCase, generateUUId } from "utils/utils";
import { postData } from "services/index";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { userStorage } from "utils/helper";
import perfeqtLogo from "assets/perfeqt Logoo-02.png";

function hasPageNumber(arr, pageNumber) {
  let res = -1;
  arr.forEach((el, i) => {
    if (el.pageNumber === pageNumber) {
      res = i;
    }
  });
  return res;
}

export default function GenerateTemplate({ isFor }) {
  const { id } = useParams();
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: workflowDataSaga });

  const dispatch = useDispatch();
  const history = useHistory();

  const [formData, setFormData] = useState({});

  const [formErrorData, setFormErrorData] = useState({});

  const [tempError, setTempError] = useState(false);

  const [page, setPage] = useState(0);

  const [pageStatus, setPageStatus] = useState({});

  const [skippedPages, setSkippedPages] = useState({ from: 0, to: 0 });

  const [isSubmit, setIsSubmit] = useState(false);

  const [prevPages, setPrevPages] = useState([]);

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [smallerScreen] = useMediaQuery("(max-width: 1300px)");

  const workflow = useSelector(selectors.selectWorkflow);

  const [displayData, setDisplayData] = useState([]);

  const [navIndex, setNavIndex] = useState(null);

  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );

  const pageInfoWeb = useSelector(selectors.selectLastUpdatedPages);
  const [pageInfoMobile, setPageInfoMobile] = useState({});

  const workflowLoading = useSelector(selectors.selectWorkflowLoading);

  // let { id } = useParams();

  const getElementsState = useCallback(
    (inspectionId) => {
      const elements = componentsForElements.reduce((acc, cv) => {
        const elementsCurr = cv.structure.elements.map((element) => element);
        elementsCurr.forEach((currEl) => {
          acc[currEl.id] = {
            title: currEl.label,
            name: convertToCamelCase(currEl.label),
            value: "",
            attachments: [],
            itemType: currEl.htmlType,
            mimeType: "NA",
            itemOrder: currEl.order,
            parentId: cv.id,
            parentType: "component",
            parentInfo: {
              id: cv.id,
              title: cv.title,
            },
            id: generateUUId(),
            workflowId: workflow[0].id,
            templateItemId: currEl.id,
            itemInfo: currEl.properties ? currEl.properties : {},
            inspectionId: inspectionId,
            mandatory: currEl.mandatory,
            tableName: "inspectionItems",
          };
        });
        return acc;
      }, {});
      return elements;
    },
    [componentsForElements, workflow]
  );

  const getMandatoryStatusInElementState = useCallback(
    ({ newFormData, components }) => {
      const elements = components.reduce((acc, cv) => {
        const elementsCurr = cv.structure.elements.map((element) => element);
        elementsCurr.forEach((currEl) => {
          acc[currEl.id] = {
            ...newFormData[currEl.id],
            mandatory: currEl.mandatory,
          };
        });
        return acc;
      }, {});
      return elements;
    },
    []
  );

  const getElements = useCallback(
    (sectionId) => {
      let elementDisplayData = [];
      if (
        sectionsForBlocks &&
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        sectionsForBlocks.forEach((section) => {
          section.structure.blocks.forEach((block) => {
            blocksForComposites.forEach((blockForComposites) => {
              if (block.id === blockForComposites.id) {
                blockForComposites.structure.composites.forEach((composite) => {
                  compositesForComponents.forEach((compositeForComponents) => {
                    if (composite.id === compositeForComponents.id) {
                      compositeForComponents.structure.components.forEach(
                        (component) => {
                          componentsForElements.forEach(
                            (componentForElements) => {
                              if (componentForElements.id === component.id) {
                                componentForElements.structure.elements.forEach(
                                  (element) => {
                                    elementDisplayData.push({
                                      sectionId: section.id,
                                      element,
                                    });
                                  }
                                );
                              }
                            }
                          );
                        }
                      );
                    }
                  });
                });
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.sectionId === sectionId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [
      sectionsForBlocks,
      blocksForComposites,
      compositesForComponents,
      componentsForElements,
    ]
  );

  const getDisplayData = useCallback(
    (changePageStatus = false) => {
      let displayData = [];
      let newPageStatus = { ...pageStatus };
      if (sectionsForBlocks) {
        sectionsForBlocks.forEach((section) => {
          if (hasPageNumber(displayData, section.pageNumber) !== -1) {
            const newSections =
              displayData[hasPageNumber(displayData, section.pageNumber)]
                .sections;
            newSections.push(section);
            displayData[
              hasPageNumber(displayData, section.pageNumber)
            ].sections = newSections;
          } else {
            if (changePageStatus) {
              if (section.pageNumber === 1) {
                newPageStatus[section.pageNumber] = true;
              } else {
                newPageStatus[section.pageNumber] = false;
              }
            }
            displayData.push({
              pageNumber: section.pageNumber,
              sections: [section],
            });
          }
        });
      }
      displayData = displayData.sort((a, b) => a.pageNumber - b.pageNumber);
      return { displayData, newPageStatus };
    },
    [sectionsForBlocks]
  );

  useEffect(() => {
    if (isFor === "web") {
      //console.log("running for web");
      if (pageInfoWeb && pageInfoWeb.page) {
        setPage(pageInfoWeb.page);
        if (pageInfoWeb.prevPages) {
          setPrevPages(pageInfoWeb.prevPages);
        }
        if (pageInfoWeb.pageStatus) {
          setPageStatus(pageInfoWeb.pageStatus);
        }
      }
    } else if (isFor === "mobile") {
      //console.log(pageInfoMobile);
      // if (pageInfoMobile && pageInfoMobile.page) {
      //   if (pageInfoMobile.pageStatus) {
      //     setPageStatus(pageInfoMobile.pageStatus);
      //   }
      //   setPage(pageInfoMobile.page);
      //   if (pageInfoMobile.prevPages) {
      //     setPrevPages(pageInfoMobile.prevPages);
      //   }
      // }
      if (pageInfoWeb && pageInfoWeb.page) {
        setPage(pageInfoWeb.page);
        if (pageInfoWeb.prevPages) {
          setPrevPages(pageInfoWeb.prevPages);
        }
        if (pageInfoWeb.pageStatus) {
          setPageStatus(pageInfoWeb.pageStatus);
        }
      }
    }
  }, [pageInfoWeb, pageInfoMobile]);

  useEffect(() => {
    let newPageStatus = { ...pageStatus };
    newPageStatus[page + 1] = true;
    setPageStatus(newPageStatus);
  }, [page]);

  //console.log(pageInfoWeb);

  useEffect(() => {
    if (
      skippedPages.from &&
      skippedPages.to &&
      skippedPages.from < skippedPages.to - 1
    ) {
      let newPageStatus = { ...pageStatus };
      for (let i = skippedPages.from + 1; i < skippedPages.to; i++) {
        newPageStatus[i] = false;
      }
      setPageStatus(newPageStatus);
    }
  }, [skippedPages]);
  useEffect(() => {
    const { displayData } = getDisplayData();
    let newFormData = { ...formData };
    let i = 0;
    displayData.forEach((data) => {
      if (!pageStatus[data.pageNumber]) {
        data.sections.forEach((section) => {
          getElements(section.id).forEach((element) => {
            if (i == 0) {
              i++;
              newFormData = {
                ...newFormData,
                [element.element.id]: {
                  ...newFormData[element.element.id],
                  value: "",
                  attachments: [],
                },
              };
            } else {
              newFormData = {
                ...newFormData,
                [element.element.id]: {
                  ...newFormData[element.element.id],
                  value: "",
                  attachments: [],
                },
              };
            }
          });
        });
      }
    });
    setFormData(newFormData);
  }, [pageStatus]);

  useEffect(() => {
    setDisplayData(getDisplayData().displayData);
    // if (isFor === "mobile" && !(pageInfoMobile && pageInfoMobile.page)) {
    //   setPageStatus(getDisplayData(true).newPageStatus);
    // }
    if (
      (isFor === "web" || isFor === "mobile") &&
      !(pageInfoWeb && pageInfoWeb.page)
    ) {
      setPageStatus(getDisplayData(true).newPageStatus);
    }
  }, [getDisplayData]);

  useEffect(() => {
    if (isFor === "web") setFormData(getElementsState());
  }, [getElementsState]);

  const handleFormDataChange = (e, val, id, mimeType) => {
    setTempError(false);

    if (_.isArray(val)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: [...val],
          mimeType,
        },
      }));
      return;
    }
    if (_.isArray(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: e,
          mimeType,
        },
      }));
      return;
    }
    if (_.isString(e) || _.isDate(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: e,
        },
      }));
      return;
    }
    if (val && id) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: val,
        },
      }));
      return;
    }
    const { name, value, checked } = e.target;
    if (!val) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value || value === "" || value === 0 ? value : checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: val,
        },
      }));
    }
  };

  const getErrorState = useCallback(() => {
    let errorState = {};

    getDisplayData().displayData.forEach((elementData) => {
      const pageNumber = elementData.pageNumber;
      errorState[pageNumber] = {};
      const elements = [];
      elementData.sections.forEach((section) => {
        const newElements = getElements(section.id);
        elements.push(...newElements);
      });
      elements.forEach((element) => {
        errorState[pageNumber][element.element.id] = "";
      });
    });
    return errorState;
  }, [getDisplayData, getElements]);

  useEffect(() => {
    setFormErrorData(getErrorState());
  }, [getErrorState]);

  const handleValidate = (pageNumber) => {
    let isValid = true;
    Object.keys(formErrorData[pageNumber]).forEach((key) => {
      if (
        formData[key].mandatory &&
        !formData[key].value &&
        !formData[key].attachments.length
      ) {
        isValid = false;
        let newFormErrorData = { ...formErrorData };
        newFormErrorData[pageNumber][key] = "This is a required field.";
        setFormErrorData(newFormErrorData);
        return isValid;
      }
    });
    return isValid;
  };

  const handleSubmit = async (status) => {
    if (isFor == "web") {
      setIsSubmit(true);
    } else {
      if (status === "submitted") {
        setIsSubmit(true);
      }
    }

    let authToken = null;
    if (isFor !== "web") {
      const token = localStorage.getItem("token");
      authToken = JSON.parse(token);
    }

    let data = [];
    Object.keys(formData).forEach((el) => {
      data.push(formData[el]);
    });

    //These changes are used for temporary basis for godfrey demo & Amul demo(Patchwork).
    if (isFor !== "web" && (id === "0w8vf5o0wlegxbl4" || id === "8bk6b3tbzxp6xlnh" || id === "d8a4joybjndpg8l0" || id === "h0tvb3n9lstnxpai")) {
      data = data.map((obj) => {
        if (obj.name === "storeCode") {
          return { ...obj, name: "warehouseCode" };
        } else if (obj.name === "nameOfTheOrganization") {
          return { ...obj, name: "nameOfTheBank" };
        } else if(obj.name === "conductedOn" || obj.name === "dateAndTimeOfInspection") {
          return { ...obj, name: "auditDate" };
        }
        return obj;
      });
    }
    const commonData = {
      status,
      data,
      pageInfo: status === "draft" ? { page, pageStatus, prevPages } : null,
    };
    const postRes =
      isFor === "web"
        ? await postData(commonData)
        : await postData({ ...commonData, token: authToken });

    if (postRes?.data?.success === true && status === "submitted") {
      //Do not remove this console log as it is being used as a flag for android app.
      isFor === "web"
        ? history.push("/admin/form-submitted")
        : console.log("Form Submitted Successfully");
      setIsSubmit(false);
    }
    if (postRes?.data?.success === false && status === "submitted") {
      //Do not remove this console log as it is being used as a flag for android app.
      console.log("Form Submission Failed");
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    const setFormDataOnInitialLoad = ({ data, components }) => {
      let newFormData = { ...formData };
      data.forEach((element) => {
        const templateItemId = element.templateItemId;
        newFormData = {
          ...newFormData,
          [templateItemId]: {
            ...element,
          },
        };
      });
      const newFormDataWithMandatoryStatus = getMandatoryStatusInElementState({
        newFormData,
        components,
      });
      setFormData(newFormDataWithMandatoryStatus);
    };
    const inspectionId = localStorage.getItem("inspectionId");

    // const pageInfoJSON = localStorage.getItem("pageInfo");

    const token = localStorage.getItem("token");
    const authToken = token && JSON.parse(token);

    // if (pageInfo && pageInfo.page) {
    //   setPageInfoMobile(pageInfo);
    // }
    //117f3710-3861-4470-a406-0aeb96ebfb67 orgname amul
    //535206df-72ea-4323-8bd7-defc4a87893b orgname "amul "
    //d2d84095-5af2-4217-8025-d88c1395fd7d orgname "godfrey"
    

    const formId =
      isFor == "web"
        ? userStorage?.get()?.id === "fae0fcc1-3935-4ffa-98b6-1ad63bca8819"
          ? "8bk6b3tbzxp6xlnh" //counterfeit
          : "0w8vf5o0wlegxbl4" //retail audit
        : id;
    // h0xpf4h5l2s5xrv4 kotak
    if (isFor === "web") {
      dispatch(
        actions.fetchWorkflowData({
          id: formId,
          onSuccess: (components) => {
            if (inspectionId) {
              if (isFor === "web") {
                dispatch(
                  actions.fetchSingleInspection({
                    payload: [JSON.parse(inspectionId)],
                  })
                );
              }
              dispatch(
                actions.fetchReviewData({
                  payload: [JSON.parse(inspectionId)],
                  onSuccess: setFormDataOnInitialLoad,
                  components,
                })
              );
            } else {
              //console.log("set Element state was called");
            }
          },
        })
      );
    } else {
      dispatch(
        actions.fetchWorkflowData({
          id: formId,
          token: authToken,
          onSuccess: (components) => {
            if (inspectionId) {
              dispatch(
                actions.fetchSingleInspection({
                  payload: [JSON.parse(inspectionId)],
                })
              );

              dispatch(
                actions.fetchReviewData({
                  payload: [JSON.parse(inspectionId)],
                  onSuccess: setFormDataOnInitialLoad,
                  components,
                })
              );
            } else {
              //console.log("set Element state was called");
            }
          },
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (!localStorage.getItem("inspectionId")) {
      setFormData(getElementsState(generateUUId()));
    }
  }, [getElementsState]);

  if (!isFor === "web" && workflowLoading) {
    return (
      <HStack>
        <CircularProgress
          isIndeterminate
          color="#4FD1C5"
          size="26px"
          alignSelf={"center"}
        />
        <Text>Loading...</Text>
      </HStack>
    );
  }

  return (
    <Box mt={isFor === "web" ? "75px" : "0px"} w="100%">
      <HeaderGray
        workflow={workflow && workflow[0]}
        pageNumber={page}
        numPages={getDisplayData().displayData.length}
      />
      <Box w="100%" style={{ background: "#F9F9FB", position: "relative" }}>
        {smallerScreen ? (
          <></>
        ) : isFor === "web" ? (
          <VStack
            justify="flex-end"
            align="flex-start"
            gap="2px"
            style={{ position: "absolute", left: "72px", bottom: "64px" }}
          >
            <Text
              color="#4A5568"
              fontSize="14px"
              fontWeight="400"
              lineHeight="1.4"
            >
              Powered by
            </Text>
            <Image src={perfeqtLogo} alt="perfeqt logo" w="80px" h="60px" />
          </VStack>
        ) : (
          <></>
        )}
        <VStack
          w={isMobileScreen ? "100%" : "740px"}
          style={{
            margin: "0 auto",
            padding: "64px 24px",
          }}
          gap="64px"
          area-label="sections"
          justify="flex-start"
          align="flex-start"
        >
          {displayData &&
            displayData[page] &&
            displayData[page].sections.map((section) => {
              return (
                <Section
                  key={section.id}
                  section={section}
                  data={getElements(section.id)}
                  formData={formData}
                  formErrorData={formErrorData[page + 1]}
                  handleFormDataChange={handleFormDataChange}
                  displayData={displayData}
                  setPage={setPage}
                  navIndex={navIndex}
                  setNavIndex={setNavIndex}
                  tempError={tempError}
                  setSkippedPages={setSkippedPages}
                  prevPages={prevPages}
                  page={page}
                />
              );
            })}
          {tempError ? (
            <HStack w="100%" justify="center" align="center">
              <Text
                aria-label="element label"
                color="#D93025"
                fontSize="14px"
                fontWeight="400"
                lineHeight="1.4"
                textAlign="center"
              >
                Please, Fill in all the mandatory fields.
              </Text>
            </HStack>
          ) : (
            <></>
          )}
          {page === getDisplayData().displayData.length - 1 ? (
            <>
              {page !== 0 ? (
                <HStack gap="16px" w="100%">
                  <Button
                    label={"Back"}
                    colorScheme="login"
                    variant="outline"
                    size="sm"
                    sx={{ width: "100%" }}
                    onClick={() => {
                      setPage(
                        prevPages.length
                          ? prevPages[prevPages.length - 1]
                          : page - 1
                      );
                      if (prevPages.length) {
                        const newPrevPages = [...prevPages];
                        newPrevPages.pop();
                        setPrevPages(newPrevPages);
                      }
                    }}
                  />
                  <Button
                    isDisabled={Object.values(formData).some((element) => {
                      return element?.parentInfo?.title === "Disclaimer" &&
                        element.value ===
                          "No, I would like to revisit my response"
                        ? true
                        : false;
                    })}
                    label={"Submit"}
                    colorScheme="login"
                    size="sm"
                    sx={{ width: "100%" }}
                    onClick={() => {
                      if (handleValidate(page + 1)) {
                        handleSubmit("submitted");
                      } else {
                        setTempError(true);
                      }
                    }}
                  />
                </HStack>
              ) : (
                <Button
                  isDisabled={Object.values(formData).some((element) => {
                    return element?.parentInfo?.title === "Disclaimer" &&
                      element.value ===
                        "No, I would like to revisit my response"
                      ? true
                      : false;
                  })}
                  label={"Submit"}
                  colorScheme="login"
                  size="sm"
                  sx={{ width: "100%" }}
                  onClick={() => {
                    if (handleValidate(page + 1)) {
                      handleSubmit("submitted");
                    } else {
                      setTempError(true);
                    }
                  }}
                />
              )}
            </>
          ) : page === 0 ? (
            <Button
              label={"Next"}
              colorScheme="login"
              size="sm"
              sx={{ width: "100%" }}
              onClick={() => {
                if (handleValidate(page + 1)) {
                  setPage(page + 1);
                  setPrevPages([...prevPages, page]);
                  handleSubmit("draft");
                } else {
                  setTempError(true);
                }
              }}
            />
          ) : (
            <HStack gap="16px" w="100%">
              <Button
                label={"Back"}
                colorScheme="login"
                variant="outline"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  setPage(
                    prevPages.length
                      ? prevPages[prevPages.length - 1]
                      : page - 1
                  );
                  if (prevPages.length) {
                    const newPrevPages = [...prevPages];
                    newPrevPages.pop();
                    setPrevPages(newPrevPages);
                    setNavIndex(null);
                  }
                }}
              />
              <Button
                label={"Next"}
                colorScheme="login"
                size="sm"
                sx={{ width: "100%" }}
                onClick={() => {
                  // //console.log({ validation: handleValidate(page + 1) });
                  if (handleValidate(page + 1)) {
                    setPage(navIndex ? navIndex : page + 1);

                    // Ensure newPrevPages is always an array
                    let newPrevPages = Array.isArray(prevPages)
                      ? [...prevPages]
                      : [];
                    handleSubmit("draft");
                    newPrevPages.push(page);
                    setPrevPages(newPrevPages);
                    setNavIndex(null);
                  } else {
                    setTempError(true);
                  }
                }}
              />
            </HStack>
          )}
        </VStack>
      </Box>
    </Box>
  );
}
