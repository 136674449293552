import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

// First select the relevant part from the state
const selectDomain = (state) => state?.dashboard || initialState;

export const selectData = createSelector([selectDomain], (state) => state.data);

export const selectWarehouseData = createSelector(
  [selectDomain],
  (state) => state.warehousesData
);

export const loading = createSelector([selectDomain], (state) => state.loading);
export const error = createSelector([selectDomain], (state) => state.error);
export const selectInspectionIdArr = createSelector(
  [selectDomain],
  (state) => state.inspectionIdArr
);
export const selectTableData = createSelector(
  [selectDomain],
  (state) => state.tableData
);
export const selectTableLoading = createSelector(
  [selectDomain],
  (state) => state.tableloading
);
export const selectInspectionCounts = createSelector(
  [selectDomain],
  (state) => state.inspectionCount
);
export const selectAddFilterDropdown = createSelector(
  [selectDomain],
  (state) => state.addFilterDropdown
);
export const selectGeneratedReportMonths = createSelector(
  [selectDomain],
  (state) => state.generatedReportMonths
);
