import React, { useState } from "react";
import {
  Button,
  Box,
  List,
  ListItem,
  Text,
  Collapse,
  Checkbox,
  useOutsideClick,
  InputGroup,
  Input,
  IconButton,
  InputRightElement,
  background,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { DropdownArrowIcon } from "components/Icons/Icons";
import "../../views/Dashboard/Inspection Table/index.css";
import { CloseIcon } from "@chakra-ui/icons";
const style = {
  sideDropList: {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
  },
};

const DropdownWithCheckboxesLatest = ({
  dropDownList,
  property,
  handleSelectChange,
  selectedItems,
  title,
  showCheckBox,
}) => {
  const ref = React.useRef();
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleInputVal = (value) => {
    setInputValue(value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });

  const filteredList = inputValue
    ? dropDownList.filter((item) =>
        item.category.toLowerCase().includes(inputValue.toLowerCase())
      )
    : dropDownList;

  const isInputEmpty = inputValue.trim() === "";
  return (
    <Box ref={ref} position="relative" flex={"1"}>
      <InputGroup borderRadius="15px" height="40px">
        <Input
          bg="#F7FAFC"
          fontSize="12px"
          _focus={{
            bg: "#F7FAFC",
          }}
          _placeholder={{
            fontSize: "15px",
          }}
          value={
            isInputEmpty
              ? Array.isArray(selectedItems)
                ? selectedItems
                    .map(
                      (itemId) =>
                        dropDownList.find((item) => item.id === itemId)
                          ?.category || ""
                    )
                    .join(" , ")
                : typeof selectedItems === "string"
                ? dropDownList.find((item) => item.id === selectedItems)
                    ?.category || ""
                : inputValue
              : inputValue
          }
          placeholder={isInputEmpty && selectedItems?.length === 0 ? title : ""}
          onClick={() => {
            if (isOpen !== true) {
              toggleDropdown(true);
            }
          }}
          onChange={(e) => {
            handleInputVal(e.target.value);
          }}
        />
        <InputRightElement>
          {isInputEmpty ? (
            <IconButton
              bg="inherit"
              _hover={{ cursor: "pointer" }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<DropdownArrowIcon />}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
              }}
            ></IconButton>
          ) : (
            <IconButton
              bg="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<CloseIcon color="gray.400" height="15px" mt="11px" />}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
                handleInputVal("");
              }}
            ></IconButton>
          )}
        </InputRightElement>
      </InputGroup>
      <Collapse className={isOpen ? "drop-down" : "hide"} in={isOpen}>
        <List className="scrollable-dropdown">
          {filteredList?.length === 0 ? (
            <Box className="name-location-container">
              <Text key={uuidv4()} className="warehouse-name-text">
                {`No matching ${title}`}
              </Text>
            </Box>
          ) : (
            filteredList?.map((item, index) => (
              <ListItem
                key={item.id}
                sx={style.sideDropList}
                _hover={{ background: "#F9FDFD" }}
                style={{ textTransform: "capitalize" }}
                background={
                  !showCheckBox && selectedItems === item.id
                    ? "#F7FAFC"
                    : "inherit"
                }
                borderRadius={"8px"}
                cursor="pointer"
              >
                {showCheckBox ? (
                  <>
                    <Checkbox
                      cursor="pointer"
                      in
                      colorScheme="teal"
                      isChecked={selectedItems.includes(item.id)}
                      onChange={() => handleSelectChange(item.id, property)}
                    />
                    <Text
                      onClick={() => {
                        handleSelectChange(item.id, property);
                      }}
                      fontSize="14px"
                      fontFamily="Inter"
                      cursor="pointer"
                    >
                      {item.category}
                    </Text>
                  </>
                ) : (
                  <Box w={"100%"} cursor="pointer">
                    <Text
                      onClick={() => {
                        handleSelectChange(item.id, property);
                      }}
                      fontSize="14px"
                      fontFamily="Inter"
                    >
                      {item.category}
                    </Text>
                  </Box>
                )}
              </ListItem>
            ))
          )}
        </List>
      </Collapse>
    </Box>
  );
};

export default DropdownWithCheckboxesLatest;
