import {
  Box,
  Button,
  HStack,
  VStack,
  Text,
  Select,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  CircularProgress,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { styles } from "./style.module";

import DateRangePicker from "../Inspection Table/components/DateRangePicker";
import { analyticsDashboardSaga } from "./saga";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";
import Insights from "./components/Insights";
import RatingList from "./components/RatingList";
import { Table } from "@agnext/reactlib";

// import WarehouseName from "../../shared/WarehouseName";
import noRecordImage from "assets/img/Empty Case.png";
import InfoButton from "assets/svg/FiInfo.svg";
import InsightsAndGraph from "./components/InsightsAndGraph";
import {
  quantityInsightsHeader,
  warehouseInsightsHeader,
  options,
  gaugeColor1,
  arcLength1,
  needlePosition,
  gaugeColor2,
  arcLength2,
} from "../../Variables/variables";
import Dropdown from "../../shared/Dropdown";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "./slice";

import locationImage from "assets/svg/pin.svg";
import bagImage from "assets/svg/bag.svg";
import scaleImage from "assets/svg/Scale.svg";
import commoditiesAudited from "assets/svg/Group 6 (1).svg";
import hoursImage from "assets/svg/IoTimeOutline.svg";
import rupeesImage from "assets/svg/BiRupee.svg";
import auditImage from "assets/svg/AiOutlineFileSearch.svg";
import { format } from "date-fns";
import {
  formatNumberUnit,
  formatNumber,
  getDateDifferenceInfo,
  convertMonthNumberToName,
  getMonthArray,
  getDataArray,
  summedData,
  getUniqueObjectsByProperty,
  getPercentage,
  getPercentage1,
  getPercentage2,
  uniqueIdsArray,
  getFromDate,
  arraySum,
  parseDataArray,
  weekWiseArray,
  formattedDates,
  weekStartingDates,
} from "utils/commonFunctions";
import {
  FilterIcon,
  DropdownArrowIcon,
  LineChartIcon,
} from "components/Icons/Icons";
import FilterDrawer from "./components/FilterDrawer";
import { Flex } from "@chakra-ui/react";
import WarehouseFilter from "views/shared/WarehouseFilter";
import MapChart from "components/Charts/MapChart";
import { LineChart } from "components/Charts/LineChart";
import BarChart from "components/Charts/BarChart";
import DoughnutChart from "components/Charts/DoughnutChart";
import GaugeCharts from "components/Charts/GaugeChart";
import { InfoIcon } from "@chakra-ui/icons";
import IconBox from "components/Icons/IconBox";
import InfoBox from "./components/InfoBox";
import { capitalizeString } from "utils/commonFunctions";
import WarehouseScore from "./components/WarehouseScore";

const styles1 = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    alignSelf: "center",
  },
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
};
function AnalyticsDashboard() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: analyticsDashboardSaga });

  // Login Response
  const local = localStorage.getItem("inspectionDashboard");
  const loginObject = JSON.parse(local);

  //useStates
  //Range Picker
  const [filters, setFilters] = useState({
    fromDate: getFromDate(),
    toDate: new Date(),
  });
  const { fromDate, toDate } = filters;
  const [filterQuery, setFilterQuery] = useState({
    location: "",
    clientName: "",
    commodities: "",
  });
  const { clientName, commodities, location } = filterQuery;
  const [selectedDataType, setSelectedDataType] = useState("inspectionsCount");
  const [selectedDateType, setSelectedDateType] = useState("week");
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [inputValue, setInputValue] = useState(selectedWarehouse || "");
  const [isLineChartVisible, setIsLineChartVisible] = useState(false);

  const [selectedOption, setSelectedOption] = useState("Inspection Count");

  //State Handlers
  const toggleChart = () => {
    setIsLineChartVisible(!isLineChartVisible);
  };
  const handleFilterQuery = (option, property) => {
    setFilterQuery((prevQuery) => {
      return { ...prevQuery, [property]: option };
    });
  };
  const handleFromDateChange = (date, key) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
    // dispatch event to trigger api on date change
    if (date !== null && toDate !== null) {
      // Prepare query paramenters to get filtered data from API
      const forQueryParams = {
        warehouseId: selectedWarehouse,
        ...(fromDate &&
          toDate && {
            start_dt: format(date, "yyyy-MM-dd"),
            end_dt: format(toDate, "yyyy-MM-dd"),
          }),
        location: location,
        commodities: commodities,
        clientName: clientName,
      };
      dispatch(actions.fetchAnalyticsData({ payload: forQueryParams }));
      dispatch(
        actions.fetchBarChartData({
          payload: {
            ...forQueryParams,
            unit: dateUnit,
          },
        })
      );
      dispatch(
        actions.fetchDoughnutChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchQuantityInsightsData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchWarehouseInsightsData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchPortfolioLineChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchInspectionsLineChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchQuantityLineChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchCommoditiesData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchMapChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchWarehouses({
          payload: forQueryParams,
        })
      );
    }
  };
  const handleToDateChange = (date, key) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
    if (date !== null && fromDate !== null) {
      // Prepare query paramenters to get filtered data from API
      const forQueryParams = {
        warehouseId: selectedWarehouse,
        ...(fromDate &&
          toDate && {
            start_dt: format(fromDate, "yyyy-MM-dd"),
            end_dt: format(date, "yyyy-MM-dd"),
          }),
        location: location,
        commodities: commodities,
        clientName: clientName,
      };
      dispatch(actions.fetchAnalyticsData({ payload: forQueryParams }));
      dispatch(
        actions.fetchBarChartData({
          payload: {
            ...forQueryParams,
            unit: dateUnit,
          },
        })
      );
      dispatch(
        actions.fetchDoughnutChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchQuantityInsightsData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchWarehouseInsightsData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchPortfolioLineChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchInspectionsLineChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchQuantityLineChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchCommoditiesData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchMapChartData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchWarehouses({
          payload: forQueryParams,
        })
      );
    }
  };
  const handleDataTypeChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    const selectedOption = event.target.options[selectedIndex].label;
    setSelectedOption(selectedOption);
    const newValue = event.target.value;
    setSelectedDataType(newValue);
  };
  const handleDateType = (type) => {
    setSelectedDateType(type);
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      warehouseId: selectedWarehouse,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      location,
      commodities,
    };
    dispatch(
      actions.fetchBarChartData({
        payload: { ...forQueryParams, unit: type },
      })
    );
  };
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleQuery = (val) => {
    setSearchQuery(val);
  };

  const handleSelect = (value) => {
    setInputValue(value);
    handleQuery(value);
  };

  const handleWareHouseChange = (warehouseID) => {
    setSelectedWarehouse(warehouseID);
  };

  const handleInputVal = (value) => {
    setInputValue(value);
  };
  const clearDateSelection = () => {
    setSelectedDateType("");
  };
  const dispatch = useDispatch();
  const dateUnit = getDateDifferenceInfo(filters?.fromDate, filters?.toDate);

  //useSelectors
  let analyticsData = useSelector(selectors.selectAnalyticsData) || [];
  let barChartData = useSelector(selectors.selectBarChartData) || [];
  let doughnutChartData = useSelector(selectors.selectDoughnutChartData) || [];
  let quantityInsightsData =
    useSelector(selectors.selectQuantityInsightsData) || [];
  let warehouseInsightsData =
    useSelector(selectors.selectWarehouseInsightsData) || [];
  let portfolioLineChartData =
    useSelector(selectors.selectPortfolioLineChartData) || [];
  let inspectionsLineChartData =
    useSelector(selectors.selectInspectionsLineChartData) || [];
  let quantityLineChartData =
    useSelector(selectors.selectQuantityLineChartData) || [];
  let mapChartData = useSelector(selectors.selectMapChartData) || [];
  let commoditiesData = useSelector(selectors.selectCommoditiesData) || [];
  let warehouses = useSelector(selectors.selectWarehouseData) || [];
  let analyticsLoading = useSelector(selectors.selectAnalyticsLoading);
  let insightsGraphCardLoading = useSelector(
    selectors.selectPortfolioLineChartLoading
  );
  let warehouseScore = useSelector(selectors.selectWarehouseScore);
  let labels = [];
  let data = [];

  //useEffects
  useEffect(() => {
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      warehouseId: selectedWarehouse,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      location: location,
      commodities: commodities,
      clientName: clientName,
    };
    dispatch(actions.fetchAnalyticsData({ payload: forQueryParams }));
    dispatch(
      actions.fetchBarChartData({
        payload: {
          ...forQueryParams,
          unit: dateUnit,
        },
      })
    );
    dispatch(
      actions.fetchDoughnutChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchQuantityInsightsData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchWarehouseInsightsData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchPortfolioLineChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchInspectionsLineChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchQuantityLineChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchCommoditiesData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchMapChartData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchWarehouses({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchWarehouseScore({
        payload: forQueryParams,
      })
    );
  }, [fromDate, toDate, clientName, commodities, location, selectedWarehouse]);

  // Data Modification for components.
  //Insights & Graph Data
  //Bar Chart Data
  if (barChartData?.length > 0) {
    if ("week" in barChartData[0]) {
      labels = formattedDates(barChartData, "weekStartTimestamp");
    } else if ("day" in barChartData[0]) {
      labels = barChartData.map((item) => {
        if (item.month && item.year) {
          return `${item.day} ${convertMonthNumberToName(
            item.month,
            item.year
          )}`;
        }
      });
    } else {
      labels = barChartData.map((item) => {
        if (item.month && item.year) {
          return `${convertMonthNumberToName(item.month, item.year)}`;
        }
      });
    }
    data = barChartData.map((item) => {
      if (item[selectedDataType]) {
        return parseInt(item[selectedDataType]);
      }
    });
  }
  //Line chart
  let portfolioLabel =
    dateUnit === "day" || dateUnit === "month"
      ? getMonthArray(portfolioLineChartData, dateUnit)
      : weekStartingDates(
          weekWiseArray(portfolioLineChartData, "portfolioSize")
        );

  let portfolioData = getDataArray(
    portfolioLineChartData,
    dateUnit,
    "portfolioSize"
  );

  let inspectionsLabel =
    dateUnit === "day" || dateUnit === "month"
      ? getMonthArray(inspectionsLineChartData, dateUnit)
      : weekStartingDates(
          weekWiseArray(inspectionsLineChartData, "inspectionCount")
        );
  let inspectionsData = getDataArray(
    inspectionsLineChartData,
    dateUnit,
    "inspectionCount"
  );

  let quantityLabel =
    dateUnit === "day" || dateUnit === "month"
      ? getMonthArray(quantityLineChartData, dateUnit)
      : weekStartingDates(
          weekWiseArray(inspectionsLineChartData, "totalWeight")
        );
  let quantityData = getDataArray(
    quantityLineChartData,
    dateUnit,
    "totalWeight"
  );

  let portfolioSum = arraySum(portfolioData);
  let inspectionsSum = arraySum(inspectionsData);
  let quantitySum = arraySum(quantityData);

  let insightsAndGraphData = [
    // {
    //   title: "Portfolio Size",
    //   amount: isNaN(portfolioSum)? formatNumberUnit(0) : formatNumberUnit(portfolioSum) || formatNumberUnit(0),
    //   icon: rupeesImage,
    //   data: portfolioData,
    //   labels: portfolioLabel,
    //   data: portfolioData,
    //   percentage: getPercentage(
    //     portfolioLineChartData,
    //     portfolioSum,
    //     dateUnit,
    //     "portfolioSize",
    //     "Portfolio"
    //   ),
    // },
    {
      title: "Total Inspections",
      amount: inspectionsSum || 0,
      icon: auditImage,
      labels: inspectionsLabel,
      data: inspectionsData,
      percentage: getPercentage(
        inspectionsLineChartData,
        inspectionsSum,
        dateUnit,
        "inspectionCount",
        "Inspections"
      ),
    },
    {
      title: "Quantity Inspected (MT)",
      amount: isNaN(quantitySum)
        ? formatNumber(0, "Quantity Inspected (MT)")
        : formatNumber(quantitySum, "Quantity Inspected (MT)") || 0,
      icon: locationImage,
      labels: quantityLabel,
      data: quantityData,
      percentage: getPercentage(
        quantityLineChartData,
        quantitySum,
        dateUnit,
        "totalWeight",
        "Quantity"
      ),
    },
  ];
  const bagSum = (analyticsData.length > 0 && summedData(analyticsData)) || [];
  let insightsData = bagSum?.hasOwnProperty("totalNoOfBags") && [
    {
      title: "No of Bags",
      amount: formatNumber(bagSum?.totalNoOfBags, "No of Bags"),
      icon: bagImage,
      percentage: getPercentage(
        analyticsData,
        bagSum?.totalNoOfBags,
        dateUnit,
        "totalNoOfBags",
        "Bags"
      ),
    },
    {
      title: "Commodities",
      amount: uniqueIdsArray(analyticsData, "commodities"),
      icon: commoditiesAudited,
      percentage: getPercentage(
        analyticsData,
        uniqueIdsArray(analyticsData, "commodities"),
        dateUnit,
        "commodities",
        "Commodity"
      ),
    },
    {
      title: "Sites Inspected",
      amount: uniqueIdsArray(analyticsData, "originInspected"),
      icon: locationImage,
      percentage: getPercentage(
        analyticsData,
        uniqueIdsArray(analyticsData, "originInspected"),
        dateUnit,
        "originInspected",
        "Sites"
      ),
    },
    // {
    //   title: "Man Hours Saved",
    //   amount: "N.A.",
    //   icon: scaleImage,
    // },
  ];

  //Doughnut Chart Data
  let colorMapping = { average: "#FFB783", good: "#39AEA3", bad: "#FF6D6D" };
  const keysToRemove = Object.keys(doughnutChartData).filter(
    (key) => doughnutChartData[key] === 0
  );

  const filteredData = {};
  for (const key in doughnutChartData) {
    if (!keysToRemove.includes(key)) {
      filteredData[key] = doughnutChartData[key];
    }
  }
  let selectedKeys = Object.keys(filteredData);
  let selectedColors = selectedKeys.map((key) => colorMapping[key]);
  let chartArr = Object.values(filteredData);

  //Quantity Insights

  quantityInsightsData = quantityInsightsData?.map((obj) => {
    return {
      quantity:
        obj?.quantity === "" ||
        obj?.quantity === null ||
        obj?.quantity === undefined ||
        obj?.quantity === 0
          ? "-"
          : obj?.quantity.toFixed(2),
      commodity:
        obj?.commodity === "" ||
        obj?.commodity === null ||
        obj?.commodity === undefined
          ? "-"
          : capitalizeString(obj?.commodity?.toLowerCase()),
      noOfBags:
        obj?.noOfBags === "" ||
        obj?.noOfBags === null ||
        obj?.noOfBags === undefined ||
        obj?.noOfBags === 0
          ? "-"
          : obj?.noOfBags,
    };
  });

  //Warehouse Insights

  warehouseInsightsData = warehouseInsightsData?.map((obj) => {
    return {
      ...obj,
      quantity: obj?.quantity?.toFixed(2),
      portfolioSize: obj?.portfolioSize?.toFixed(2),
      originCode: obj?.originCode?.toUpperCase(),
      originName: capitalizeString(obj?.originName?.toLowerCase()),
      warehouseScore: <WarehouseScore WHScore={obj?.warehouseScore} />,
    };
  });
  //Commodity Dropdown List

  let commodityNames = commoditiesData?.filter((obj) => {
    if (obj.name !== null && obj.name !== undefined) {
      return obj.name;
    }
  });

  //Location Dropdown List
  const uniqueObjects = getUniqueObjectsByProperty(warehouses, "city") || [];
  //console.log(uniqueObjects, "warehouses");
  return (
    <VStack sx={styles.mainContainer}>
      <Flex
        sx={styles.filtersContainer}
        borderRadius={"15px"}
        flexDirection={"row"}
      >
        {
          loginObject?.user?.orgName === "HDFC" ? (
            <></>
          ) : (
            //
            // Filter Drawer start
            //
            <Box flex="1" display={{ base: "flex", md: "none" }}>
              <Button
                sx={styles.button}
                rightIcon={<FilterIcon width="30px" height="18px" />}
                variant="outline"
                onClick={toggleDrawer}
                width={"100%"}
              >
                Filters
                {(location !== "" || commodities !== "") && (
                  <Box sx={styles.greenCircle}></Box>
                )}
              </Button>
              <FilterDrawer
                commodityOptions={commodityNames}
                locationOptions={uniqueObjects}
                handleFilterQuery={handleFilterQuery}
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                commodities={commodities}
                location={location}
              />
            </Box>
          )
          //
          // Filter Drawer End
          //
        }
        <Box display={{ base: "none", md: "block" }} flex="1">
          <Dropdown
            options={commodityNames}
            placeholder={"Commodity"}
            handleFilterQuery={handleFilterQuery}
            property={"commodities"}
            listKey={"name"}
          />
        </Box>
        {loginObject?.user?.orgName === "HDFC" && (
          <WarehouseFilter
            warehouseOptions={warehouses}
            inputValue={inputValue}
            handleQuery={handleQuery}
            handleSelect={handleSelect}
            handleWareHouseChange={handleWareHouseChange}
            handleInputVal={handleInputVal}
            searchQuery={searchQuery}
          />
        )}
        <Box display={{ base: "none", md: "block" }} flex="1">
          <Dropdown
            options={uniqueObjects}
            placeholder={"Location"}
            handleFilterQuery={handleFilterQuery}
            property={"location"}
            listKey={"city"}
          />
        </Box>

        <DateRangePicker
          width={{ base: "50%", md: "33.33%" }}
          filters={filters}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
        />
      </Flex>
      {insightsAndGraphData.length === 0 ? (
        <Box style={styles.message}>
          <img
            style={styles.image}
            src={noRecordImage}
            alt="No Records Image"
          />
          <p>No Insights For Selected Filters</p>
        </Box>
      ) : insightsGraphCardLoading ? (
        <CircularProgress
          isIndeterminate
          color="#4FD1C5"
          size="40px"
          alignSelf={"center"}
        />
      ) : (
        <Box sx={styles.insightsContainer} gap={{ base: "16px", md: "24px" }}>
          <HStack
            gap={{ base: "16px", md: "24px" }}
            w={"100%"}
            sx={{
              "@media (max-width: 1200px)": {
                flexDirection: "column",
              },
            }}
            flexDirection={{ base: "column", md: "row" }}
          >
            {insightsAndGraphData.map((insight, index) => (
              <InsightsAndGraph
                title={insight.title}
                amount={insight.amount}
                icon={insight.icon}
                key={uuidv4()}
                index={index}
                labels={insight.labels}
                data={insight.data}
                loading={insightsGraphCardLoading}
                percentage={insight.percentage}
                tooltipData={barChartData}
              />
            ))}
          </HStack>
          <HStack
            flexWrap={"wrap"}
            gap={{ base: "16px", md: "24px" }}
            w={"100%"}
          >
            {insightsData.length > 0 &&
              insightsData?.map((insight, index) => (
                <Insights
                  pVal={"20px"}
                  minWidthValue={"364px"}
                  isRow={true}
                  key={index}
                  title={insight.title}
                  loading={analyticsLoading}
                  // error={error}
                  amount={insight.amount}
                  icon={
                    <img
                      src={insight.icon}
                      // style={imageStyle}
                      alt={`Icon ${index}`}
                    />
                  }
                  percentage={insight.percentage}
                />
              ))}
          </HStack>
        </Box>
      )}

      <Box sx={styles.barChartContainer} padding={{ base: "16px", md: "20px" }}>
        <HStack
          sx={styles.headerContainer}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Text sx={styles.header}>{selectedOption}</Text>
          <HStack>
            <IconButton
              bg="#F7FAFC"
              _hover={{ cursor: "pointer" }}
              _active={{}}
              sx={{ height: "32px", borderRadius: "8px" }}
              icon={<LineChartIcon />}
              onClick={toggleChart}
            ></IconButton>
            <HStack gap={"0px"}>
              <Box
                sx={styles.chartHeaderBtn}
                borderBottomLeftRadius="8px"
                borderTopLeftRadius="8px"
                as="button"
                backgroundColor={selectedDateType === "day" ? "#F7FAFC" : ""}
                onClick={() => handleDateType("day")}
              >
                D
              </Box>
              <Box
                sx={{
                  ...styles.chartHeaderBtn,
                  cursor: dateUnit === "day" ? "not-allowed" : "pointer",
                }}
                as="button"
                backgroundColor={selectedDateType === "week" ? "#F7FAFC" : ""}
                onClick={() => handleDateType("week")}
                disabled={dateUnit === "day"}
              >
                W
              </Box>
              <Box
                sx={{
                  ...styles.chartHeaderBtn,
                  cursor:
                    dateUnit === "day" || dateUnit === "week"
                      ? "not-allowed"
                      : "pointer",
                }}
                as="button"
                borderBottomRightRadius="8px"
                borderTopRightRadius="8px"
                backgroundColor={selectedDateType === "month" ? "#F7FAFC" : ""}
                onClick={() => handleDateType("month")}
                disabled={dateUnit === "day" || dateUnit === "week"}
              >
                M
              </Box>
            </HStack>
            <Select sx={styles.barChartSelect} onChange={handleDataTypeChange}>
              <option
                value="inspectionsCount"
                defaultChecked
                sx={styles.barChartSelectOpt}
              >
                Inspection Count
              </option>
              <option value="noOfBags" sx={styles.barChartSelectOpt}>
                Bag Count
              </option>
              <option value="quantity" sx={styles.barChartSelectOpt}>
                Quantity Inspected (MT)
              </option>
            </Select>
          </HStack>
        </HStack>
        <Box sx={styles.barChart}>
          {isLineChartVisible ? (
            <LineChart
              labels={labels}
              data={data}
              key={uuidv4()}
              showLine={true}
              tooltipData={barChartData}
            />
          ) : (
            <BarChart
              labels={labels}
              barData={data}
              tooltipData={barChartData}
            />
          )}
        </Box>
      </Box>
      <HStack
        sx={styles.doughnutChartOuterContainer}
        flexDirection={{ base: "column", md: "row" }}
        height={{ base: "830px", md: "441px" }}
        gap={{ sm: "36px", md: "20px" }}
      >
        <VStack
          sx={{
            ...styles.doughnutChartContainer,
            "@media (max-width: 768px)": {
              maxHeight: "350px",
            },
          }}
        >
          <HStack sx={styles.headerContainer}>
            <Text sx={styles.header} padding={"20px"}>
              Commodity Health
            </Text>
            <Box
              padding={"20px"}
              sx={{ ...styles.header, position: "relative" }}
            >
              <Popover>
                <PopoverTrigger>
                  <InfoIcon
                    _hover={{ cursor: "pointer" }}
                    boxSize={6}
                    color="#CBD5E0"
                  />
                </PopoverTrigger>
                <PopoverContent
                  style={{
                    right: "150px",
                    outline: "none",
                    border: "1px solid #E2E8F0",
                    borderRadius: "15px",
                    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <InfoBox text="The graph shows the overall health of the commodity stored in the warehouse." />
                </PopoverContent>
              </Popover>
            </Box>
          </HStack>
          <HStack alignSelf={"center"}>
            {chartArr[0] === 0 && chartArr[1] === 0 && chartArr[2] === 0 ? (
              <Box style={styles.message}>
                <img
                  style={styles.image}
                  src={noRecordImage}
                  alt="No Records Image"
                />
                <p>No Records Found</p>
              </Box>
            ) : (
              <>
                <Box
                  sx={{
                    ...styles.doughnutContainer,
                    "@media (max-width: 768px)": {
                      height: "211px",
                      width: "211px",
                    },
                    "@media (min-width: 769px)": {
                      height: "292px",
                      width: "292px",
                    },
                  }}
                  // height={{ base: "211px", md: "292px" }}
                  // width={{ base: "211px", sm: "100%" }}
                >
                  <DoughnutChart
                    doughnutData={chartArr}
                    selectedColors={selectedColors}
                  />
                </Box>
                <RatingList />
              </>
            )}
          </HStack>
        </VStack>
        <VStack sx={styles.quatityInsightsContainer}>
          <Box sx={styles.quantityHeaderContainer}>
            <HStack sx={styles.headerContainer}>
              <Text sx={styles.header}>Measured Commodities</Text>
              <Box sx={{ ...styles.header, position: "relative" }}>
                <Popover>
                  <PopoverTrigger>
                    <InfoIcon
                      _hover={{ cursor: "pointer" }}
                      boxSize={6}
                      color="#CBD5E0"
                    />
                  </PopoverTrigger>
                  <PopoverContent
                    style={{
                      right: "26px",
                      outline: "none",
                      border: "1px solid #E2E8F0",
                      borderRadius: "15px",
                      boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
                    }}
                  >
                    <InfoBox text="Quantities measured across commodities." />
                  </PopoverContent>
                </Popover>
              </Box>
            </HStack>
          </Box>
          <Box w={"100%"}>
            {quantityInsightsData.length === 0 ? (
              <Box style={styles.message}>
                <img
                  style={styles.image}
                  src={noRecordImage}
                  alt="No Records Image"
                />
                <p>No Records Found</p>
              </Box>
            ) : (
              <Table
                overflowY="auto"
                tableContainerHeight={{ base: "441px", sm: "378px" }}
                headerList={quantityInsightsHeader}
                dataList={quantityInsightsData}
                fixedHeader={true}
              />
            )}
          </Box>
        </VStack>
      </HStack>
      <HStack
        sx={styles.gaugeOuterContainer}
        flexDirection={{ base: "column", md: "row" }}
        gap={{ sm: "36px", md: "24px" }}
      >
        <VStack sx={styles.gaugeChartContainer}>
          <HStack sx={styles.headerContainer}>
            <Text sx={styles.header}>Warehouse Score</Text>
            <Box sx={{ ...styles.header, position: "relative" }}>
              <Popover>
                <PopoverTrigger>
                  <InfoIcon
                    _hover={{ cursor: "pointer" }}
                    boxSize={6}
                    color="#CBD5E0"
                  />
                </PopoverTrigger>
                <PopoverContent
                  style={{
                    right: "40px",
                    outline: "none",
                    border: "1px solid #E2E8F0",
                    borderRadius: "15px",
                    boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <InfoBox text="The Warehouse Score is a metric that assesses the overall organization and documentation quality of a warehouse. It encompasses various parameters such as the condition of the warehouse structure, ventilation, plinth height, roof condition, availability and condition of fire equipment, presence of leaks, hygiene and cleanliness, electrical connections, security measures, documentation (ID cards, visitor register, stock register, etc.), valid warehouse license, incident reporting (theft, other major incidents, natural calamities), and more. This comprehensive evaluation aims to ensure efficient operations, safety, and regulatory compliance in the warehouse." />
                </PopoverContent>
              </Popover>
            </Box>
          </HStack>
          <Box>
            <GaugeCharts
              color={gaugeColor1}
              arcLength={arcLength1}
              needlePosition={warehouseScore}
            />
          </Box>
        </VStack>
        {/* <VStack sx={styles.gaugeChartContainer}>
          <HStack sx={styles.headerContainer}>
            <Text sx={styles.header}>Inspection Score</Text>
          </HStack>
          <Box>
            <GaugeCharts
              color={gaugeColor2}
              arcLength={arcLength2}
              needlePosition={needlePosition}
            />
          </Box>
        </VStack> */}
      </HStack>
      {/* <VStack sx={styles.warehouseInsightsContainer} bg={"#FFF"} width={"100%"}>
        <HStack sx={styles.headerContainer}>
          <Box sx={styles.quantityHeaderContainer}>
            <Text sx={styles.header}>Warehouse Inspected</Text>
          </Box>
        </HStack>
        <Box width={"100%"}>
          {warehouseInsightsData.length === 0 ? (
            <Box style={styles.message}>
              <img
                style={styles.image}
                src={noRecordImage}
                alt="No Records Image"
              />
              <p>No Records Found</p>
            </Box>
          ) : (
            <Table
              overflowY="auto"
              containerStyle={{ maxHeight: "715px" }}
              headerList={warehouseInsightsHeader}
              dataList={warehouseInsightsData}
              fixedHeader={true}
            />
          )}
        </Box>
      </VStack> */}
      {/* <VStack bg={"#FFF"} width={"100%"} >
        <HStack sx={styles.headerContainer}>
          <Box sx={styles.quantityHeaderContainer}>
            <Text sx={styles.header}>Auditors Insights</Text>
          </Box>
        </HStack>
        <Box width={"100%"}>
          <Table headerList={headerArray2} dataList={data2} overflow="auto" fixedHeader={true} />
        </Box>
      </VStack> */}
      <VStack sx={styles.geoInsightsContainer} bg={"#FFF"} width={"100%"}>
        <HStack sx={styles.geoheaderContainer}>
          <Box sx={styles.quantityHeaderContainer}>
            <Text sx={styles.header}>Geographical Reach</Text>
          </Box>
        </HStack>
        <Box width={"100%"}>
          {mapChartData.length !== 0 && <MapChart regionData={mapChartData} />}
        </Box>
      </VStack>
    </VStack>
  );
}

export default React.memo(AnalyticsDashboard);
