import React, { useState } from "react";
import {
  Button,
  Box,
  List,
  ListItem,
  Text,
  Collapse,
  Checkbox,
  useOutsideClick,
  InputGroup,
  Input,
  IconButton,
  InputRightElement,
  background,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { DropdownArrowIcon } from "components/Icons/Icons";
import "../../views/Dashboard/Inspection Table/index";
import { getUniqueObjectsByProperty } from "utils/commonFunctions";
import { CloseIcon } from "@chakra-ui/icons";
const style = {
  sideDropList: {
    display: "flex",
    padding: "6px 12px",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
  },
};

const DropdownWithCheckboxes = ({
  dropDownList,
  property,
  handleSelectChange,
  selectedItems,
  title,
  isMobile,
  extraProperty,
}) => {
  const ref = React.useRef();
  const [inputValue, setInputValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  let filteredWarehouseOptions = inputValue
    ? dropDownList?.filter((inspection) =>
        inspection[property]?.toLowerCase().includes(inputValue.toLowerCase())
      )
    : dropDownList;

  let uniqueObjects =
    getUniqueObjectsByProperty(filteredWarehouseOptions, property) || [];

  let counter = [];
  let count = 0;
  uniqueObjects = uniqueObjects?.map((element, index) => {
    if (
      property in element &&
      (element[property] === undefined ||
        element[property] === null ||
        element[property].trim() === "")
    ) {
      counter.push(count);
    }
    count++;
    return element;
  });
  for (let i = counter.length - 1; i >= 0; i--) {
    uniqueObjects?.splice(counter[i], 1);
  }
  const sortedArray = uniqueObjects?.sort((a, b) =>
    a[property]?.localeCompare(b[property])
  );

  const handleInputVal = (value) => {
    setInputValue(value);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick({
    ref: ref,
    handler: () => setIsOpen(false),
  });
  const isInputEmpty = inputValue.trim() === "";
  return (
    <Box ref={ref} position="relative">
      <InputGroup borderRadius="15px" height="40px">
        <Input
          _focus={{
            bg: "#F7FAFC",
          }}
          className="add-filter-btn"
          color="#718096"
          _placeholder={{
            fontSize: "15px",
          }}
          value={inputValue}
          placeholder={title}
          onClick={() => {
            if (isOpen !== true) {
              toggleDropdown(true);
            }
          }}
          onChange={(e) => {
            handleInputVal(e.target.value);
          }}
        />
        <InputRightElement>
          {isInputEmpty ? (
            <IconButton
              bg="inherit"
              _hover={{ cursor: "pointer" }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<DropdownArrowIcon />}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
              }}
            ></IconButton>
          ) : (
            <IconButton
              bg="inherit"
              _hover="none"
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={<CloseIcon color="gray.400" height="15px" mt="11px" />}
              onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
                handleInputVal("");
              }}
            ></IconButton>
          )}
        </InputRightElement>
      </InputGroup>
      <Collapse
        className={
          isOpen
            ? isMobile
              ? "side-drop-down-mobile"
              : "side-drop-down"
            : "hide"
        }
        in={isOpen}
      >
        <List className="scrollable-dropdown">
          {sortedArray?.length === 0 ? (
            <Box className="name-location-container">
              <Text key={uuidv4()} className="warehouse-name-text">
                {`No matching ${title}`}
              </Text>
            </Box>
          ) : (
            sortedArray?.map((item, index) => {
              return (
                <ListItem
                  key={item.id}
                  sx={style.sideDropList}
                  _hover={{ background: "#F9FDFD" }}
                  style={{ textTransform: "capitalize" }}
                >
                  <Checkbox
                    cursor="pointer"
                    colorScheme="teal"
                    isChecked={selectedItems[property]?.includes(
                      item[`${property}`]
                    )}
                    onChange={() =>
                      handleSelectChange(item[`${property}`], property)
                    }
                  />
                  <Text
                    onClick={() => {
                      handleSelectChange(item[`${property}`], property);
                    }}
                    fontSize="14px"
                    fontFamily="Inter"
                    cursor="pointer"
                  >
                    {extraProperty
                      ? item[`${extraProperty}`]?.toLowerCase()
                      : item[`${property}`]?.toLowerCase()}
                  </Text>
                </ListItem>
              );
            })
          )}
        </List>
      </Collapse>
    </Box>
  );
};

export default DropdownWithCheckboxes;
