import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import UserRoleTable from "./components/UserRole/UserRoleTable/UserRoleTable";
import UserRoleFilter from "./components/UserRole/UserRoleFilter/UserRoleFilter";
import UserRoleTop from "./components/UserRole/UserRoleTop/UserRoleTop";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { watchRolesAndPermission } from "./saga";
import { useDispatch, useSelector } from "react-redux";
import * as selectors from "./selectors";

const UserRole = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: watchRolesAndPermission });

  const [url, setUrl] = useState("");

  const allRolesData = useSelector(selectors.selectUserData);
  const allPermissions = useSelector(selectors.selectPermissionData);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchUserRoleRequest(url));
    dispatch(actions.fetchPermissionsDataRequest());
    dispatch(actions.fetchEditRoleRequest());
  }, [url]);

  const allRolesInfo = useSelector(selectors.selectEditData);
  //console.log();

  const queryParams = [];

  const filterRoles = (
    selectedRoles,
    selectedPermissions,
    selectedPages,
    selectedStatus
  ) => {
    if (selectedStatus) {
      queryParams.push(`status=${selectedStatus}`);
    }

    // Add selectedPermissions to queryParams if present
    if (selectedPermissions && selectedPermissions.length > 0) {
      const permsSets = JSON.stringify(selectedPermissions);
      queryParams.push(`actionTypes=${permsSets}`);
    }

    // Add selectedPages to queryParams if present
    if (selectedPages && selectedPages.length > 0) {
      const actionTypes = JSON.stringify(selectedPages);
      queryParams.push(`permsSets=${actionTypes}`);
    }

    // Add selectedRoles to queryParams if present
    if (selectedRoles && selectedRoles.length > 0) {
      const roleIds = JSON.stringify(selectedRoles);
      queryParams.push(`roles=${roleIds}`);
    }

    const newUrl = `${
      queryParams.length > 0 ? "?" + queryParams.join("&") : ""
    }`;
    setUrl(newUrl);
  };

  const uniqueIdsSet = new Set();

  const allActions = allPermissions?.payload?.reduce(
    (uniqueActions, permission) => {
      let permissionName = permission?.actionType;
      if (permissionName?.includes("_")) {
        const words = permissionName.split("_");
        const capitalizedWords = words.map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1)
        );
        permissionName = capitalizedWords.join(" ");
      } else {
        const word =
          permissionName?.charAt(0).toUpperCase() + permissionName?.slice(1);
        permissionName = word;
      }

      const id = permission.actionType;

      if (!uniqueIdsSet.has(id)) {
        uniqueIdsSet.add(id);
        uniqueActions.push({ id: id, category: permissionName });
      }

      return uniqueActions;
    },
    []
  );

  const uniquePagesSet = new Set();
  const allPages = allPermissions?.payload?.reduce(
    (uniquePages, permission) => {
      const id = permission?.permsSetName.toLowerCase();
      if (!uniquePagesSet.has(id)) {
        uniquePagesSet.add(id);
        uniquePages.push({ id, category: permission.permsSetName });
      }
      return uniquePages;
    },
    []
  );
  const allStatus = [
    { id: "active", category: "Active" },
    { id: "inactive", category: "Deactivated" },
    { id: "draft", category: "Draft" },
  ];

  const allRoles = Array.isArray(allRolesInfo?.payload)
    ? allRolesInfo.payload.map((role) => ({
        id: role.id,
        category: role.roleName,
      }))
    : [];

  let totalRoles = allRolesData?.payload?.length;
  return (
    <Box mt={"75px"}>
      <Stack spacing={"36px"} direction="column">
        <UserRoleTop props={{ totalRoles }} />
        <UserRoleFilter
          allActions={allActions}
          allPages={allPages}
          allStatus={allStatus}
          allRoles={allRoles}
          filterRoles={filterRoles}
        />
        <UserRoleTable props={allRolesData?.payload} />
      </Stack>
    </Box>
  );
};

export default UserRole;
