import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { ArrowUpIcon, AddIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function Moveup({ moveUpHandler, isDisabled }) {
  // //console.log(order);
  return (
    <>
      <Button
        leftIcon={
          <ArrowUpIcon color={isDisabled ? "main.iconDisabled" : "main.icon"} />
        }
        colorScheme="login"
        variant="ghost"
        onClick={moveUpHandler}
        // label="Move Up"
      />
    </>
  );
}

export default Moveup;
