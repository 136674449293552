import { call, put, takeLatest, takeEvery, delay } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";
import * as API1 from "services/apis";

export function* handleFetchReviewData({ payload }) {
  try {
    const res = yield call(API1.getAllItems, payload.payload);
    const { data } = res;
    yield put(actions.fetchReviewDataSuccess(data.payload));
    payload.onSuccess({ data: data.payload, components: payload.components });
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchReviewDataFailure(err?.response?.data));
  }
}
export function* handleFetchWorkflowData({ payload }) {
  //console.log(payload);
  try {
    const res = yield call(API.fetchWorkflowData, payload);
    const { data } = res;
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
    payload.onSuccess(data.payload.components);
  } catch (err) {
    yield put(actions.fetchWorkflowDataFailure(err?.response?.data));
  }
}

export function* handlePostWorkflowData({ payload }) {
  try {
    const res = yield call(API.postWorkflowData, payload.data);
    //console.log({ res });
    // let data = JSON.parse(res.data);
    // yield put(actions.postWorkflowDataSuccess(data));
  } catch (err) {
    //console.log({ err });
    yield put(actions.postWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleFetchSingleInspection({ payload }) {
  // //console.log({payload})
  try {
    const res = yield call(API1.getSingleInspection, payload);
    //console.log({ res });
    let data = res.data;
    yield put(actions.fetchSingleInspectionSuccess(data.payload));
  } catch (err) {
    //console.log({ err });
    yield put(actions.fetchSingleInspectionFailure(err?.response?.data));
  }
}

/***
 * Root saga manages watcher lifecycle
 ***/

export function* workflowDataSaga() {
  yield takeLatest(actions.postWorkflowData.type, handlePostWorkflowData);
  yield takeLatest(actions.fetchWorkflowData.type, handleFetchWorkflowData);
  yield takeLatest(actions.fetchReviewData.type, handleFetchReviewData);
  yield takeLatest(
    actions.fetchSingleInspection.type,
    handleFetchSingleInspection
  );
}
