import Dashboard from "views/Dashboard/Inspection Table";
import Workflows from "views/Workflow Builder/pages/Workflows";
import AnalyticsDashboard from "views/Dashboard/Analytics Dashboard";
import Warehouses from "views/Dashboard/Warehouses/pages/Warehouses/Warehouses";
import CmTable from "views/Dashboard/Collateral Manager/Pages/Collateral Manager Table/CmTable";
import UserRole from "views/RoleAndPermission/UserRole";

//SVGs
import activeDashboardSVG from "assets/svg/MdDashboard.svg";
import dashboardSVG from "assets/svg/MdDashboard-teal.svg";
import activeInspectionSVG from "assets/svg/HiDocumentSearch.svg";
import inspectionSVG from "assets/svg/HiDocumentSearch-teal.svg";
import activeUserSVG from "assets/svg/IoSettingsSharp.svg";
import InActiveUserSVG from "assets/svg/IoSettingsSharp-teal.svg";
import activeWorkflowSVG from "assets/svg/IoShareSocialSharp-teal.svg";
import inActiveWorkflowSVG from "assets/svg/IoShareSocialSharp.svg";
import { Inspections } from "views/Users/pages/myApprovals";
import ReviewWorkflow from "views/Workflow/pages/reviewWorkflow";
import activeApprovalSVG from "assets/svg/MdOutlineApproval.svg";
import approvalSVG from "assets/svg/MdOutlineApproval-teal.svg";
import GenerateWorkflow from "views/Workflow/pages/generateWorkflow";
// import SummaryNCCF from "views/Dashboard/SummaryNCCF";

import InActiveWarehouse from "assets/svg/Warehouses-teal.svg";
import ActiveWarehouse from "assets/svg/Warehouses.svg";
import ActiveCMIcon from "assets/svg/Collateral Managers-teal.svg";
import InActiveCMIcon from "assets/svg/Collateral Managers.svg";
import EnnoventureComp from "views/Workflow/components/ennoventureComp";
var SideBarRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AnalyticsDashboard,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  // {
  //   path: "/dashboard/summary",
  //   name: "Summary",
  //   component: EnnoventureComp,
  //   layout: "/admin",
  //   activeIcon: activeDashboardSVG,
  //   inActiveIcon: dashboardSVG,
  // },
  {
    path: "/inspections",
    name: "Inspections",
    component: Dashboard,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
  },
  {
    path: "/warehouse",
    name: "Warehouses",
    component: Warehouses,
    layout: "/admin",
    activeIcon: ActiveWarehouse,
    inActiveIcon: InActiveWarehouse,
  },
  {
    path: "/cm-manager",
    name: "Collateral Managers",
    component: CmTable,
    layout: "/admin",
    activeIcon: InActiveCMIcon,
    inActiveIcon: ActiveCMIcon,
  },
  {
    path: "/my-approvals/inspections",
    name: "Pending Approvals",
    component: Inspections,
    layout: "/admin",
    activeIcon: activeApprovalSVG,
    inActiveIcon: approvalSVG,
  },
  // {
  //   path: "/my-approval/inspections/reviewing",
  //   name: "Review Form",
  //   component: ReviewWorkflow,
  //   layout: "/admin",
  //   activeIcon: activeDashboardSVG,
  //   inActiveIcon: dashboardSVG,
  // },
  {
    path: "/user-roles",
    name: "Roles & Permissions",
    component: UserRole,
    layout: "/admin",
    activeIcon: activeUserSVG,
    inActiveIcon: InActiveUserSVG,
  },
  {
    path: "/workflows",
    name: "Workflow",
    component: Workflows,
    layout: "/admin",
    activeIcon: activeWorkflowSVG,
    inActiveIcon: inActiveWorkflowSVG,
  },
  {
    path: "/view-form",
    name: "Bank Audit",
    component: GenerateWorkflow,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
];
export default SideBarRoutes;
