import { createSlice } from "@reduxjs/toolkit";
import { generateUUId } from "utils/utils";

export const initialState = {
  loading: false,
  error: null,
  workflow: {},
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
  downloading: false,
  reviewDataLoading:false
};

const homePage = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    // Fetch Data
    fetchWorkflowData: (state, action) => {
      state.loading = true;
    },
    fetchWorkflowDataSuccess: (state, action) => {
      state.loading = false;
      // let newWorkflow = action.payload.form;
      // const newWorkflow = Object.assign({}, action.payload.form);
      let newWorkflow = JSON.parse(JSON.stringify(action.payload.form));
      newWorkflow["inspectionId"] = generateUUId();
      state.workflow = [newWorkflow];
      state.sections = action.payload.sections;
      state.blocks = action.payload.blocks;
      state.composites = action.payload.composites;
      state.components = action.payload.components;
    },
    fetchWorkflowDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postWorkflowData: (state, action) => {
      state.loading = true;
    },

    postWorkflowDataSuccess: (state, action) => {
      state.loading = false;
    },

    postWorkflowDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },

    storeFileToUpload: (state, action) => {
      const files = state.files || [];
      state.files = [...files, action.payload];
    },
    fetchReviewData: (state, action) => {
      state.reviewDataLoading = true;
    },
    fetchReviewDataSuccess: (state, action) => {
      state.reviewDataLoading = false;
      state.reviewData = action.payload;
    },
    fetchReviewDataFailure: (state, action) => {
      state.reviewDataLoading = false;
    },
    clearData: (state, action) => {
      state.loading = true;
    },
    fetchClearDataSuccess: (state, action) => {
      state.loading = false;
      state.workflow = {};
      state.sections = []
      state.blocks = []
      state.composites = []
      state.components = []
      state.reviewData = []
    },
    fetchClearDataFailure: (state, action) => {
      state.loading = false;
    },
    // Init

    init: (state, action) => {
      const { loggedInUser, id } = action.payload;
      state.workflow = {
        ...state.workflow,
        id: id,
        createdBy: loggedInUser,
        title: "Workflow",
        application: "Rake Inspection",
        client: generateUUId(),
      };
      state.sections = [];
      state.blocks = [];
      state.composites = [];
      state.components = [];
    },
    downloadImage(state, action) {
      state.downloading = true;
    },
    downloadImageSuccess(state, action) {
      state.downloading = false;
    },
    downloadImageFailure(state, action) {
      state.downloading = false;
    },

    // Init End
  },
});

export const { actions, reducer, name: sliceKey } = homePage;
