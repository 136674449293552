import { Table } from "@agnext/reactlib";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import * as selectors from "../../selectors";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { actions, reducer, sliceKey } from "../../slice";
import { WarehouseHeader } from "../../variables/variable";
import { warehouseSaga } from "../../saga";
import { Box, HStack, Select, VStack, Text, Tooltip } from "@chakra-ui/react";
import WarehouseScore from "views/Dashboard/Analytics Dashboard/components/WarehouseScore";
import { format, sub } from "date-fns";
import { capitalizeString } from "utils/commonFunctions";
const Warehouses = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: warehouseSaga });

  const [selectedOption, setSelectedOption] = useState("Top 10");
  const [selectedDateType, setSelectedDateType] = useState("year");
  const [date, setDate] = useState({
    fromDate: sub(new Date(), { months: 12 }),
    toDate: new Date(),
  });
  const { fromDate, toDate } = date;

  const dispatch = useDispatch();

  const handleDateChange = (fromDate, toDate) => {
    setDate({
      fromDate,
      toDate,
    });
  };
  //console.log(date);
  let warehouseInsightsData =
    useSelector(selectors.selectWarehouseInsightsData) || [];
  // Sort the array based on the 'score' key in ascending order
  let filteredArray = warehouseInsightsData?.filter(
    (obj) =>
      obj.warehouseScore !== null &&
      obj.warehouseScore !== undefined &&
      obj.warehouseScore !== ""
  );
  let warehouseData = filteredArray.sort(
    (a, b) => a.warehouseScore - b.warehouseScore
  );

  warehouseData = warehouseData?.map((obj) => {
    return {
      ...obj,
      warehouseName: capitalizeString(obj?.warehouseName?.toLowerCase()),
      warehouseScore: <WarehouseScore WHScore={obj?.warehouseScore} />,
    };
  });

  warehouseData =
    selectedOption === "Top 10"
      ? warehouseData.slice(-10).reverse()
      : warehouseData.slice(0, 10);

  const handleDateType = (type) => {
    setSelectedDateType(type);
  };

  const toggleTopBottom = (e) => {
    const selectedIndex = e.target.selectedIndex;
    const selectedOption = e.target.options[selectedIndex].label;
    setSelectedOption(selectedOption);
  };
  const styles = {
    select: {
      borderRadius: "8px",
      border: "1px solid #E2E8F0",
      background: "#F7FAFC",
      display: "flex",
      height: "32px",
      padding: " 6px 8px 6px 12px",
      justifyContent: "flex-end",
      alignItems: "flex-start",
      gap: "12px",
      color: "#4A5568",
      fontSize: "12.5px",
    },
    selectOpt: {
      color: "#2D3748",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
    heading: {
      color: "#7E8DA2",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "20px",
    },
    headerBtn: {
      padding: "0px 12px",
      justifyContent: "center",
      height: "32px",
      alignItems: "center",
      border: "1px solid #EDF2F7",
      color: "#4A5568",
      fontFamilpy: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      cursor: "pointer",
    },
  };
  function setDates(duration) {
    const today = new Date();
    let fromDate, toDate;

    switch (duration) {
      case "day":
        fromDate = toDate = sub(today, { days: 1 });
        break;
      case "week":
        fromDate = sub(today, { days: 7 });
        toDate = today;
        break;
      case "month":
        fromDate = sub(today, { days: 30 });
        toDate = today;
        break;
      case "quarter":
        fromDate = sub(today, { months: 3 });
        toDate = today;
        break;
      case "year":
        fromDate = sub(today, { months: 12 });
        toDate = today;
        break;
      default:
        break;
    }

    handleDateChange(fromDate, toDate);
  }
  useEffect(() => {
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
    };
    dispatch(
      actions.fetchWarehouseInsightsData({
        payload: forQueryParams,
      })
    );
  }, [date]);
  return (
    <Box mt={"75px"} w={"100%"} bg={"#fff"} borderRadius={"15px"}>
      <VStack w={"100%"}>
        <HStack justifyContent={"space-between"} w={"100%"} p={"15px"}>
          <Text sx={styles.heading}>Warehouses</Text>
          <HStack gap={"0px"}>
            <Box
              sx={styles.headerBtn}
              borderBottomLeftRadius="8px"
              borderTopLeftRadius="8px"
              as="button"
              backgroundColor={selectedDateType === "day" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("day");
                setDates("day");
              }}
            >
              <Tooltip label="Yesterday" hasArrow>
                D
              </Tooltip>
            </Box>
            <Box
              sx={{
                ...styles.headerBtn,
              }}
              as="button"
              backgroundColor={selectedDateType === "month" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("month");
                setDates("month");
              }}
            >
              <Tooltip label="Last Month" hasArrow>
                M
              </Tooltip>
            </Box>
            <Box
              sx={{
                ...styles.headerBtn,
              }}
              as="button"
              backgroundColor={selectedDateType === "quarter" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("quarter");
                setDates("quarter");
              }}
            >
              <Tooltip label="Last Quater" hasArrow>
                Q
              </Tooltip>
            </Box>
            <Box
              sx={{
                ...styles.headerBtn,
              }}
              as="button"
              borderBottomRightRadius="8px"
              borderTopRightRadius="8px"
              backgroundColor={selectedDateType === "year" ? "#F7FAFC" : ""}
              onClick={() => {
                handleDateType("year");
                setDates("year");
              }}
            >
              <Tooltip label="Last Year" hasArrow>
                Y
              </Tooltip>
            </Box>
          </HStack>
          <Select sx={styles.select} onChange={toggleTopBottom} w={"100px"}>
            <option value="Top 10" sx={styles.selectOpt}>
              Top 10
            </option>
            <option value="Bottom 10" sx={styles.selectOpt}>
              Bottom 10
            </option>
          </Select>
        </HStack>
        <Table
          overflowY="auto"
          headerList={WarehouseHeader}
          dataList={warehouseData}
          fixedHeader={true}
          containerStyle={{ width: "100%" }}
        />
      </VStack>
    </Box>
  );
};

export default Warehouses;
