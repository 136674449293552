import React, { useCallback, useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { workflowDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import HeaderGray from "../../components/headerGray";
import {
  Text,
  HStack,
  VStack,
  Box,
  useMediaQuery,
  useDisclosure,
  useToast,
  CircularProgress,
} from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import Section from "./section";
import { postData } from "services/index";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { userStorage } from "utils/helper";
import { AreYouSureModal } from "./popups/AreYouSure";
import { DiscardModal } from "./popups/DiscardModal";

function hasPageNumber(arr, pageNumber) {
  let res = -1;
  arr.forEach((el, i) => {
    if (el.pageNumber === pageNumber) {
      res = i;
    }
  });
  return res;
}

export default function ReviewWorkflow() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: workflowDataSaga });

  const dispatch = useDispatch();
  const history = useHistory();

  const [files, setFiles] = useState([]);

  const [formData, setFormData] = useState({});

  const [page, setPage] = useState(0);

  const [isSubmit, setIsSubmit] = useState(false);

  const [reviewData, setReviewData] = useState([]);

  const [discardButton, setDiscardButton] = useState(0);

  const [displayData, setDisplayData] = useState([]);

  const [prevPages, setPrevPages] = useState([]);

  const [dataArr, setdataArr] = useState([]);

  const [btnStatusArr, setBtnStatusArr] = useState([]);

  const [sectionElementMap, setSectionElementMap] = useState({});

  const [disabledSectionCheck, setDisabledSectionCheck] = useState([]);

  const [allElementsId, setAllElementsId] = useState({});

  const [tempError, setTempError] = useState(false);

  const handleFilesChange = (file) => {
    const newFiles = [...files];
    newFiles.push(file);
    setFiles(newFiles);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure(); // Change variable names here

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const toast = useToast();

  const showSuccessToast = () => {
    toast({
      title: "Changes Saved",
      description: "Your changes have been saved successfully.",
      status: "success",
      duration: 1500,
      isClosable: true,
    });
  };

  const showApprovedToast = () => {
    toast({
      title: "Approved Successfully",
      description: "Your form is approved .",
      status: "success",
      duration: 1500,
      isClosable: true,
    });
  };

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");

  const workflow = useSelector(selectors.selectWorkflow);
  const toBeReviewedData = useSelector(selectors.selecReviewData);
  const sections = useSelector(selectors.selectSections);
  const reviewLoading = useSelector(selectors.selectReviewDataLoading)
  const loading = useSelector(selectors.selectLoading)
  const [navIndex, setNavIndex] = useState(null);
  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );

  const { inspectionId } = useParams();
  const getElementsState = useCallback(() => {
    const elements = componentsForElements.reduce((acc, cv) => {
      const elementsCurr = cv.structure.elements.map((element) => element);
      elementsCurr.forEach((currEl) => {
        acc[currEl.id] = {
          mandatory: currEl.mandatory,
        };
      });
      return acc;
    }, {});
    return elements;
  }, [componentsForElements, workflow]);
  //Changes
  const getElements = useCallback(
    (sectionId) => {
      let elementDisplayData = [];
      if (
        sectionsForBlocks &&
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        sectionsForBlocks.forEach((section) => {
          section.structure.blocks.forEach((block) => {
            blocksForComposites.forEach((blockForComposites) => {
              if (block.id === blockForComposites.id) {
                blockForComposites.structure.composites.forEach((composite) => {
                  compositesForComponents.forEach((compositeForComponents) => {
                    if (composite.id === compositeForComponents.id) {
                      compositeForComponents.structure.components.forEach(
                        (component) => {
                          componentsForElements.forEach(
                            (componentForElements) => {
                              if (componentForElements.id === component.id) {
                                componentForElements.structure.elements.forEach(
                                  (element) => {
                                    elementDisplayData.push({
                                      sectionId: section.id,
                                      element,
                                    });
                                  }
                                );
                              }
                            }
                          );
                        }
                      );
                    }
                  });
                });
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.sectionId === sectionId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [
      sectionsForBlocks,
      blocksForComposites,
      compositesForComponents,
      componentsForElements,
    ]
  );
  //changes

  const getDisplayData = useCallback(() => {
    let displayData = [];
    if (sectionsForBlocks) {
      sectionsForBlocks.forEach((section) => {
        if (hasPageNumber(displayData, section.pageNumber) !== -1) {
          const newSections =
            displayData[hasPageNumber(displayData, section.pageNumber)]
              .sections;
          newSections.push(section);
          displayData[
            hasPageNumber(displayData, section.pageNumber)
          ].sections = newSections;
        } else {
          displayData.push({
            pageNumber: section.pageNumber,
            sections: [section],
          });
        }
      });
    }
    displayData = displayData.sort((a, b) => a.pageNumber - b.pageNumber);
    return displayData;
  }, [sectionsForBlocks]);

  useEffect(() => {
    setDisplayData(getDisplayData());
  }, [getDisplayData]);

  const handleFormDataChange = (e, val, id, mimeType, files) => {
    setTempError(false);

    if (_.isArray(val)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: [...val],
          files,
          mimeType,
        },
      }));
      return;
    }
    if (_.isArray(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: e,
          mimeType,
        },
      }));
      return;
    }
    if (_.isString(e) || _.isDate(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: e,
        },
      }));
      return;
    }
    if (val && id) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: val,
        },
      }));
      return;
    }
    const { name, value, checked } = e.target;
    if (!val) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value || value === "" || value === 0 ? value : checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: val,
        },
      }));
    }
  };

  const handleSubmit = async (status) => {
    setIsSubmit(true);
    let data = [];
    const toClearElementIds = disabledSectionCheck
      .map((sectionId) => {
        return sectionElementMap[sectionId];
      })
      .flat();
    Object.keys(formData).forEach((el) => {
      data.push(formData[el]);
    });

    data = data.map((element) => {
      let id = element.workflowId
      if (toClearElementIds.includes(element.templateItemId)) {
        return { ...element, attachments: [], value: "" };
      } else if (
        (id === "0w8vf5o0wlegxbl4" || id === "8bk6b3tbzxp6xlnh" || id === "d8a4joybjndpg8l0")
      ) {
        if (element.name === "storeCode") {
          return { ...element, name: "warehouseCode" };
        } else if (element.name === "nameOfTheOrganization") {
          return { ...element, name: "nameOfTheBank" };
        } else if (element.name === "conductedOn") {
          return { ...element, name: "auditDate" };
        }
      }
      return element;
    });
    const postRes = await postData({ status, data });
    if (postRes?.data?.success === true) {
      if (status === "draft") {
        history.push("/admin/my-approvals/inspections");
        setFormData({});
        setReviewData([]);
      } else {
        if (status === "submitted") {
          showSuccessToast();
        } else {
          showApprovedToast();
        }
        setTimeout(() => {
          history.push("/admin/my-approvals/inspections");
        }, 1700);
      }
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    inspectionId &&
      dispatch(
        actions.fetchReviewData({
          payload: [inspectionId],
        })
      );
   
  }, [dispatch]);
 
  useEffect(() => {
    if (toBeReviewedData && toBeReviewedData?.length > 0) {
      const id = toBeReviewedData[0].workflowId
      dispatch(actions.fetchWorkflowData({ id }));
      let modifyReviewData = JSON.parse(JSON.stringify(toBeReviewedData))
      if (id === "0w8vf5o0wlegxbl4" || id === "8bk6b3tbzxp6xlnh" || id === "d8a4joybjndpg8l0") {
        modifyReviewData = modifyReviewData.map((obj) => {
          if (obj.name === "warehouseCode") {
            return { ...obj, name: "storeCode" };
          } else if (obj.name === "nameOfTheBank") {
            return { ...obj, name: "nameOfTheOrganization" };
          } else if(obj.name === "auditDate") {
            return { ...obj, name: "conductedOn" };
          }
          return obj;
        });
      }
      modifyReviewData = modifyReviewData?.reduce((acc, cv) => {
        acc[cv.templateItemId] = { ...cv };
        return acc;
      }, {});

      setFormData(modifyReviewData);
      setReviewData(modifyReviewData);
    }
  }, [toBeReviewedData]);

  useEffect(() => {
    if (sections && sections.length) {
      let data = sections.map((section) => getElements(section.id));
      const result = data.reduce((accumulator, currentArray) => {
        currentArray.forEach((item) => {
          const sectionId = item.sectionId;
          const elementId = item.element.id;

          if (!accumulator[sectionId]) {
            accumulator[sectionId] = [elementId];
          } else if (!accumulator[sectionId].includes(elementId)) {
            accumulator[sectionId].push(elementId);
          }
        });

        return accumulator;
      }, {});
      setdataArr(data);
      setSectionElementMap(result);
    }
  }, [sections]);
  //Logic to check any element change in review page
  const isObjectMatching = (id) => {
    const formObject = formData[id];
    const reviewObject = reviewData[id];
    const hasValueInForm = formObject && formObject.hasOwnProperty("value");
    const hasValueInReview =
      reviewObject && reviewObject.hasOwnProperty("value");

    // Compare "value" property first
    if (hasValueInForm || hasValueInReview) {
      if (
        typeof formObject?.value == "object" &&
        formObject?.value instanceof Date
      ) {
        return formObject?.value.toISOString() === reviewObject?.value;
      }
      if (
        formObject &&
        reviewObject &&
        formObject.value !== reviewObject.value
      ) {
        return false;
      }
    }

    // If both "value" properties are empty, then compare "attachments"
    const hasAttachmentsInForm =
      formObject && formObject.attachments && formObject.attachments.length > 0;
    const hasAttachmentsInReview =
      reviewObject &&
      reviewObject.attachments &&
      reviewObject.attachments.length > 0;

    if (hasAttachmentsInForm || hasAttachmentsInReview) {
      if (
        JSON.stringify(formObject.attachments) !==
        JSON.stringify(reviewObject.attachments)
      ) {
        return false;
      }
    }

    return true;
  };
  //Logic to conditionally showing sections based on Yes/No selection
  useEffect(() => {
    setDisabledSectionCheck([]);
    if (dataArr && dataArr.length) {
      let sectionMap = displayData?.reduce((acc, curr) => {
        acc[curr.pageNumber] = curr.sections[0].id;
        return acc;
      }, {});
      let sectionArr = displayData?.map((pageData) => pageData.sections[0]);
      dataArr.map((el) => {
        el.map((data) => {
          let currPage;
          let navPage;
          let currentSectionId = data.sectionId;
          let navSectionId;
          const value = formData[data.element.id]?.value;
          if (
            data.element.htmlType === "radio" &&
            data.element.properties.navigations
          ) {
            data.element.properties.navigations.forEach((navOption) => {
              if (value === navOption.value) {
                navSectionId = navOption.sectionId;
              }
            });
            sectionArr.forEach((section) => {
              if (navSectionId !== undefined) {
                if (section.id === currentSectionId) {
                  currPage = section.pageNumber;
                }
                if (section.id === navSectionId) {
                  navPage = section.pageNumber;
                }
              }
            });
            if (currPage && navPage) {
              setDisabledSectionCheck((prevState) => {
                let arr = [];
                for (let i = currPage + 1; i < navPage; i++) {
                  arr.push(sectionMap[i]);
                }
                return [...new Set([...prevState, ...arr])];
              });
            }
          }
        });
      });
    }
  }, [dataArr, formData]);
  useEffect(() => {
    dataArr.map((el) => {
      el.map((data) => {
        //Logic to check changes in any element to handle save and discard button enable/disable
        const toClearElementIds = disabledSectionCheck
          .map((sectionId) => {
            return sectionElementMap[sectionId];
          })
          .flat();
        const isMatching = isObjectMatching(data.element.id);
        setBtnStatusArr((prevState) => {
          let updatedState = [...prevState];
          if (isMatching) {
            if (updatedState.length === 0) return [];
            updatedState = updatedState.filter((id) => id !== data.element.id);
          } else {
            updatedState.push(data.element.id);
          }
          updatedState = updatedState.filter(
            (id) => !toClearElementIds.includes(id)
          );
          return [...new Set([...updatedState])];
        });
      });
    });
  }, [disabledSectionCheck]);

  const getErrorState = useCallback(() => {
    const allElementIds = [];
    getDisplayData()?.forEach((elementData) => {
      const elements = [];
      elementData.sections.forEach((section) => {
        const newElements = getElements(section.id);
        elements.push(...newElements);
      });
      elements.forEach((element) => {
        allElementIds.push(element.element.id);
      });
    });
    return allElementIds;
  }, [getDisplayData, getElements]);

  useEffect(() => {
    setAllElementsId(getErrorState());
  }, [getErrorState]);

  const handleValidate = () => {
    let isValid = true;
    const toClearElementIds = disabledSectionCheck
      .map((sectionId) => {
        return sectionElementMap[sectionId];
      })
      .flat();
    let dataToCheck = allElementsId.filter(
      (id) => !toClearElementIds.includes(id)
    );
    let data = getElementsState();
    dataToCheck.forEach((key) => {
      if (
        data[key]?.mandatory &&
        !formData[key]?.value &&
        !formData[key]?.attachments?.length
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  const handleResetClick = (id) => {
    const reviewObject = reviewData[id];
    setFormData({
      ...formData,
      [id]: reviewObject,
    });
  };

  const handleImageDownload = (imageName) => {
    dispatch(
      actions.downloadImage({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        imageName,
      })
    );
  };
if(loading || reviewLoading){
  return (
    <HStack mt={"75px"}>
      <CircularProgress
        isIndeterminate
        color="#4FD1C5"
        size="36px"
        alignSelf={"center"}
      />
      <Text>Loading...</Text>
    </HStack>
  );
}
  return (
    <VStack mt={"75px"} w="100%" gap={"36px"}>
      <Box w="100%" p={"32px"} bg={"#fff"} borderRadius={"15px"}>
        <HeaderGray
          workflow={workflow && workflow[0]}
          pageNumber={page}
          numPages={getDisplayData().length}
          hidePagesDetails={true}
        />
        <Box w="100%" style={{ background: "#F9F9FB", position: "relative" }}>
          <VStack
            w={isMobileScreen ? "100%" : "740px"}
            style={{
              margin: "0 auto",
              padding: "64px 24px",
            }}
            gap="64px"
            area-label="sections"
            justify="flex-start"
            align="flex-start"
          >
            {formData !== undefined &&
              sections &&
              sections.length &&
              JSON.parse(JSON.stringify(sections))
                .sort((a, b) => a.pageNumber - b.pageNumber)
                .map((section) => {
                  return (
                    <Section
                      key={section.id}
                      section={section}
                      data={getElements(section.id)}
                      formData={formData}
                      reviewData={reviewData}
                      handleFormDataChange={handleFormDataChange}
                      handleFilesChange={handleFilesChange}
                      displayData={displayData}
                      setPage={setPage}
                      navIndex={navIndex}
                      setNavIndex={setNavIndex}
                      handleResetClick={handleResetClick}
                      discardButton={discardButton}
                      handleImageDownload={handleImageDownload}
                      prevPages={prevPages}
                      disabledSectionCheck={disabledSectionCheck}
                      isObjectMatching={isObjectMatching}
                    />
                  );
                })}
          </VStack>
        </Box>
      </Box>
      {tempError ? (
        <HStack w="100%" justify="center" align="center">
          <Text
            aria-label="element label"
            color="#D93025"
            fontSize="14px"
            fontWeight="400"
            lineHeight="1.4"
            textAlign="center"
          >
            Please, Fill in all the mandatory fields.
          </Text>
        </HStack>
      ) : (
        <></>
      )}
      <HStack padding={"32px 0px"} w={"100%"} justifyContent={"space-between"}>
        <Button
          isDisabled={btnStatusArr.length === 0 ? true : false}
          variant={"outlined"}
          w={"244px"}
          h={"64px"}
          style={{
            border: "1px solid #E53E3E",
            color: "#E53E3E",
            borderRadius: "8px",
          }}
          onClick={onOpen2}
        >
          Discard All Changes
        </Button>
        <HStack>
          <Button
            isDisabled={btnStatusArr.length === 0 ? true : false}
            variant={"outlined"}
            w={"244px"}
            h={"64px"}
            style={{
              border: "1px solid #38b2ac",
              color: "#38b2ac",
              borderRadius: "8px",
            }}
            // onClick={() => {
            //   onOpen1();
            //   // handleSubmit("submitted");
            // }}
            onClick={onOpen1}
          >
            Save Changes
          </Button>
          <Button
            variant={"solid"}
            w={"244px"}
            h={"64px"}
            style={{
              border: "1px solid #48BB78",
              color: "#48BB78",
              borderRadius: "8px",
            }}
            onClick={() => {
              if (handleValidate()) {
                setTempError(false);
                onOpen();
              } else {
                setTempError(true);
              }
            }}
          >
            Approve
          </Button>
        </HStack>
      </HStack>
      <AreYouSureModal
        handleSubmit={(status) => {
          handleSubmit(status);
        }}
        isOpen={isOpen}
        onClose={onClose}
        status={"reviewed"}
        msg="Are you sure you want to submit changes?"
        btnText="Submit"
      />
      <AreYouSureModal
        isOpen={isOpen1}
        onClose={onClose1}
        handleSubmit={(status) => {
          handleSubmit(status);
        }}
        status={"submitted"}
        msg="Are you sure you want to save changes?"
        btnText="Save"
      />
      <DiscardModal
        isOpen={isOpen2}
        onClose={onClose2}
        msg="Are you sure you want to discard all changes?"
        btnText="Discard"
        setFormData={setFormData}
        setDiscardButton={setDiscardButton}
        discardButton={discardButton}
        reviewData={reviewData}
      />
    </VStack>
  );
}
