import React, { useState, useEffect } from "react";
import { Stack, Box, Radio, RadioGroup } from "@chakra-ui/react";

export default function SingleSelect({
  data, //getElement(sectionId)
  handleFormDataChange,
  formData,
  displayData, //Section
  navIndex,
  setNavIndex,
  setPage,
  setSkippedPages,
  prevPages,
  page,
}) {
  useEffect(() => {
    let sectionId = "";
    let newPageNumber = navIndex;
    const value = formData[data.element.id]?.value;
    if (data.element.properties.navigations) {
      data.element.properties.navigations.forEach((navOption) => {
        if (value === navOption.value) {
          sectionId = navOption.sectionId;
        }
      });
    }

    displayData?.forEach((pageData) => {
      pageData.sections.forEach((section) => {
        if (section.id === sectionId) {
          newPageNumber = pageData.pageNumber - 1;
        }
      });

      if (navIndex !== newPageNumber) {
        setNavIndex(newPageNumber);
        setSkippedPages((skippedPages) => {
          return {
            from: page + 1,
            to: newPageNumber + 1,
          };
        });
      }
    });
  }, [formData, data, displayData, setNavIndex, navIndex]);

  useEffect(() => {
    if (
      !data.element.properties.navigations &&
      data.element.default &&
      data.element.default.value &&
      !formData[data.element.id]?.value
    ) {
      handleFormDataChange(data.element.default.value, null, data.element.id);
    }
  }, [formData]);

  return (
    <Stack>
      <RadioGroup
        defaultValue={
          data.element.properties.navigations
            ? null
            : formData[data.element.id]?.value ||
              (data.element.default && data.element.default.value)
        }
        name={data.element.id}
        value={formData[data.element.id]?.value}
        onChange={(val) => {
          //console.log(val);
          handleFormDataChange(val, null, data.element.id);
        }}
      >
        {data ? (
          data.element.properties.options.map((option) => {
            return (
              <Box
                style={{
                  border: "1px solid #EDF2F7",
                  borderRadius: "8px",
                  padding: "12px",
                  margin: "8px",
                }}
                key={option.value}
              >
                <Radio
                  size="md"
                  colorScheme="login"
                  isChecked
                  value={option.value}
                >
                  {option.value}
                </Radio>
              </Box>
            );
          })
        ) : (
          <></>
        )}
      </RadioGroup>
    </Stack>
  );
}
