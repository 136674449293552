import React, { useEffect, useState } from "react";
import { Select } from "@agnext/reactlib";

export default function Dropdown2({
  data,
  formData,
  handleFormDataChange,
  reviewData,
  discardButton,
}) {
  // if (data.element.id === "mf52jirs6w4isesx") {
  //   //console.log(formData[data.element.id]?.value);
  // }

  const [optionValue, setOptionValue] = useState(
    formData[data.element.id]?.value || ""
  );
  useEffect(() => {
    if (optionValue && optionValue !== formData[data.element.id]?.value) {
      handleFormDataChange(optionValue, "", data.element.id, "NA");
    }
  }, [optionValue, data.element.id]);

  //console.log(optionValue);

  useEffect(() => {
    setOptionValue(formData[data.element.id]?.value);
  }, [data.element.id, reviewData]);

  useEffect(() => {
    setOptionValue(formData[data.element.id]?.value);
  }, [discardButton]);

  return (
    <Select
      value={optionValue}
      size="md"
      name="1"
      focusBorderColor="login.500"
      options={data.element.properties.options}
      onChange={(e) => setOptionValue(e.target.value)}
    />
  );
}
