import {
  Button,
  Modal,
  ModalContent,
  ModalBody,
  Box,
  VStack,
  CircularProgress,
  HStack,
} from "@chakra-ui/react";

// Custom components
import React from "react";
import { ActionIcon } from "components/Icons/Icons";
import { useState } from "react";
import { ViewReportIcon } from "components/Icons/Icons";
import { DownloadReportIcon } from "components/Icons/Icons";
import MultiEmailInput from "components/MultiEmailInput/MultiEmailInput";
import { Pagination, Table } from "@agnext/reactlib";
import noRecordImage from "assets/img/Empty Case.png";
import { capitalizeString } from "utils/commonFunctions";
import "../index.css";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
};
const InspectionDataTable = ({
  data,
  onClickViewReport,
  onClickDownloadReport,
  heading,
  page,
  numPages,
  handlePageSelect,
  handleNextPage,
  handlePreviousPage,
  isLoadingPagination
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showSuccess, setShowSuccess] = useState("");
  const [showError, setShowError] = useState("");
  const [selectedActionIconPosition, setSelectedActionIconPosition] = useState({
    x: 0,
    y: 0,
  });
  const [inspectionDetails, setInspectionDetails] = useState({
    fileName: null,
    inspectionId: null,
    warehouseCode: null,
    auditDate: null,
  });

  const handleOpenModal = (e) => {
    e.preventDefault(); // Prevent default link behavior
    e.stopPropagation();
    const iconPosition = e.currentTarget.getBoundingClientRect();

    setSelectedActionIconPosition({
      x: iconPosition.left,
      y: iconPosition.top,
    });
    setIsOpen(true);
    setShowSuccess("");
    setShowError("");
  };

  const visibleHeadings = heading?.filter(
    (heading) => heading.visible === true
  );

  const headerArray = visibleHeadings?.map((obj) => {
    if (obj.value === "action") {
      return {
        keyText: obj.value,
        displayText: obj.display,
      };
    }
    if (typeof data.value !== Number) {
      return {
        keyText: obj.value,
        displayText: obj.display,
        hasTooltip: true,
        hasSort: true,
      };
    }
    return {
      keyText: obj.value,
      displayText: obj.display,
      hasSort: true,
    };
  });
  let capitalizedArray =
    data &&
    data.map((obj) => {
      const lowercasedObject = {};
      for (const key in obj) {
        lowercasedObject[key] =
          typeof obj[key] === "string" && obj[key] !== "N.A."
            ? key === "inspectionReport"
              ? obj[key]
              : capitalizeString(obj[key]?.toLowerCase())
            : obj[key];
      }
      return lowercasedObject;
    });

  // If Key is missing in data, inserted with value "N.A."
  function checkKeysAndInsert(heading, capitalisedArray) {
    for (let i = 0; i < capitalisedArray.length; i++) {
      const obj2 = capitalisedArray[i];

      for (let j = 0; j < heading.length; j++) {
        const key = heading[j].value;

        if (!(key in obj2)) {
          obj2[key] = "N.A.";
        }
      }
    }
    return capitalisedArray;
  }

  capitalizedArray =
    heading.length > 0 && capitalizedArray.length > 0
      ? checkKeysAndInsert(heading, capitalizedArray)
      : capitalizedArray;

  data = capitalizedArray?.map((inspection, i) => {
    const auditDate = inspection?.auditDate || "N.A"; // Or null or undefined if not present audit date
    let formattedDate = "N.A.";

    if (auditDate !== null && auditDate !== "N.A.") {
      const date = new Date(auditDate);
      date.setHours(date.getHours() + 5);
      date.setMinutes(date.getMinutes() + 30);
      formattedDate = date.toISOString().split('T')[0];
    }
    return {
      ...inspection,
      auditDate: formattedDate,
      warehouseCode: inspection?.warehouseCode?.toUpperCase() || "N.A.",
      action: (
        <ActionIcon
          w="20px"
          h="20px"
          mx="16px"
          onClick={(e) => {
            if (inspection) handleFileName(inspection);
            handleOpenModal(e);
          }}
          _hover={{
            cursor: "pointer",
          }}
        />
      ),
    };
  });

  const handleShowSuccess = (value) => {
    setShowSuccess(value);
  };

  const handleShowError = (value) => {
    setShowError(value);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setInspectionDetails(null);
  };

  const handleFileName = (inspection) => {
    setInspectionDetails({
      fileName: inspection.inspectionReport,
      inspectionId: inspection.id,
      warehouseCode: inspection.externalCode,
      auditDate: inspection.auditDate,
    });
  };

  return (
    <>
        <HStack display={isLoadingPagination ? "flex" :"none" } justifyContent={"center"}>
          <CircularProgress isIndeterminate color="#4FD1C5" size="36px"/>
        </HStack>  
        <Box display={isLoadingPagination ? "none" : "block"}>
        <Box display={"flex"} justifyContent={"flex-end"} w={"100%"} mb={"10px"} >
        <Pagination
          page={page}
          numPages={numPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Box>
        <VStack
            borderRadius="15px"
            padding="0px"
            backgroundColor="white"
            border= {"1px solid #efefef"}
            minH={"400px"}
            >
            {data.length > 0 ? (
              <Table
                headerList={headerArray}
                dataList={data}
                stickyColumn={["Action"]}
              />
            ) : (
              <Box style={styles.message}>
                <img
                  style={styles.image}
                  src={noRecordImage}
                  alt="No Records Image"
                />
                <p>No Records Found</p>
              </Box>
            )}
            {isOpen && (
              <Modal isOpen={isOpen} onClose={handleCloseModal} display="flex">
                <ModalContent
                  className="action-btn-dropdown"
                  top={selectedActionIconPosition.y + -35 + "px"}
                  left={selectedActionIconPosition.x + -308 + "px"}
                >
                  <Box
                    display="flex"
                    rowGap="20px!important"
                    flexDirection="column"
                  >
                    <Button
                      variant="outline"
                      className="view-report-btn"
                      rightIcon={<ViewReportIcon />}
                      onClick={() => {
                        handleCloseModal();
                        onClickViewReport(inspectionDetails.fileName);
                      }}
                    >
                      View Report
                    </Button>
                    <Button
                      className="download-report-btn"
                      rightIcon={<DownloadReportIcon />}
                      variant="outline"
                      onClick={() => {
                        handleCloseModal();
                        onClickDownloadReport(inspectionDetails);
                      }}
                    >
                      Download Report
                    </Button>
                    <ModalBody className="email-head">Email Report:</ModalBody>
                    <MultiEmailInput
                      inspectionId={inspectionDetails.inspectionId}
                      showSuccess={showSuccess}
                      handleShowSuccess={handleShowSuccess}
                      showError={showError}
                      handleShowError={handleShowError}
                    />
                  </Box>
                </ModalContent>
              </Modal>
            )}
          {/* </Box> */}
        </VStack>
        </Box>
      <Box display={"flex"} justifyContent={"flex-end"} w={"100%"} mt={"10px"}>
        <Pagination
          page={page}
          numPages={numPages}
          handlePageSelect={handlePageSelect}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          colorActive="#4FD1C5"
        />
      </Box>
    </>
  );
};

export default InspectionDataTable;
