import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import { actions } from "./slice";

import * as api from "../../services/apis";

function* fetchUserRole(action) {
  try {
    const response = yield call(api.userRoles, action.payload);
    yield put(actions.fetchUserRoleSuccess(response.data));
  } catch (e) {
    yield put(actions.fetchUserRoleFaliure(e));
    //console.log(e);
  }
}

function* fetchEditRole(action) {
  try {
    const { roleId } = action.payload || {};
    const response = yield call(api.editRole, roleId);
    yield put(actions.fetchEditRoleSuccess(response.data));
  } catch (e) {
    yield put(actions.fetchEditRoleFaliure(e));
  }
}

function* fetchPermissionsData(action) {
  try {
    const response = yield call(api.permissionsData);
    yield put(actions.fetchPermissionsDataSuccess(response.data));
  } catch (e) {
    yield put(actions.fetchPermissionsDataFaliure(e));
  }
}

function* handlePostUserRole(action) {
  const { payload } = action;
  try {
    const response = yield call(api.postUserData, payload.newRoleObject);
    //console.log(payload);
    payload.onSuccess(response.data.success);
    yield put(actions.fetchPostUserRoleSuccess(response.data));
  } catch (error) {
    payload.onSuccess(error.response.data.success, error.response.data.reason);
    yield put(actions.fetchPostUserRoleFailure(error));
  }
}
function* handleUpdateRole(action) {
  try {
    //console.log(action);
    const response = yield call(api.updateRoleData, action.payload);
    //console.log(response);
    action.payload.onSuccess(response.data.success);
    yield put(actions.fetchUserDetailsSuccess(response));
  } catch (error) {
    //console.log(error);
    action.payload.onSuccess(
      error.response.data.success,
      error.response.data.reason
    );

    yield put(actions.fetchUserDetailsFaliure(error));
  }
}

function* fetchRolePermissionData(action) {
  try {
    const { roleId } = action.payload;
    const response = yield call(api.rolePermissionData, roleId);
    yield put(actions.fetchRolePermissionsDataSuccess(response.data));
  } catch (error) {
    yield put(actions.fetchRolePermissionsDataFaliure(error));
  }
}

function* fetchUserDetails(action) {
  try {
    const response = yield call(api.userDetails, action.payload);
    yield put(actions.fetchUserDetailsSuccess(response));
  } catch (error) {
    yield put(actions.fetchUserDetailsFaliure(error));
  }
}

function* handleUserAssignedData(action) {
  try {
    const { roleId } = action.payload;
    const response = yield call(api.userAssignedData, roleId);
    yield put(actions.fetchUserAssignedDataSuccess(response));
  } catch (error) {
    yield put(actions.fetchUserAssignedDataFaliure(error));
  }
}
function* handleDeleteRole(action) {
  try {
    const response = yield call(api.deleteRole, action.payload);
    yield put(actions.fetchDeleteRoleSuccess(response));
  } catch (error) {
    yield put(actions.fetchDeleteRoleFaliure(error));
  }
}
function* fetchSingleUsersData(action) {
  try {
    const response = yield call(api.getSingleUsers, action.payload);
    yield put(actions.fetchSingleUsersSuccess(response));
  } catch (e) {
    yield put(actions.fetchSingleUsersFaliure(e));
  }
}

function* handleDeactivateRole(action) {
  try {
    const response = yield call(api.deactivateRole, action.payload);
    yield put(actions.fetchDeactivateRoleSuccess(response));
  } catch (e) {
    yield put(actions.fetchDeactivateRoleFaliure(e));
  }
}

function* handleActivateRole(action) {
  try {
    const response = yield call(api.activateRole, action.payload);
    yield put(actions.fetchActivateRoleSuccess(response));
  } catch (e) {
    yield put(actions.fetchActivateRoleFaliure(e));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}
export function* watchRolesAndPermission() {
  yield takeLatest(actions.fetchUserRoleRequest.type, fetchUserRole);
  yield takeEvery(actions.fetchEditRoleRequest.type, fetchEditRole);

  yield takeLatest(actions.fetchPostUserRoleRequest.type, handlePostUserRole);

  yield takeLatest(actions.fetchEditRoleRequest.type, fetchEditRole);
  yield takeEvery(actions.fetchUserDetailsRequest.type, fetchUserDetails);
  yield takeLatest(
    actions.fetchPermissionsDataRequest.type,
    fetchPermissionsData
  );
  yield takeEvery(
    actions.fetchRolePermissionsDataRequest.type,
    fetchRolePermissionData
  );

  yield takeEvery(actions.fetchRoleUpdateDataRequest.type, handleUpdateRole);
  yield takeEvery(
    actions.fetchUserAssignedDataRequest.type,
    handleUserAssignedData
  );
  yield takeEvery(actions.fetchDeleteRoleRequest.type, handleDeleteRole);
  yield takeEvery(actions.fetchSingleUsersRequest.type, fetchSingleUsersData);
  yield takeEvery(
    actions.fetchDeactivateRoleRequest.type,
    handleDeactivateRole
  );
  yield takeEvery(actions.fetchActivateRoleRequest.type, handleActivateRole);
  yield takeLatest(actions.clearState.type, handleClearState);
}
