// import { Input } from "@agnext/reactlib";
import { ButtonGroup, IconButton } from "@chakra-ui/button";
import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Input } from "@chakra-ui/input";
import { Box, HStack } from "@chakra-ui/layout";
import { useState } from "react";

function EditableText({ text, handleTextChange, handleSubmit }) {
  const [isEditing, setisEditing] = useState(false);
  
  const [textboxValue, settextboxValue] = useState(text);

  const handleClose = () => {
    setisEditing(!isEditing);
    settextboxValue(text);
  };

  const handleTextboxChange = (e) => {
    settextboxValue(e.target.value);
    // handleTextChange(textboxValue)
  };
  return !isEditing ? (
    <Box w={"100%"}>
      {/* <HStack> */}
      {text}
      <IconButton
        size="sm"
        icon={<EditIcon color={"#4FD1C5"} />}
        style={{
          background: "none",
        }}
        _focus={{ boxShadow: "none" }}
        onClick={() => {
          handleClose();
        }}
      />
    </Box>
  ) : (
    <>
      <HStack>
        <Input
          value={textboxValue}
          onChange={(e) => {
            handleTextboxChange(e);
            handleTextChange(textboxValue);
          }}
          w={"100%"}
          variant="flushed"
          colorScheme="login"
          focusBorderColor="rgba(0, 0, 0, 0)"
          style={{ borderBottom: "1px solid #EFEFEF" }}
        />
        <ButtonGroup justifyContent="center" size="sm" spacing={-2}>
          <IconButton
            icon={<CheckIcon w={4} h={3} />}
            style={{ background: "none" }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              handleSubmit(textboxValue);
              handleTextChange(textboxValue);
              setisEditing(false)
            }}
          />
          <IconButton
            icon={<CloseIcon w={4} h={3} />}
            style={{ background: "none" }}
            _focus={{ boxShadow: "none" }}
            onClick={handleClose}
          />
        </ButtonGroup>
      </HStack>
    </>
  );
}
export default EditableText;
