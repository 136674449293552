import React from "react";
import {
  HStack,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  VStack,
  Text,
} from "@chakra-ui/react";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

export default function WorkflowAction({ id }) {
  return (
    <Popover
      autoFocus={false}
      closeOnBlur={true}
      closeOnEsc={true}
      closeOnOverlayClick={true}
    >
      {({ isOpen, onClose }) => (
        <>
          <PopoverTrigger>
            <HStack style={{ cursor: "pointer" }} align="center">
              <IoEllipsisVerticalSharp />
            </HStack>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              w="120px"
              style={{
                outline: "none",
                border: "1px solid #E2E8F0",
                padding: "8px",
                boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
              }}
            >
              <VStack gap="8px">
                <Link to={`/admin/update/${id}`}>
                  <HStack justify="center">
                    <Text>Edit</Text>
                  </HStack>
                </Link>
                <Link to={`/admin/view/${id}`}>
                  <HStack justify="center">
                    <Text>View</Text>
                  </HStack>
                </Link>
              </VStack>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
}
