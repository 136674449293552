import React from "react";
import { UnorderedList, ListItem, Box, Text, Flex } from "@chakra-ui/react";

const styles = {
  text: {
    color: "#667085",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  ratingContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
  },
};

function RatingList() {
  const getColorForRating = (rating) => {
    switch (rating) {
      case "Good":
        return "#39A39A";
      case "Average":
        return "#FFB783";
      case "Satisfactory":
        return "#FF6D6D";
      default:
        return "#000"; // Default color
    }
  };

  const ratings = ["Good", "Average", "Satisfactory"];

  return (
    <UnorderedList listStyleType="none" p={0} sx={styles.ratingContainer}>
      {ratings.map((rating, index) => (
        <ListItem key={index}>
          <Flex alignItems="center">
            <Box
              bgColor={getColorForRating(rating)}
              borderRadius="50%"
              w="8px"
              h="8px"
              mr="8px"
            />
            <Text sx={styles.text}>{rating}</Text>
          </Flex>
        </ListItem>
      ))}
    </UnorderedList>
  );
}

export default RatingList;
