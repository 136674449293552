import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Input,
  VStack,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { FiUploadCloud, FiFile } from "react-icons/fi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { fetchSasurl, uploadFile } from "services/index";
import { sliceKey, reducer } from "../../pages/generateTemplate/slice";
import { useInjectReducer } from "redux-injectors";
// import SparkMD5 from "spark-md5";

export default function FileUpload({ data, formData, handleFormDataChange }) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const [filesToUpload, setFilesToUpload] = useState([]);
  // const [savedFileHash, setSavedFileHash] = useState({});
  const [savedFileNames, setSavedFileName] = useState({});

  const [filesForDisplay, setFilesForDisplay] = useState(
    formData[data.element.id]?.attachments || []
  );
  const toast = useToast();

  useEffect(() => {
    // Check if formData[data.element.id] is available
    const initialFiles = formData[data.element.id]?.attachments || [];
    const fileNames =
      initialFiles.map((fileName) => {
        let deleteFileName =
          fileName.split("_perfeqt_")[0] + "." + fileName.split(".")[1];
        return deleteFileName;
      }) || [];
    setFilesForDisplay(initialFiles);
    setSavedFileName({ [data.element.id]: fileNames });
  }, [formData, data.element.id]);

  const handleInputClick = (id) => {
    document.getElementById(id).click();
  };

  // //console.log({ filesToUpload });
  const showWarningToast = () => {
    toast({
      title: "File Already Uploaded",
      status: "info",
      duration: 1300,
      isClosable: true,
    });
  };
  const handleFileChange = async (e) => {
    let files = [...filesToUpload];
    files.push(...Array.from(e.target.files));

    // Hashing and removing duplicate files which will be uploaded in one go.
    // //console.log("files", files.length);
    // let hashedFiles = [];
    // const promises = files.map((file) => {
    //   return new Promise((resolve) => {
    //     var fileReader = new FileReader();
    //     fileReader.readAsArrayBuffer(file);
    //     fileReader.onloadend = async function () {
    //       let hash = SparkMD5.ArrayBuffer.hash(fileReader.result);
    //       hashedFiles.push(hash);
    //       //console.log("added");
    //       resolve();
    //     };
    //   });
    // });
    // //console.log(promises.length);
    // let uniqueFiles;
    // Promise.all(promises).then(() => {
    //   uniqueFiles = hashedFiles.reduce((acc, curr, index) => {
    //     if (
    //       savedFileHash[data.element.id] &&
    //       Object.values(savedFileHash[data.element.id]).includes(curr)
    //     ) {
    //       return acc;
    //     }

    //     acc[curr] = files[index];
    //     setSavedFileHash((prevState) => {
    //       //console.log(index, files, files.length, "hash", hashedFiles.length);
    //       let key = files[index].name;
    //       // let currentObj = {
    //       //   [key]: curr,
    //       // };
    //       if (!prevState[data.element.id]) {
    //         prevState[data.element.id] = {};
    //       }
    //       prevState[data.element.id][key] = curr;
    //       return prevState;
    //     });
    //     return acc;
    //   }, {});
    // });
    // uniqueFiles = Object.values(uniqueFiles);
    // files = uniqueFiles;
    // setFilesToUpload(uniqueFiles);

    //  Hashing and removing duplicate files which will be uploaded in one go.

    const fileList = files.map((file) => file.name);

    let uniqueFiles = files.filter((file) => {
      if (
        savedFileNames[data.element.id] &&
        savedFileNames[data.element.id].includes(file.name)
      ) {
        showWarningToast();
        return false;
      } else {
        setSavedFileName((prevState) => {
          return {
            ...prevState,
            [data.element.id]: [
              ...(prevState[data.element.id] || []),
              file.name,
            ],
          };
        });
        return true;
      }
    });

    // uniqueFiles = Object.values(uniqueFiles);
    // files = uniqueFiles;
    setFilesToUpload(uniqueFiles);

    // setFilesToUpload(files);

    files = Object.values(uniqueFiles);

    const fileNames = [...filesForDisplay];

    let mimeTypes =
      formData[data.element.id]?.mimeType &&
      formData[data.element.id]?.mimeType !== "NA"
        ? formData[data.element.id]?.mimeType
        : "";

    // //console.log({ files });

    const token = localStorage.getItem("token");

    files.forEach(async (file) => {
      const fileNameArr = file.name.split(".");
      const type = fileNameArr.pop();
      const fileName = `${fileNameArr.join("")}_perfeqt_${
        data.element.id
      }.${type}`;

      fileNames.push(fileName);

      mimeTypes = mimeTypes ? mimeTypes + "," + file.type : file.type;

      let sasURLData;

      if (token) {
        sasURLData = await fetchSasurl({
          fileName,
          token: JSON.parse(token),
        });
      } else {
        sasURLData = await fetchSasurl({
          fileName,
        });
      }

      const sasURL = sasURLData && sasURLData.data.payload.url;

      const uploadResponse = await uploadFile({ file, sasURL });
    });

    setFilesForDisplay(fileNames);

    setFilesToUpload([]);

    handleFormDataChange(null, fileNames, data.element.id, mimeTypes);
  };
  const handleFileDelete = (index, fileName) => {
    // const newFiles = filesToUpload.filter((file, i) => i !== index);
    // setFilesToUpload(newFiles);
    let deleteFileName =
      fileName.split("_perfeqt_")[0] + "." + fileName.split(".")[1];

    const fileNames = filesForDisplay.filter((file, i) => i !== index);

    setSavedFileName((prevState) => {
      const updatedState = {
        ...prevState,
        [data.element.id]: prevState[data.element.id].filter(
          (file) => file !== deleteFileName
        ),
      };
      return updatedState;
    });

    // setSavedFileHash((prevState) => {
    //   let objCopy = JSON.parse(JSON.stringify(prevState));
    //   // let newObj = prevState[data.element.id].filter(
    //   //   (file) => fileName !== file
    //   // );
    //   let delteFileName = fileName.split("_")[0] + "." + fileName.split(".")[1];
    //   delete objCopy[data.element.id][delteFileName];
    //   //console.log("delete from hashed", fileName, objCopy, delteFileName, [
    //     objCopy[data.element.id][delteFileName],
    //   ]);
    //   return objCopy;
    // });
    let mimeTypes =
      formData[data.element.id]?.mimeType &&
      formData[data.element.id]?.mimeType !== "NA"
        ? formData[data.element.id]?.mimeType
        : "";
    mimeTypes = mimeTypes
      .split(",")
      .filter((file, i) => i !== index)
      .join(",");

    // let mimeTypes = "";

    // newFiles.forEach(async (file) => {
    //   const fileName = `${file.name.split(".")[0]}_${data.element.id}.${
    //     file.name.split(".")[1]
    //   }`;

    //   fileNames.push(fileName);

    //   mimeTypes = mimeTypes ? mimeTypes + "," + file.type : file.type;
    // });
    //console.log({ filesForDisplay, fileNames });

    handleFormDataChange(null, fileNames, data.element.id, mimeTypes);
  };

  return (
    <Box>
      <Input
        id={data.element.id}
        onChange={(e) => {
          // //console.log(e.target.files[0]);
          // setFilesToUpload(e.target.files);
          handleFileChange(e);
        }}
        style={{ display: "none" }}
        type="file"
        accept="image/*,application/pdf"
        multiple
      />
      <HStack
        style={{
          width: "100%",
          border: "2px dashed #E2E8F0",
          borderRadius: "8px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          padding: "20px 40px 40px 40px",
        }}
        onClick={() => {
          handleInputClick(data.element.id);
        }}
      >
        <VStack align="center">
          <Box
            style={{
              padding: "8px",
              border: "1px solid #EDF2F7",
              borderRadius: "8px",
            }}
          >
            <FiUploadCloud size="24px" color="#718096" />
          </Box>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
          >
            Size limit: 10MB
          </Text>
          <Text
            fontSize="10px"
            fontWeight="400"
            color="#718096"
            lineHeight="1.4"
            w={isMobileScreen ? "100%" : "442px"}
            textAlign="center"
          >
            .doc, .docx, .txt, .rtf, .odt, .ppt, .pptx, .odp, .ods, .csv, .xls,
            .xlsx, .nymbers, .key, .png, .jpg, .gif, .json, .xml, .zip, .rar,
            .mp3, .wav, .aiff, .pbix, .pdf
          </Text>
        </VStack>
      </HStack>
      {filesForDisplay && filesForDisplay.length > 0 ? (
        filesForDisplay.map((file, index) => {
          return (
            <HStack
              w="100%"
              justify="space-between"
              align="center"
              key={index}
              style={{
                padding: "6px 12px",
                background: "#F7FAFC",
                borderRadius: "8px",
                margin: "14px auto",
              }}
            >
              <HStack>
                <FiFile size="22px" color="#718096" />
                <Text
                  color="#4A5568"
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="1.4"
                >
                  {file?.length > 18
                    ? `${file.slice(0, 18)}...${file.slice(file.length - 6)}`
                    : file}
                </Text>
              </HStack>
              <span
                style={{ padding: "6px", cursor: "pointer" }}
                onClick={() => handleFileDelete(index, file)}
              >
                <IoCloseCircleOutline size="24px" color="#718096" />
              </span>
            </HStack>
          );
        })
      ) : (
        <></>
      )}
    </Box>
  );
}
