import React, { useCallback, useEffect, useState } from "react";
import {
  HStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  VStack,
  Box,
} from "@chakra-ui/react";
import { Select, Button } from "@agnext/reactlib";
import { useSelector, useDispatch } from "react-redux";
import * as selectors from "../../pages/Create/selectors";
import { actions } from "../../pages/Create/slice";
import { ExternalLinkIcon, CloseIcon } from "@chakra-ui/icons";

export default function LinkTable({ tableName, setTableName, handleSubmit }) {
  const tableNameOptions = [
    { label: "Inspection_Items", value: "inspectionItems" },
    { label: "Inspection_Job_Details", value: "inspectionJobDetails" },
    { label: "Inspection_Audit_Data", value: "inspectionAuditData" },
    { label: "Inspections", value: "inspections" },
  ];

  const dispatch = useDispatch();

  const handleTableNameChange = (e) => {
    let label = "";
    tableNameOptions.forEach((option) => {
      if (option.value === e.target.value) {
        label = option.label;
      }
    });
    const newOption = { label, value: e.target.value };
    // //console.log({newOption})
    handleSubmit(newOption.value);
    setTableName(newOption);
  };

  return (
    <Box w="300px">
      <Select
        value={tableName.value}
        onChange={handleTableNameChange}
        options={tableNameOptions}
        placeholder={`Select Table`}
        focusBorderColor="login.500"
      />
    </Box>
  );
}
