export const styles = {
  mainContainer: {
    gap: "36px",
    paddingTop: "72px",
    overflowX: "hidden",
  },
  filtersContainer: {
    padding: "16px 20px",
    justifyContent: "space-between",
    width: "100%",
    backgroundColor: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    borderRadius: "15px",
    gap: "16px",
    border: "1px solid #E2E8F0",
  },
  insightsContainer: {
    display: "flex",
    flexDirection: "column",
    alignSelf: "stretch",
  },
  button: {
    backgroundColor: "#F7FAFC",
    color: "#4F4F4F",
    fontSize: "12px",
    borderRadius: "8px",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    height: "39px",
    fontWeight: "600",
    lineHeight: "24px",
  },
  barChartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "36px",
    alignSelf: "stretch",
    borderRadius: "15px",
    border: "1px solid #E2E8F0",
    background: " #FFF",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
    // height: "454px",
  },
  headerContainer: {
    width: "100%",
    justifyContent: "space-between",

    alignItems: "flex-start",
  },
  header: {
    color: "#7E8DA2",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "150%",
  },
  chartHeaderBtn: {
    padding: "0px 12px",
    justifyContent: "center",
    height: "32px",
    alignItems: "center",
    border: "1px solid #EDF2F7",
    color: "#4A5568",
    fontFamilpy: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "20px",
    cursor: "pointer",
  },
  barChart: {
    width: "100%",
    height: "270px",
  },
  doughnutChartOuterContainer: {
    width: "100%",
    alignItems: "flex-start",
    alignSelf: "stretch",
  },
  doughnutChartContainer: {
    width: "100%",
    alignItems: "flex-start",
    gap: "36px",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "15px",
    border: "1px solid var(--gray-200, #E2E8F0)",
    backgroundColor: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  },
  doughnutContainer: {
    overflow: "hidden",
  },
  quatityInsightsContainer: {
    // width: "100%",
    alignItems: "flex-start",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "15px",
    background: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    border: "1px solid #E2E8F0",
    overflow: "hidden",
  },
  quantityHeaderContainer: {
    width: "100%",
    padding: "20px",
  },
  gaugeOuterContainer: {
    width: "100%",
  },
  gaugeChartContainer: {
    width: "100%",
    padding: "20px",
    alignItems: "center",
    gap: "36px",
    flex: "1 0 0",
    borderRadius: "15px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
  },
  warehouseInsightsContainer: {
    borderRadius: "15px",
    border: "1px solid #E2E8F0",
    background: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    gap: "0px",
  },
  geoInsightsContainer: {
    borderRadius: "15px",
    background: "#FFF",
    boxShadow: "0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02)",
    gap: "0px",
    border: "1px solid #E2E8F0",
  },
  geoheaderContainer: {
    width: "100%",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
  },
  barChartSelect: {
    borderRadius: "8px",
    width: "182px",
    border: "1px solid #E2E8F0",
    background: "#F7FAFC",
    display: "flex",
    height: "32px",
    padding: " 6px 8px 6px 12px",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    gap: "12px",
    color: "#4A5568",
    fontSize: "12.5px",
  },
  barChartSelectOpt: {
    color: "#2D3748",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
  },
  greenCircle: {
    position: "absolute",
    top: "-2.5px",
    right: "-3.5px",
    width: "12px",
    height: "12px",
    backgroundColor: "#319795",
    borderRadius: "50%",
    border: "1px solid white",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    alignSelf: "center",
  },
  message: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "22px",
  },
  image: {
    maxWidth: "100%",
    height: "auto",
    margin: "auto",
  },
};
