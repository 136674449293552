import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../Create/slice";
import { UpdateWorkflowDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import Editable from "../../Components/Editable";
import { Box, HStack } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";
import Section from "./Section";
import { AddIcon } from "@chakra-ui/icons";
import { generateId } from "utils/index";

export default function Update() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: UpdateWorkflowDataSaga });

  const dispatch = useDispatch();
  const { workflowId } = useParams();

  const workflowData = useSelector(selectors.selectWorkflow);
  const sections = useSelector(selectors.selectSectionsForBlocks) || [];
  const blocks = useSelector(selectors.selectBlocksForComposites) || [];
  const composites = useSelector(selectors.selectCompositesForComponents) || [];
  const components = useSelector(selectors.selectComponentsForElements) || [];

  const [workflow, setWorkflow] = useState("Workflow");

  const [description, setDescription] = useState("Description");

  const sectionsFromWorkflow =
    (workflowData &&
      workflowData.structure &&
      workflowData.structure.sections) ||
    [];

  useEffect(() => {
    setWorkflow(workflowData && workflowData.title);
    setDescription(workflowData && workflowData.description);
  }, [workflowData]);

  // const [client, setClient] = useState("Client");

  // const [application, setApplication] = useState("Application");

  useEffect(() => {
    dispatch(actions.fetchWorkflowData({ id: workflowId }));
  }, [dispatch, workflowId]);

  const handleUpdateData = () => {
    //console.log("Update clicked");
    const onSuccess = () => {
      alert("Updated Successfully");
    };
    const onError = () => {
      alert("Something Went Wrong");
    };
    dispatch(
      actions.postWorkflowData({
        data: {
          form: workflowData,
          sections,
          blocks,
          composites,
          components,
        },
        onSuccess,
        onError,
      })
    );
  };

  const handleWorkflowChange = (value) => {
    setWorkflow(value);
  };

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  // const handleClientChange = (value) => {
  //   setClient(value);
  // };

  // const handleApplicationChange = (value) => {
  //   setApplication(value);
  // };

  const handleWorkflowSubmit = (workflow) => {
    dispatch(actions.editWorkflowName(workflow));
  };

  const handleDescriptionSubmit = (description) => {
    dispatch(actions.editWorkflowDescription(description));
  };

  // const handleClientSubmit = (client) => {
  //   dispatch(actions.editWorkflowClient(client));
  // };

  // const handleApplicationSubmit = (application) => {
  //   dispatch(actions.editWorkflowApplication(application));
  // };

  const handleAddSection = async () => {
    const sectionId = await generateId();
    dispatch(actions.initNewSection(sectionId));
  };

  useEffect(() => {
    const loggedInUser = "5c1aaeae-ef0b-4464-9366-9051514cb138";
    const init = async () => {
      const id = await generateId();
      dispatch(actions.init({ id, loggedInUser }));
    };
    init();
  }, [dispatch]);

  return (
    <Box p={"15px"} borderRadius={"15px"} mt={"75px"} bg={"#fff"}>
      <HStack style={{ padding: "0px 0 40px 0" }} justify="space-between">
        <Box w={"60%"} s>
          <Editable
            text={workflow}
            handleTextChange={handleWorkflowChange}
            handleSubmit={handleWorkflowSubmit}
          />
          <HStack
            style={{
              padding: "16px 0 0 0",
              borderTop: "1px solid #efefef",
            }}
            justify="space-between"
          >
            <Box style={{ width: "33%" }}>
              <Editable
                text={description}
                handleSubmit={handleDescriptionSubmit}
                handleTextChange={handleDescriptionChange}
              />
            </Box>
            <Box
              style={{
                width: "33%",
                border: "1px solid #efefef",
                height: "32px",
              }}
            >
              <p>Client</p>
              {/* <Editable
                text={client}
                handleSubmit={handleClientSubmit}
                handleTextChange={handleClientChange}
              /> */}
            </Box>
            <Box
              style={{
                width: "33%",
                border: "1px solid #efefef",
                height: "32px",
              }}
            >
              <p>Application</p>
              {/* <Editable
                text={application}
                handleSubmit={handleApplicationSubmit}
                handleTextChange={handleApplicationChange}
              /> */}
            </Box>
          </HStack>
        </Box>
        <Box w={"40%"} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="sm"
            onClick={handleAddSection}
            colorScheme="login"
            leftIcon={<AddIcon />}
            label="Section"
          />
        </Box>
      </HStack>
      {sectionsFromWorkflow.map((section) => (
        <Section
          key={section.id}
          sectionId={section.id}
          sectionOrder={section.order}
          sectionOrderLast={
            sectionsFromWorkflow.length >= 1
              ? sectionsFromWorkflow[sectionsFromWorkflow.length - 1].order
              : 0
          }
        />
      ))}
      {sectionsFromWorkflow.length ? (
        <HStack
          justifyContent="flex-end"
          style={{ position: "fixed", right: "32px", bottom: "32px" }}
        >
          <Button
            size="sm"
            onClick={handleUpdateData}
            colorScheme="login"
            label="Update"
          />
        </HStack>
      ) : (
        <></>
      )}
    </Box>
  );
}
