import {
  Box,
  Button,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Table } from "@agnext/reactlib";
import React from "react";
import { capitalizeString } from "utils/commonFunctions";
import eyeIcon from "assets/svg/right-icon.svg";
import { ViewReportIcon } from "components/Icons/Icons";
import { CmDetailsHeader } from "../Pages/Collateral Manager Table/variables/variable";
import WarehouseScore from "views/Dashboard/Analytics Dashboard/components/WarehouseScore";
export const PopupTable = ({ name, data }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Count occurrences of warehouseName
  const originNameCount = data.reduce((acc, obj) => {
    const originName = obj.originName;
    acc[originName] = (acc[originName] || 0) + 1;
    return acc;
  }, {});
  // Merge objects and calculate averages
  const mergedObjects = Object.keys(originNameCount).map((originName) => {
    const filteredObjects = data.filter((obj) => obj.originName === originName);
    const inspectionCount = originNameCount[originName];
    const uniqueWarehouseCodes = [
      ...new Set(filteredObjects.map((obj) => obj.externalCode)),
    ];
    // //console.log("uniqueWarehouseCodes", uniqueWarehouseCodes);
    const mergedObject = {
      originName: capitalizeString(originName.toLocaleLowerCase()),
      externalCode: uniqueWarehouseCodes,
      inspectionCount: inspectionCount === 1 ? 1 : inspectionCount,
      bagCount: filteredObjects
        .reduce((sum, obj) => sum + parseInt(Number(obj.bagCount)), 0)
        .toFixed(1),
      quantity: filteredObjects
        .reduce((sum, obj) => sum + Number(obj.quantity), 0)
        .toFixed(1),
      warehouseScore: isNaN(
        filteredObjects.reduce(
          (sum, obj) => sum + parseFloat(Number(obj.warehouseScore)).toFixed(1),
          0
        ) / inspectionCount
      )
        ? "N.A."
        : filteredObjects.reduce(
            (sum, obj) =>
              sum + parseFloat(Number(obj.warehouseScore)).toFixed(1),
            0
          ) / inspectionCount,
    };
    return mergedObject;
  });
  //   //console.log(
  //     mergedObjects.map((obj) => {
  //       return typeof obj.warehouseScore;
  //     })
  //   );

  let modifiedMergedObjects = mergedObjects.map((obj) => {
    return {
      ...obj,
      warehouseScore: <WarehouseScore WHScore={obj.warehouseScore} />,
    };
  });
  //console.log("merged", modifiedMergedObjects);
  return (
    <>
      <HStack>
        <ViewReportIcon onClick={onOpen} style={{ cursor: "pointer" }} />
        <Text>{capitalizeString(name.toLowerCase())}</Text>
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent bg={"gray.50"}>
          <ModalHeader p={"15px"}>Warehouses</ModalHeader>
          <ModalCloseButton />
          <Box p={"15px"} bg={"#fff"} m={"12px"} borderRadius={"15px"}>
            <Table
              overflowY="auto"
              headerList={CmDetailsHeader}
              dataList={modifiedMergedObjects}
              fixedHeader={true}
              containerStyle={{
                width: "100%",
                height: data.length > 6 ? "400px" : "fitContent",
              }}
            />
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};
