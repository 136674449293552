import { customAlphabet } from "nanoid/async";
import { customAlphabet as customAlphabetSync } from "nanoid";
import _ from "lodash";

export async function generateId() {
  const nanoid = customAlphabet("1234567890abcdefghijklmnopqrstuvwxyz", 16);
  const id = await nanoid();
  return id;
}

export function generateIdSync() {
  const nanoid = customAlphabetSync("1234567890abcdefghijklmnopqrstuvwxyz", 16);
  const id = nanoid();
  return id;
}

export const sort = (arr) => {
  return arr.sort((a, b) => a.order - b.order);
};

export const move = (a, b) => {
  const temp = b.order;
  b.order = a.order;
  a.order = temp;
  return { updated1: a, updated2: b };
};

export const fixOrder = (sections) => {
  const newSections = _.cloneDeep(sections);
  let prevIndex = 0;
  let currIndex = 1;
  let prevOrder = null;
  while (currIndex < newSections.length) {
    if (prevOrder) {
      newSections[currIndex].order = prevOrder + 1;
      prevOrder = prevOrder + 1;
    }
    if (newSections[prevIndex].order === newSections[currIndex].order) {
      prevOrder = newSections[prevIndex].order;
      newSections[currIndex].order = prevOrder + 1;
      prevOrder = prevOrder + 1;
    }
    currIndex++;
    prevIndex++;
  }
  return newSections;
};

const flow = [
  "state",
  "sections",
  "blocks",
  "composites",
  "components",
  "elements",
];

const orderManagement = (component, delOrder) => {
  const newComponent = component.map((element) => {
    let newElement = _.cloneDeep(element);
    if (element.order >= delOrder) {
      newElement.order = newElement.order - 1;
    }
    return newElement;
  });
};

const orderManagementAdd = (component) => {
  let temp = 0;
  component = component.map((element) => {
    let newElement = _.cloneDeep(element);
    if (newElement.order === temp) {
      newElement.order = newElement.order + 1;
    }
    temp = newElement.order;
    return newElement;
  });
};

export const deletion = (id, ParentId, state, flowIndex) => {
  //base case
  if (flowIndex === 1) {
    let parent = state.workflow;
    let delOrder = 0;
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id !== id) {
        return true;
      }
      delOrder = comp.order;
      return false;
    });
    orderManagement(newChildComp, delOrder);
    parent.structure[flow[flowIndex]] = newChildComp;
  } else if (ParentId !== null && flowIndex !== 1) {
    let delOrder = 0;
    let allDataOfParent = state[flow[flowIndex - 1]];
    allDataOfParent = allDataOfParent.filter(
      (currComponent) => currComponent.id === ParentId
    );
    let parent = allDataOfParent[0];
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id !== id) {
        return true;
      }
      delOrder = comp.order;
      return false;
    });
    orderManagement(newChildComp, delOrder);
    parent.structure[flow[flowIndex]] = newChildComp;
  }

  let deletedFromState = [];
  let toBeDeletedChildren = [];
  let currComponentInsideState = state[flow[flowIndex]];
  if (flowIndex !== 5) {
    currComponentInsideState = currComponentInsideState.filter((curr) => {
      if (curr.id !== id) {
        return true;
      }
      deletedFromState =
        curr.structure && curr.structure[flow[flowIndex + 1]]
          ? curr.structure[flow[flowIndex + 1]]
          : [];
      return false;
    });
    state[flow[flowIndex]] = currComponentInsideState;
    deletedFromState.forEach((delId) => {
      toBeDeletedChildren.push(delId.id);
    });
    if (toBeDeletedChildren !== []) {
      toBeDeletedChildren.forEach((del) => {
        deletion(del, null, state, flowIndex + 1);
      });
    }
  }
};

export const generateTitle = (genericTitle, order) => {
  return genericTitle + " " + order;
};

/*
// tobecompleted
export const duplicate = (newId, id, ParentId, state, flowIndex) => {
  //base case
  let idNext = generateIdSync();
  let tempComp;
  if (flowIndex === 1) {
    let parent = state.workflow;
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id === id) {
        tempComp = comp;
      }
      return true;
    });
    tempComp = { ...tempComp, id: newId };
    newChildComp = [...newChildComp, tempComp];
    sort(newChildComp);
    orderManagementAdd(newChildComp);
    parent.structure[flow[flowIndex]] = newChildComp;
  } else if (ParentId !== null && flowIndex !== 1) {
    let allDataOfParent = state[flow[flowIndex - 1]];
    allDataOfParent.filter((currComponent) => currComponent.id === ParentId);
    let parent = allDataOfParent[0];
    let newChildComp = parent.structure[flow[flowIndex]];
    newChildComp = newChildComp.filter((comp) => {
      if (comp.id === id) {
        tempComp = comp;
      }
      return true;
    });
    tempComp = { ...tempComp, id: newId };
    newChildComp = [...newChildComp, tempComp];
    sort(newChildComp);
    orderManagementAdd(newChildComp);
    // eval("parent.structure." + flow[flowIndex] + "=" + "newChildComp");
    parent.structure[flow[flowIndex]] = newChildComp;
  }

  let dataFromState = [];
  let toBeAddedChildren = [];
  let currComponentInsideState = state[flow[flowIndex]];
  if (flowIndex !== 5) {
    currComponentInsideState = currComponentInsideState.filter((curr) => {
      if (curr.id === id) {
        tempComp = curr;
        dataFromState =
          curr.structure && curr.structure[flow[flowIndex + 1]]
            ? curr.structure[flow[flowIndex + 1]]
            : [];
      }
      return true;
    });
    tempComp = { ...tempComp, id: newId };
    currComponentInsideState = [...currComponentInsideState, tempComp];
    state[flow[flowIndex]] = currComponentInsideState;
    dataFromState.forEach((delId) => {
      toBeAddedChildren.push(delId.id);
    });
    if (toBeAddedChildren !== []) {
      toBeAddedChildren.forEach((del) => {
        duplicate(idNext, del, null, state, flowIndex + 1);
      });
    }
  }
};

*/

export const duplicate = (newId, id, ParentId, state, flowIndex) => {
  let tempComp;

  const deepCopy = (obj) => _.cloneDeep(obj);

  if (flowIndex === 1) {
    let parent = state.workflow;
    let newChildComp = deepCopy(parent.structure[flow[flowIndex]]);

    newChildComp.forEach((comp) => {
      if (comp.id === id) {
        tempComp = deepCopy(comp);
        tempComp.id = newId;
      }
    });

    newChildComp.push(tempComp);
    sort(newChildComp);
    orderManagementAdd(newChildComp);
    parent.structure[flow[flowIndex]] = newChildComp;
    parent = { ...parent, structure: { [flow[flowIndex]]: newChildComp } };
  } else if (ParentId !== null && flowIndex !== 1) {
    let allDataOfParent = state[flow[flowIndex - 1]];
    let parent = {};
    allDataOfParent.forEach((currComp) => {
      if (currComp.id === ParentId) {
        parent = deepCopy(currComp);
      }
    });
    let newChildComp =
      parent.structure && deepCopy(parent.structure[flow[flowIndex]]);

    newChildComp.forEach((comp) => {
      if (comp.id === id) {
        tempComp = deepCopy(comp);
        tempComp.id = newId;
      }
    });

    newChildComp.push(tempComp);
    sort(newChildComp);
    orderManagementAdd(newChildComp);
    parent = { ...parent, structure: { [flow[flowIndex]]: newChildComp } };
    let newAllDataParent = deepCopy(allDataOfParent);
    let newAllDataParent2 = newAllDataParent.map((currParent) => {
      if (currParent.id === parent.id) {
        return parent;
      }
      return currParent;
    });
    state[flow[flowIndex - 1]] = deepCopy(newAllDataParent2);
  }

  if (flowIndex === 4) {
    let currComponentInsideState = deepCopy(state[flow[flowIndex]]);
    [...state[flow[flowIndex]]].forEach((curr) => {
      if (curr.id === id) {
        tempComp = deepCopy(curr);
        tempComp.id = newId;
        const elements =
          tempComp.structure && tempComp.structure.elements
            ? tempComp.structure.elements
            : [];
        const newElements = [...elements].map((element) => {
          return { ...element, id: generateIdSync() };
        });
        tempComp = {
          ...tempComp,
          structure: { ...tempComp.structure, elements: newElements },
        };
      }
    });

    currComponentInsideState.push(tempComp);
    //console.log({ currComponentInsideState });
    state[flow[flowIndex]] = currComponentInsideState;
  } else if (flowIndex !== 5) {
    let dataFromState = [];
    let toBeAddedChildren = [];
    let currComponentInsideState = deepCopy(state[flow[flowIndex]]);
    [...state[flow[flowIndex]]].forEach((curr) => {
      if (curr.id === id) {
        if (
          curr.structure &&
          curr.structure[[flow[flowIndex + 1]]] &&
          curr.structure.links
        ) {
          tempComp = {
            ...curr,
            structure: {
              [flow[flowIndex + 1]]: deepCopy(
                curr.structure[flow[flowIndex + 1]]
              ),
              links: deepCopy(curr.structure.links),
            },
          };
        } else if (curr.structure && curr.structure[[flow[flowIndex + 1]]]) {
          tempComp = {
            ...curr,
            structure: {
              [flow[flowIndex + 1]]: deepCopy(
                curr.structure[flow[flowIndex + 1]]
              ).map((currComp) => {
                const genId = generateIdSync();
                toBeAddedChildren.push({ currId: currComp.id, idNext: genId });
                return { ...currComp, id: genId };
              }),
            },
          };
        } else {
          tempComp = deepCopy(curr);
        }
        tempComp.id = newId;
        dataFromState = tempComp.structure
          ? tempComp.structure[flow[flowIndex]]
          : [];
        //console.log({ dataFromState, flow: [flow[flowIndex]] });
      }
    });

    currComponentInsideState.push(tempComp);
    state[flow[flowIndex]] = currComponentInsideState;

    (dataFromState ? dataFromState : []).forEach((currComp) => {
      toBeAddedChildren.push(currComp.id);
    });

    if (toBeAddedChildren.length !== 0) {
      toBeAddedChildren.forEach((ids) => {
        duplicate(ids.idNext, ids.currId, null, state, flowIndex + 1);
      });
    }
  }
};
