import React, { createContext, useContext, useState } from "react";

// Create a context for managing filter state
export const ReviewAddFilterContext = createContext();

export const ReviewAddFilterProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState({
    cmName: [],
    auditorId: [],
    originLocation: [],
  });

  const [selectedItems, setSelectedItems] = useState({
    cmName: [],
    auditorId: [],
    originLocation: [],
  });
  const handleQuery = () => {
    setQuery(selectedItems);
  };
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (item, property) => {
    const { [property]: propertyItems } = selectedItems;
    if (propertyItems.includes(item[property])) {
      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        [property]: propertyItems.filter((value) => value !== item[property]),
      }));
    } else {
      setSelectedItems((prevSelectedItems) => ({
        ...prevSelectedItems,
        [property]: [...propertyItems, item[property]],
      }));
    }
  };

  const handleSelectChange = (item, property) => {
    setSelectedItems((prevSelectedItems) => {
      const updatedItems = prevSelectedItems[property].includes(item)
        ? prevSelectedItems[property].filter(
            (selectedItem) => selectedItem !== item
          )
        : [...prevSelectedItems[property], item];

      return {
        ...prevSelectedItems,
        [property]: updatedItems,
      };
    });
  };

  const handleRemoveItem = (itemToRemove, property) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [property]: prevSelectedItems[property].filter(
        (item) => item !== itemToRemove
      ),
    }));
  };

  const handleClear = () => {
    setSelectedItems({
      cmName: [],
      auditorId: [],
      originLocation: [],
    });
    setQuery({
      cmName: [],
      auditorId: [],
      originLocation: [],
    });
  };

  return (
    <ReviewAddFilterContext.Provider
      value={{
        selectedItems,
        toggleCollapse,
        handleItemClick,
        handleSelectChange,
        handleRemoveItem,
        handleClear,
        isOpen,
        setIsOpen,
        handleQuery,
        query,
      }}
    >
      {children}
    </ReviewAddFilterContext.Provider>
  );
};
export function useReviewAddFilter() {
  return useContext(ReviewAddFilterContext);
}
