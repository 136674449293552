import React, { useCallback, useEffect, useState } from "react";
import {
  HStack,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  VStack,
} from "@chakra-ui/react";
import { Select, Button } from "@agnext/reactlib";
import { useSelector, useDispatch } from "react-redux";
import * as selectors from "../../pages/Create/selectors";
import { actions } from "../../pages/Create/slice";
import { ExternalLinkIcon, CloseIcon } from "@chakra-ui/icons";

export default function LinkTo({
  selectedOption,
  setSelectedOption,
  title,
  setTitle,
  handleSubmit,
  links,
  parentType,
  parentId,
}) {
  const options = [
    { label: "Section", value: "section" },
    {
      label: "Block",
      value: "block",
    },
    {
      label: "Composite",
      value: "composite",
    },
    { label: "Component", value: "component" },
  ];

  const section = useSelector(selectors.selectSectionList);
  const block = useSelector(selectors.selectBlockList);
  const composite = useSelector(selectors.selectCompositeList);
  const component = useSelector(selectors.selectComponentList);

  const titleOptionsList = useCallback(() => {
    return {
      section: section || [],
      block: block || [],
      composite: composite || [],
      component: component || [],
    };
  }, [section, block, composite, component]);

  const [titleOptions, setTitleOptions] = useState([]);

  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleOptionChange = (e) => {
    const newOption = { label: "", value: e.target.value };
    setSelectedOption(newOption);
  };

  const handleTitleChange = (e) => {
    let label = "";
    titleOptions.forEach((option) => {
      if (option.value === e.target.value) {
        label = option.label;
      }
    });
    const newOption = { label, value: e.target.value };
    setTitle(newOption);
  };

  const handleDeleteLink = (link) => {
    dispatch(actions.deleteLink({ parentType, parentId, link }));
  };

  useEffect(() => {
    let newTitleOptions = [];
    if (selectedOption.value) {
      newTitleOptions = titleOptionsList()[selectedOption.value].filter(option => option.value !== parentId);
    }
    setTitleOptions(newTitleOptions);
  }, [selectedOption, titleOptionsList, parentId]);

  useEffect(() => {
    setError("");
  }, [selectedOption, title]);

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <Button variant="ghost" colorScheme="login">
          <ExternalLinkIcon color="login.500" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        style={{
          top: "40px",
          boxShadow: "0px 25px 50px -12px rgba(0, 0, 0, 0.25)",
          width: "380px",
        }}
      >
        <PopoverHeader textAlign="center">Link To</PopoverHeader>
        <PopoverBody>
          <VStack alignItems="flex-start" style={{ margin: "12px auto" }}>
            {links.map((link, i) => (
              <HStack gap="2" key={i}>
                <HStack
                  style={{
                    padding: "2px 8px",
                    background: "rgba(79, 209, 191, 0.1)",
                  }}
                >
                  <span style={{ textTransform: "capitalize" }}>
                    {link.type}:
                  </span>
                  <span>
                    {link.title && link.title.length > 18
                      ? link.title.slice(0, 18) + "..."
                      : link.title}
                  </span>
                </HStack>
                <HStack
                  style={{
                    background: "rgba(79, 209, 191, 0.1)",
                    height: "22px",
                    width: "22px",
                    cursor: "pointer",
                  }}
                  justify="center"
                  onClick={() => {
                    handleDeleteLink(link);
                  }}
                >
                  <CloseIcon w="2.5" h="2.5" />
                </HStack>
              </HStack>
            ))}
          </VStack>
          <VStack gap={4}>
            <Select
              value={selectedOption.value}
              onChange={handleOptionChange}
              options={options}
              placeholder="Select what to link"
              focusBorderColor="login.500"
            />
            <Select
              value={title.value}
              onChange={handleTitleChange}
              options={titleOptions}
              placeholder={`Select title of ${selectedOption.value}`}
              focusBorderColor="login.500"
            />
            <Text color="red">{error}</Text>
            <HStack w="100%" justify="flex-end">
              <Button
                size="sm"
                colorScheme="login"
                label="Add"
                onClick={() => {
                  if (!title || !selectedOption.value) {
                    setError("Provide proper values.");
                    return;
                  }
                  handleSubmit();
                }}
              />
            </HStack>
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
