import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actions, sliceKey, reducer } from "../../Create/slice";
import * as selectors from "../selectors";
import { useInjectReducer } from "redux-injectors";
import { Box, ButtonGroup, HStack } from "@chakra-ui/react";
import Moveup from "../../../Components/moveup";
import MoveDown from "../../../Components/moveDown";
import Delete from "../../../Components/Delete";
import Duplicate from "../../../Components/duplicate";
import { Button } from "@agnext/reactlib";
import { AddIcon } from "@chakra-ui/icons";
import Editable from "../../../Components/Editable";
import Element from "../Element";
import { generateId } from "../../../../../utils/index";
import LinkTo from "../../../Components/LinkTo";

export default function Component({
  compositeId,
  componentId,
  componentOrder,
  componentOrderLast,
  sectionId,
}) {
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const [title, setTitle] = useState({
    label: "",
    value: "",
  });
  useInjectReducer({ key: sliceKey, reducer: reducer });

  const components = useSelector(selectors.selectComponentsForElements);

  const [elements, setElements] = useState([]);

  const [componentName, setComponentName] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (components.length) {
      components.forEach((component) => {
        if (component && component.id === componentId) {
          const newElements =
            component.structure && component.structure.elements;
          setElements(newElements);
          setComponentName(component.title);
        }
      });
    }
  }, [componentId, components]);

  // useEffect(() => {
  //   dispatch(actions.fetchComponentData({ id: componentId }));
  // }, [dispatch, componentId]);

  const getLinks = useCallback(() => {
    let links;
    if (components.length) {
      components.forEach((component) => {
        if (component.id === componentId) {
          links = component.structure &&
            component.structure.links && [...component.structure.links];
        }
      });
    }
    return links || [];
  }, [componentId, components]);

  const handleComponentNameChange = (value) => {
    setComponentName(value);
  };

  const handleSubmitLinkTo = () => {
    dispatch(
      actions.addLinkToComponent({
        componentId,
        linkTo: {
          type: selectedOption.value,
          id: title.value,
          title: title.label,
        },
      })
    );
    const newOption = { label: "", value: "" };
    setSelectedOption(newOption);
    setTitle(newOption);
  };

  const handleComponentDelete = () => {
    const payload = {
      componentId,
      compositeId,
    };
    dispatch(actions.deletionComponent(payload));
  };

  const handleComponentDuplicate = async () => {
    const newId = await generateId();
    const payload = {
      newId,
      componentId,
      compositeId,
    };
    dispatch(actions.duplicateComponent(payload));
  };

  const handleComponentNameSubmit = (componentName) => {
    const payload = {
      componentId,
      componentName,
      compositeId,
    };
    dispatch(actions.editComponentName(payload));
  };

  const moveUpHandlerComponent = () => {
    const payload = {
      componentId,
      compositeId,
    };
    dispatch(actions.moveUpComponent(payload));
  };

  const moveDownHandlerComponent = () => {
    const payload = {
      componentId,
      compositeId,
    };
    dispatch(actions.moveDownComponent(payload));
  };

  const handleAddElement = async () => {
    const elementId = await generateId();
    dispatch(actions.initNewElement({ elementId, componentId }));
  };
  return (
    <>
      <Box
        w={"100%"}
        style={{
          padding: "16px",
          border: "1px solid #EFEFEF",
          margin: "16px 0 16px 0",
          borderLeft: "2px solid rgba(79, 209, 191, 0.5)",
          borderRadius: "0 8px 8px 0",
        }}
      >
        <HStack
          justify={"space-between"}
          style={{
            background: "rgba(84,3, 117, 0.03)",
            padding: "7px 12px",
            borderRadius: "0 8px 8px 0",
          }}
        >
          <Box w="40%">
            <Editable
              text={componentName}
              handleTextChange={handleComponentNameChange}
              handleSubmit={handleComponentNameSubmit}
            />
          </Box>
          <ButtonGroup
            justifyContent="center"
            size="md"
            style={{ position: "relative" }}
          >
            <Moveup
              moveUpHandler={moveUpHandlerComponent}
              isDisabled={componentOrder === 1 ? true : false}
            />
            <MoveDown
              moveDownHandler={moveDownHandlerComponent}
              isDisabled={componentOrder === componentOrderLast ? true : false}
            />
            <Delete deleteHandler={handleComponentDelete} />
            <Duplicate duplicateHandler={handleComponentDuplicate} />
            <LinkTo
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
              title={title}
              setTitle={setTitle}
              handleSubmit={handleSubmitLinkTo}
              links={getLinks()}
              parentType="components"
              parentId={componentId}
            />
          </ButtonGroup>
          <Box>
            <Button
              size="sm"
              onClick={handleAddElement}
              colorScheme="login"
              leftIcon={<AddIcon />}
              label="Element"
            />
          </Box>
        </HStack>
        {elements &&
          elements.map((element) => (
            <Element
              key={element.id}
              elementId={element.id}
              componentId={componentId}
              elementOrder={element.order}
              elements={elements}
              elementOrderLast={
                elements.length >= 1 ? elements[elements.length - 1].order : 0
              }
              currElement={element}
              sectionId={sectionId}
            />
          ))}
      </Box>
    </>
  );
}
