import { useRef, useState } from "react";
import { List, useOutsideClick } from "@chakra-ui/react";
import {
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Collapse,
  Box,
  Text,
} from "@chakra-ui/react";
import { DropdownArrowIcon } from "components/Icons/Icons";
import WarehouseFilterDropdown from "views/Dashboard/Inspection Table/components/WarehouseFilterDropdown.jsx";
import "views/Dashboard/Inspection Table/index.css";
import { CloseIcon } from "@chakra-ui/icons";
import { useWarehouseFilter } from "views/Dashboard/context/WarehouseFilterContext";
const WarehouseFilter = ({ warehouseOptions }) => {
  const {
    handleWareHouseChange,
    handleSelect,
    searchQuery,
    handleQuery,
    inputValue,
    handleInputVal,
  } = useWarehouseFilter();
  // //console.log(warehouseOptions);

  let filteredWarehouseOptions = warehouseOptions.filter((option) => {
    return (
      option?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option?.externalCode?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option?.city?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      option?.state?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  // //console.log(filteredWarehouseOptions, warehouseOptions);

  let sortedArray = filteredWarehouseOptions.sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  // //console.log(sortedArray);
  //  let modifiedData = sortedArray.map((element) => {
  //     return { ...element, [name] : element[name].toLowerCase(),
  //         [location] : element[location].toLowerCase(),
  //         // [state] : element[state]?.toLowerCase() };
  //   }
  //   }
  //   );
  // //console.log(modifiedData)
  const ref = useRef();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };
  useOutsideClick({
    ref: ref,
    handler: () => {
      if (isDropdownOpen) {
        setDropdownOpen(false);
      }
    },
  });

  return (
    <>
      <Box
        ref={ref}
        style={{ position: "relative" }}
        // minW="250px"
        onClick={() => {
          if (isDropdownOpen !== true) {
            setDropdownOpen(true);
          }
        }}
      >
        <InputGroup borderRadius="15px" height="40px">
          <Input
            bg="#F7FAFC"
            fontSize="11px"
            value={inputValue}
            placeholder="Warehouse Name, Id, Location..."
            onChange={(e) => {
              handleInputVal(e.target.value);
              handleQuery(e.target.value);
            }}
          />
          <InputRightElement>
            {searchQuery.trim() === "" ? (
              <IconButton
                bg="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<DropdownArrowIcon />}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDropdown();
                }}
              ></IconButton>
            ) : (
              <IconButton
                bg="inherit"
                _hover="none"
                _active={{
                  bg: "inherit",
                  transform: "none",
                  borderColor: "transparent",
                }}
                _focus={{
                  boxShadow: "none",
                }}
                icon={<CloseIcon color="gray.400" height="10px" />}
                onClick={() => {
                  toggleDropdown();
                  handleSelect("");
                  handleWareHouseChange(null);
                }}
              ></IconButton>
            )}
          </InputRightElement>
        </InputGroup>

        <Collapse
          in={isDropdownOpen}
          className={
            isDropdownOpen ? "warehouse-filter-dropdown-container" : "hide"
          }
        >
          <List
            className="scrollable-dropdown"
            borderTopRadius="8px"
            border="1px solid #E2E8F0"
          >
            {filteredWarehouseOptions.length === 0 ? (
              <Box className="name-location-container">
                <Text className="warehouse-name-text">
                  No matching warehouses
                </Text>
              </Box>
            ) : (
              <Box>
                <Box
                  className="name-location-container"
                  onClick={() => {
                    handleWareHouseChange(null);
                    handleSelect("");
                    toggleDropdown();
                  }}
                  borderBottom="1px solid #E2E8F0"
                >
                  <Text className="warehouse-name-text">All</Text>
                </Box>
                {sortedArray.map((option) => (
                  <Box key={option.id}>
                    <WarehouseFilterDropdown
                      id={option.id}
                      warehouse={option}
                      handleSelect={handleSelect}
                      toggleDropdown={toggleDropdown}
                      handleWareHouseChange={handleWareHouseChange}
                    />
                  </Box>
                ))}
              </Box>
            )}
          </List>
        </Collapse>
      </Box>
    </>
  );
};

export default WarehouseFilter;
