import React, { useState, useEffect } from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from "react-simple-maps";
import geoJSONData from "../../assets/geojson/india.json";
import { Box, Button, Text, Icon } from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";
import { scaleLinear } from "d3-scale";
import "./MapChart.css";

const MapChart = ({ regionData }) => {
  const initialCenter = [-10, -10];
  const [zoom, setZoom] = useState({
    coordinates: [80.9629, 20.5937],
    zoom: 3.5,
  });
  const [stateContent, setStateContent] = useState("");
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({});

  // Step 2: Find the minimum and maximum quantity values
  const minQuantity = Math.min(...regionData.map((item) => item.quantity));
  const maxQuantity = Math.max(...regionData.map((item) => item.quantity));

  // Step 3: Create a color scale based on the minimum and maximum values
  const minColor = "#4EC3B9";
  const maxColor = "#1B776F";

  const handleZoomIn = () => {
    if (zoom.zoom >= 20) return;
    setZoom((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
  };

  const handleZoomOut = () => {
    if (zoom.zoom <= 4) return;
    setZoom((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
  };

  const showTooltip = (geo, event) => {
    event.preventDefault();
    const { st_nm } = geo.properties;
    const { clientX, clientY } = event;
    const regionDataItem = regionData.find((item) => item.state === st_nm);
    if (regionDataItem) {
      const updatedRegionDataItem = { ...regionDataItem, state: st_nm };
      setTooltipContent(updatedRegionDataItem);
      setTooltipPosition({
        left: clientX - 280,
        top: clientY - 100,
        position: "absolute",
      });
    }
  };

  const hideTooltip = () => {
    setTooltipContent("");
  };

  const getColorForRegion = (region) => {
    const regionName = region.properties.st_nm;

    const regionDataItem = regionData.find((item) => item.state === regionName);
    if (regionDataItem) {
      const quantity = regionDataItem.quantity;
      const colorScale = scaleLinear()
        .domain([minQuantity, maxQuantity])
        .range([minColor, maxColor]);
      return colorScale(quantity);
    }
    return "#F8F8F8";
  };

  const countDecimalAndNumberLength = (maxInput) => {
    // Use a regular expression to match decimal and number characters
    const inputString = String(maxInput);
    const regex = /[0-9.]/g;
    const matches = inputString.match(regex);

    // Calculate the total length of decimal and number characters
    const totalLength = matches ? matches.length : 0;
    return totalLength == 0 ? "-55px" : -12 * totalLength + "px";
  };
  const handleMoveEnd = (position) => {
    setZoom(position);
  };

  return (
    <Box w="100%" data-tip="" position="relative">
      <ComposableMap width={800} height={400} projection="geoMercator">
        <ZoomableGroup
          center={zoom.coordinates}
          zoom={zoom.zoom}
          onMoveEnd={handleMoveEnd}
          minZoom={4}
          maxZoom={40}
        >
          <Geographies geography={geoJSONData}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={(event) => showTooltip(geo, event)}
                  onMouseLeave={hideTooltip}
                  onMouseDown={(e) => e.preventDefault()}
                  style={{
                    default: {
                      fill: getColorForRegion(geo),
                      stroke: "#D0D5DD",
                      outline: "none",
                      strokeWidth: 0.2,
                    },
                    hover: {
                      fill: getColorForRegion(geo),
                      stroke: "#D0D5DD",
                      outline: "none",
                      strokeWidth: 0.2,
                    },
                    pressed: {
                      fill: getColorForRegion(geo),
                      stroke: "#D0D5DD",
                      outline: "none",
                      strokeWidth: 0.2,
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      {tooltipContent && (
        <Box
          className="tooltip"
          left={tooltipPosition.left}
          top={tooltipPosition.top}
          p="2"
        >
          <Text w={"100%"}>
            <Icon as={FaMapMarkerAlt} boxSize={4} color="#D0D5DD" />
            <span className="tooltipHeading">{tooltipContent.state}</span>
          </Text>
          {/* <Text w={"100%"} className="rowMap">
            <span className="textLeftContent">Portfolio Size</span>{" "}
            <span className="textRightContent">
              Rs{tooltipContent.portfolioSize}
            </span>
          </Text> */}
          <Text w={"100%"} className="rowMap">
            <span className="textLeftContent">Quantity Inspected</span>{" "}
            <span className="textRightContent">
              {tooltipContent.quantity} MT
            </span>
          </Text>
          <Text w={"100%"} className="rowMap" borderBottomColor={"#fff"}>
            <span className="textLeftContent">No. of Bags</span>{" "}
            <span className="textRightContent">{tooltipContent.noOfBags}</span>
          </Text>
          {/* Add more tooltip content as needed  & give borderBottomColor={"#fff"} to the last tooltip item*/}
        </Box>
      )}
      <Box className="color-gradient">
        <Text
          bottom="-5px"
          left="-40px"
          position="absolute"
          fontSize="sm"
          color="#7E8DA2"
        >
          {minQuantity} MT
        </Text>
        <div className="color-gradient-bar"></div>
        <Text
          position="absolute"
          right={countDecimalAndNumberLength(maxQuantity)}
          bottom="-5px"
          fontSize="sm"
          color="#7E8DA2"
        >
          {maxQuantity} MT
        </Text>
      </Box>
      <Box>
        <Button
          className="buttonStyle"
          size="sm"
          onClick={handleZoomIn}
          bottom="70px"
          position="absolute"
        >
          +
        </Button>
        <Button
          className="buttonStyle"
          size="sm"
          onClick={handleZoomOut}
          position="absolute"
        >
          -
        </Button>
      </Box>
    </Box>
  );
};

export default MapChart;
