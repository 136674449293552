/*eslint-disable*/
// chakra imports
import { Box, useColorModeValue, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import SidebarContent from "./SidebarContent";

// FUNCTIONS

function Sidebar(props) {
  // to check for active links and opened collapses
  const [mediaQuery] = useMediaQuery("(min-width: 350px)");
  const mainPanel = React.useRef();
  let variantChange = "0.2s linear";

  const { routes, sidebarVariant, brandText } = props;

  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "none";
  let sidebarRadius = "0px";
  let sidebarMargins = "16px 0px 16px 11px";
  if (sidebarVariant === "opaque") {
    sidebarRadius = "16px";
    sidebarMargins = "16px 0px 16px 11px";
  }

  // SIDEBAR
  return (
    <Box ref={mainPanel}>
      <Box display={{ sm: "none", xl: "block" }} position="fixed">
        <Box
          bg={sidebarBg}
          transition={variantChange}
          w="260px"
          maxW="260px"
          // ms={{
          //   sm: "16px",
          // }}
          // my={{
          //   sm: "20px",
          // }}
          h="calc(100vh - 32px)"
          ps="20px"
          pe="20px"
          // m={sidebarMargins}
          borderRadius={sidebarRadius}
        >
          {mediaQuery && <SidebarContent brandText={brandText} />}
        </Box>
      </Box>
    </Box>
  );
}

export default Sidebar;
