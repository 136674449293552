import React, { useState } from "react";

import { Image } from "@chakra-ui/react";

import { useHistory, useLocation } from "react-router-dom";

import Banner from "components/Layout/auth/Banner/Banner.js";
import perfeqtLogo from "assets/perfeqt Logoo-02.png";
import { LoginForm } from "@agnext/reactlib";
import "./index.css";
import { setNewPassword } from "services/apis";

function SetNewPassword() {
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const oid = searchParams.get("oid");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isValidPassLength, setIsValidPassLength] = useState(false);
  const [isSpacialChar, setIsSpacialChar] = useState(false);

  const passwordHandler = (e) => {
    const specialCharRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\/\\-]/;

    setPassword(e.target.value);
    setLoading(false);
    password.length >= 8
      ? setIsValidPassLength(true)
      : setIsValidPassLength(false);
    specialCharRegex.test(password)
      ? setIsSpacialChar(true)
      : setIsSpacialChar(false);
  };

  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
    setLoading(false);
  };

  const resetHandler = async () => {
    if (!isValidPassLength || !isSpacialChar) return;
    try {
      setLoading(true);
      if (password === confirmPassword) {
        await setNewPassword(oid, password);
      } else {
        setError("Passwords do not match");
        return;
      }
      setConfirmPassword("");
      setPassword("");
      history.push("/auth/password-reset-successful");
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data.reason);
      } else {
        setError("Internal server error");
      }
      setLoading(false);
    }
  };

  const backToSignInHandler = () => {
    history.push("/auth/signin");
  };

  return (
    <LoginForm
      type="passwordReset"
      leftComponent={<Banner />}
      logo={<Image src={perfeqtLogo} alt="perfeqt-logo" />}
      password={password}
      confirmPassword={confirmPassword}
      onChangePassword={passwordHandler}
      onChangeConfirmPassword={confirmPasswordHandler}
      passwordErrorMessage=""
      confirmPasswordErrorMessage=""
      handleSubmit={resetHandler}
      handleGoBack={backToSignInHandler}
      hasMinLength={isValidPassLength}
      hasSpecialChar={isSpacialChar}
      error={error}
    />
  );
}

export default SetNewPassword;
