import React, { useEffect, useState, useRef } from "react";
import { Select, Button } from "@agnext/reactlib";
import {
  Stack,
  Box,
  useMediaQuery,
  Modal,
  ModalOverlay,
  useDisclosure,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  ModalFooter,
} from "@chakra-ui/react";
import EnnoventureComp from "../ennoventureComp";

export default function Dropdown({ data, formData, handleFormDataChange }) {
  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");
  const initRef = useRef();
  const [optionValue, setOptionValue] = useState(
    formData[data.element.id]?.value || ""
  );
  useEffect(() => {
    if (optionValue) {
      handleFormDataChange(optionValue, "", data.element.id, "NA");
    }
  }, [optionValue, data.element.id]);

  useEffect(() => {
    setOptionValue(formData[data.element.id]?.value);
  }, [data.element.id, formData]);

  // hardcoded id for godfrey counterfeit scan (shoulb be replaced with hybrid element)
  if (data.element.id === "yrb61phymyxdx0zt" || data.element.id === "asowykftujzsgzz6") {
    return (
      <Stack direction={isMobileScreen ? "column-reverse" : "row"}>
        <Box w={isMobileScreen ? "100%" : "70%"}>
          <Select
            value={optionValue}
            size="md"
            name="1"
            focusBorderColor="login.500"
            options={data.element.properties.options}
            onChange={(e) => setOptionValue(e.target.value)}
          />
        </Box>
        <ModalBasic />
      </Stack>
    );
  }

  return (
    <Select
      value={optionValue}
      size="md"
      name="1"
      focusBorderColor="login.500"
      options={data.element.properties.options}
      onChange={(e) => setOptionValue(e.target.value)}
    />
  );
}

export function ModalBasic() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button label="Start Detection" colorScheme="gray" onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} size={"full"} >
        <ModalOverlay />
        <ModalContent h="80%">
          {/* <ModalCloseButton /> */}
          <ModalBody>
            <EnnoventureComp />
          </ModalBody>

          <ModalFooter>
            <Button label="Close" colorScheme="gray" onClick={onClose} />
            {/* <Button variant='ghost'>Secondary Action</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
