import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import {
  Box,
  Button,
  Flex,
  Image,
  Select,
  Text,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import { format } from "date-fns";
import {
  transformArrayOfArrayOfObjects,
  transformArray,
  replaceNullWithNA,
} from "utils/commonFunctions";

import { sliceKey, reducer, actions } from "./slice";
import { dashboardSaga } from "./saga";
import * as selectors from "./selectors";

import "./index.css";

import InspectionDataTable from "./components/InspectionDataTable";
import SearchAndFilterOptions from "./components/SearchAndFilterOptions";

import { useWarehouseFilter } from "../context/WarehouseFilterContext";
import { useAddFilter } from "../context/AddFilterContext";
import { calculateTotalPages } from "utils/commonFunctions";
import Download from "assets/svg/download-icon-white.svg";
import { userStorage } from "utils/helper";

export default function Dashboard() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: dashboardSaga });
  const dispatch = useDispatch();

  //useContext
  const { selectedWarehouse } = useWarehouseFilter();
  const { query } = useAddFilter();

  //useState
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    fromDate: null,
    toDate: null,
  });
  const [page, setPage] = useState(0);
  const { fromDate, toDate } = filters;

  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const showErrorToast = () => {
    toast({
      title: "Report is Generating",
      description: "Please wait few seconds",
      status: "loading",
      duration: 2000,
      isClosable: true,
    });
  };

  //Use Selectors
  let data = useSelector(selectors.selectData) || [];
  let warehouses = useSelector(selectors.selectWarehouseData) || [];
  let inspectionIds = useSelector(selectors.selectInspectionIdArr) || [];
  let tableData = useSelector(selectors.selectTableData) || [];
  let totalInspectionCount = useSelector(selectors.selectInspectionCounts) || 1;
  let dropDownList = useSelector(selectors.selectAddFilterDropdown) || [];
  let reportMonthArray =
    useSelector(selectors.selectGeneratedReportMonths) || [];
  // //console.log("selectGeneratedReportMonths", reportMonthArray);
  // let dropDownList = useSelector(selectors.selectAddFilterDropdown) || [];

  let yearMonthArray = reportMonthArray.reduce((acc, currentItem) => {
    const { year, month } = currentItem;
    if (!acc[year]) acc[year] = [];
    const date = new Date(year, month, 0);
    const formatDate = format(date, "MMMM");
    acc[year].push(formatDate);
    return acc;
  }, {});

  //Variables & functions
  const modifiedData = data.map(replaceNullWithNA);
  const numPages = calculateTotalPages(totalInspectionCount, 11);

  const userRoles = userStorage.get()?.roles || [];
  const isClient = userRoles.includes("client");

  const excludedValues = [
    "CM AGENCY",
    "Warehouse Code",
    "Audit Date",
    "Auditor Name",
    "Inspection Report",
    "submit",
  ];

  const isExcluded = (item) =>
    excludedValues.includes(item.display) ||
    item?.display?.toLowerCase().includes("image") ||
    item?.display?.toLowerCase().includes("photo") ||
    item?.display?.toLowerCase().includes("submit");

  let Theads = transformArray(tableData?.flat()).filter((item) => {
    if (item?.display === "Name of the Bank") {
      return false;
    } else {
      return !isExcluded(item);
    }
  });

  let data_transformed = transformArrayOfArrayOfObjects(
    tableData,
    modifiedData
  );

  let headingMap = [
    { display: "Audit Date", value: "auditDate", visible: true },
    { display: "Client Name", value: "orgName", visible: true },
    { display: "Warehouse Name", value: "originName", visible: true },
    { display: "Warehouse Code", value: "externalCode", visible: true },
    { display: "CM Name", value: "cmName", visible: true },
    { display: "Location", value: "originLocation", visible: true },
    { display: "Auditor Name", value: "auditorName", visible: true },
  ];

  headingMap = headingMap.filter((item) => {
    if (isClient && item?.display === "Client Name") {
      return false;
    }
    return true;
  });
  let options = [
    ...headingMap,
    ...Theads,
    { display: "Action", value: "action", visible: true },
  ];
  const [checkedOptions, setCheckedOptions] = useState(
    Theads.length > 0 ? options : headingMap
  );
  const handleCheckedOptions = (optionValue, isChecked) => {
    setCheckedOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.display === optionValue
          ? { ...option, visible: isChecked }
          : option
      )
    );
  };

  const handleReset = () => {
    setCheckedOptions(headingMap);
  };

  const handleDragUpdate = (updatedState) => {
    setCheckedOptions(updatedState);
  };

  const handleNextPage = () => {
    if (page === numPages - 1) return;
    handleIsLoadingPagination(true)
    setPage(page + 1);
  };
  const [isLoadingPagination, setIsLoadingPagination] = useState(true);
  const handleIsLoadingPagination=(status)=>{
    setIsLoadingPagination(status)
  }

  //Page change handlers
  const handlePreviousPage = () => {
    if (page === 0) return;
    handleIsLoadingPagination(true)
    setPage(page - 1);
  };

  const handlePageSelect = (pageNumber) => {
    if (page === pageNumber) return
    handleIsLoadingPagination(true)
    setPage(pageNumber);
  };
  
  useEffect(() => {
    if (Theads.length > 0 && checkedOptions.length === headingMap.length) {
      setCheckedOptions(options);
    }
  }, [Theads]);

  useEffect(() => {
    const requiredWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouse
    );
    const warehouseId = selectedWarehouse ? requiredWarehouse.id : null;
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
      status: "reviewed",
    };
    dispatch(actions.fetchAllData({ payload: inspectionIds,onSuccess : handleIsLoadingPagination }));
    dispatch(actions.fetchAddFilterDropdown({ payload: forQueryParams }));
  }, [inspectionIds]);

  useEffect(() => {
    const requiredWarehouse = warehouses.find(
      (warehouse) => warehouse.id === selectedWarehouse
    );
    const warehouseId = selectedWarehouse ? requiredWarehouse.id : null;
    // Prepare query paramenters to get filtered data from API
    const forQueryParams = {
      warehouseId: warehouseId,
      ...(fromDate &&
        toDate && {
          start_dt: format(fromDate, "yyyy-MM-dd"),
          end_dt: format(toDate, "yyyy-MM-dd"),
        }),
      query,
      page,
      status: "reviewed",
    };
    dispatch(
      actions.fetchData({
        payload: forQueryParams,
      })
    );
    dispatch(
      actions.fetchInspectionsCountData({
        payload: forQueryParams,
      })
    );
    dispatch(actions.fetchWarehouses({ payload: forQueryParams }));
    dispatch(actions.fetchGeneratedReportMonths());
  }, [selectedWarehouse, fromDate, toDate, query, page]);

  //State Handler
  const handleSearchQuery = (value) => {
    setSearchQuery(value);
  };
  const handleFromDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      fromDate: date,
    }));
  };
  const handleToDateChange = (date) => {
    setFilters((prevState) => ({
      ...prevState,
      toDate: date,
    }));
  };

  const initiateFetchData = () => {
    setTimeout(() => {
      const requiredWarehouse = warehouses.find(
        (warehouse) => warehouse.id === selectedWarehouse
      );
      const warehouseId = selectedWarehouse ? requiredWarehouse.id : null;
      // Prepare query paramenters to get filtered data from API
      const forQueryParams = {
        warehouseId: warehouseId,
        ...(fromDate &&
          toDate && {
            start_dt: format(fromDate, "yyyy-MM-dd"),
            end_dt: format(toDate, "yyyy-MM-dd"),
          }),
        query,
        page,
        status: "reviewed",
      };

      dispatch(
        actions.fetchData({
          payload: forQueryParams,
        })
      );
      dispatch(
        actions.fetchInspectionsCountData({
          payload: forQueryParams,
        })
      );
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }, 60000);
  };

  const handleReportLoading = () => {
    showErrorToast();
    if (!isLoading) {
      setIsLoading(true);
      initiateFetchData();
    }
  };
  //Dispatch Handlers
  const onClickViewReport = (fileName) => {
    // //console.log(`clicked to view ${fileName}`);
    if (fileName === "N.A.") {
      handleReportLoading();
      return;
    }
    dispatch(
      actions.viewReport({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        fileName,
      })
    );
  };
  const onClickDownloadReport = (inspection) => {
    //console.log(inspection);
    if (inspection?.fileName === "N.A.") {
      handleReportLoading();
      return;
    }
    dispatch(
      actions.downloadReport({
        source: { name: process.env.REACT_APP_SAS_URL_GENERATOR_SOURCE },
        inspectionDetails: inspection,
      })
    );
  };
  let modifiedCheckedOptions = checkedOptions.filter((item) => item);
  const ref2 = useRef();

  useOutsideClick({
    ref: ref2,
    handler: () => setIsOpen2(false),
  });

  const [isOpen2, setIsOpen2] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [monthArray, setMonthArray] = useState("");
  const yearList = Object.keys(yearMonthArray).filter((year) => year >= 2023);

  useEffect(() => {
    setMonthArray(yearMonthArray[selectedYear]);
  }, [selectedYear]);

  const handleDownloadSummarySheet = () => {
    dispatch(
      actions.downloadSummarySheet({
        month: selectedMonth.toLowerCase(),
        year: selectedYear,
      })
    );
  };
  if ((selectedMonth === "" || selectedYear === "") && yearList.length) {
    setSelectedYear(yearList[0]);
    setSelectedMonth(yearMonthArray[yearList[0]][0]);
  }
  useEffect(() => {
    if (selectedYear.length) {
      setSelectedMonth(yearMonthArray[selectedYear][0]);
    }
  }, [selectedYear]);
  const handleApplyButton = () => {
    setPage(0);
  };

  return (
    <>
      <Flex
        flexDirection="column"
        pt={{ base: "75px", md: "75px" }}
        gap={"10px"}
      >
        <Flex justifyContent={"space-between"}>
          <Text fontSize={"18px"} fontWeight={"700"}>
            {totalInspectionCount} Inspections
          </Text>
          {/* <HStack gap={"8px"}> */}
          {/* <Button colorScheme="login">
              <Link to={`/admin/my-approvals/inspections`}>
                <Text fontSize={"16px"} fontWeight={"600"}>
                  My Approvals
                </Text>
              </Link>
            </Button> */}
          <Button
            colorScheme="login"
            onClick={() => {
              setIsOpen2(!isOpen2);
            }}
          >
            <Flex gap={"8px"}>
              <Text fontSize={"16px"} fontWeight={"600"}>
                Download Report
              </Text>
              <Image src={Download} />
            </Flex>
          </Button>
          {/* </HStack> */}
        </Flex>
        <Box ref={ref2} className={isOpen2 ? "drop-Down2" : "hide"}>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {yearList.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
          <Select
            value={selectedMonth}
            onChange={(e) => {
              setSelectedMonth(e.target.value);
            }}
          >
            {monthArray ? (
              monthArray?.map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))
            ) : (
              <></>
            )}
          </Select>
          <Box alignContent={"center"} sx={{ marginLeft: "6%" }}>
            <Button colorScheme="login" onClick={handleDownloadSummarySheet}>
              <Flex gap={"8px"}>
                <Text fontSize={"16px"} fontWeight={"600"}>
                  Download
                </Text>
                <Image src={Download} />
              </Flex>
            </Button>
          </Box>
        </Box>
        <SearchAndFilterOptions
          warehouseOptions={warehouses}
          handleSearchQuery={handleSearchQuery}
          searchQuery={searchQuery}
          dropDownList={dropDownList}
          filters={filters}
          handleFromDateChange={handleFromDateChange}
          handleToDateChange={handleToDateChange}
          checkedOptions={modifiedCheckedOptions}
          handleCheckedOptions={handleCheckedOptions}
          handleReset={handleReset}
          handleDragUpdate={handleDragUpdate}
          handleApplyButton={handleApplyButton}
        />

        <Box>
          <InspectionDataTable
            onClickViewReport={onClickViewReport}
            onClickDownloadReport={onClickDownloadReport}
            data={data_transformed}
            heading={modifiedCheckedOptions}
            page={page}
            numPages={numPages}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            handlePageSelect={handlePageSelect}
            isLoadingPagination={isLoadingPagination}
          />
        </Box>
      </Flex>
    </>
  );
}
