import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const SAS_URL_GENERATOR = process.env.REACT_APP_SAS_URL_GENERATOR;
const AUTH_URL = process.env.REACT_APP_AUTH_API_ENDPOINT;

const headerHandler = () => {
  const storedLoginData = localStorage.getItem("inspectionDashboard");
  const loginData = JSON.parse(storedLoginData);
  const token = JSON.parse(localStorage.getItem("token"));

  const authToken = loginData ? loginData.token : token;

  return {
    authorization: authToken,
    "Content-Type": "application/json",
  };
};

//Inspection Apis (Gets the array of inspection ids.)
// export const getInspection = (payload) => {
//   console.log("coming to get Inspections");
//   let url = `${API_ENDPOINT}/inspections?`;
//   let isDoubleQuery = false;
//   if (
//     payload?.warehouseId !== null &&
//     payload?.warehouseId !== "" &&
//     payload?.warehouseId !== undefined
//   ) {
//     url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
//     isDoubleQuery = true;
//   }
//   if (payload.start_dt && payload.end_dt) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
//   }
//   if (payload.query.auditorName.length !== 0) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     const data = payload.query.auditorName.map((item) => `"${item}"`);
//     url = `${url}auditor_names=[${data}]`;
//   }
//   if (payload.query.cmName.length !== 0) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     const data = payload.query.cmName.map((item) => `"${item}"`);
//     url = `${url}cm_names=[${data}]`;
//   }
//   if (payload.query.originLocation.length !== 0) {
//     if (isDoubleQuery) url += "&";
//     else isDoubleQuery = true;
//     const data = payload.query.originLocation.map((item) => `"${item}"`);
//     url = `${url}locations=[${data}]`;
//   }
//   if (isDoubleQuery) url += "&";

//   if (payload?.page) {
//     url = `${url}page=${payload.page + 1}&size=11&order=DESC`;
//   }

//   const headers = headerHandler();
//   return axios.get(url, { headers });
// };

export const getSingleInspection = (payload) => {
  console.log({ payload });
  let url = `${API_ENDPOINT}/inspections/${payload.payload}`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const getInspection = (payload) => {
  let url = `${API_ENDPOINT}/inspections?`;
  let isDoubleQuery = false;
  // Check if payload is defined
  if (payload) {
    if (
      payload?.warehouseId !== null &&
      payload?.warehouseId !== "" &&
      payload?.warehouseId !== undefined
    ) {
      url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
      isDoubleQuery = true;
    }
    if (payload.start_dt && payload.end_dt) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
    }
    if (payload.query.auditorId.length !== 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      const data = payload.query.auditorId.map((item) => `"${item}"`);
      url = `${url}auditor_ids=[${data}]`;
    }
    if (payload.query.cmName.length !== 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      const data = payload.query.cmName.map((item) => `"${item}"`);
      url = `${url}cm_names=[${data}]`;
    }
    if (payload.query.originLocation.length !== 0) {
      if (isDoubleQuery) url += "&";
      else isDoubleQuery = true;
      const data = payload.query.originLocation.map((item) => `"${item}"`);
      url = `${url}locations=[${data}]`;
    }
    if (isDoubleQuery) url += "&";
    if (payload?.page >= 0) {
      url = `${url}page=${payload.page + 1}&size=10&order=DESC`;
      isDoubleQuery = true;
    }
  }

  if (payload?.status?.length > 0) {
    // console.log("Adding status to URL:", payload.status);
    if (isDoubleQuery) {
      url += "&";
    } else {
      isDoubleQuery = true;
    }
    url = `${url}status=${payload.status}`;
    // console.log(url);
  }

  const headers = headerHandler();

  return axios.get(url, { headers });
};

export const getAddFilterDropdown = (payload) => {
  let url = `${API_ENDPOINT}/inspections?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload?.query?.auditorId && payload?.query?.auditorId?.length !== 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    const data = payload.query.auditorName.map((item) => `"${item}"`);
    url = `${url}auditor_ids=[${data}]`;
  }
  if (payload?.query?.cmName && payload.query.cmName.length !== 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    const data = payload.query.cmName.map((item) => `"${item}"`);
    url = `${url}cm_names=[${data}]`;
  }
  if (
    payload?.query?.originLocation &&
    payload.query.originLocation.length !== 0
  ) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    const data = payload.query.originLocation.map((item) => `"${item}"`);
    url = `${url}locations=[${data}]`;
  }
  if (payload?.status !== "" && payload?.status?.length > 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}status=${payload.status}`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const getInspectionCountData = (payload) => {
  let url = `${API_ENDPOINT}/inspections?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.query.auditorId.length !== 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    const data = payload.query.auditorId.map((item) => `"${item}"`);
    url = `${url}auditor_ids=[${data}]`;
  }
  if (payload.query.cmName.length !== 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    const data = payload.query.cmName.map((item) => `"${item}"`);
    url = `${url}cm_names=[${data}]`;
  }
  if (payload.query.originLocation.length !== 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    const data = payload.query.originLocation.map((item) => `"${item}"`);
    url = `${url}locations=[${data}]`;
  }
  if (payload?.status !== "" && payload?.status?.length > 0) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}status=${payload.status}`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};
export const getWarehouses = (payload) => {
  let url = `${API_ENDPOINT}/origins?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }

  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};

export const fetchSASUrl = (payload) => {
  const url = SAS_URL_GENERATOR;
  return axios.post(url, payload);
};

export const fetchSummarySheet = (payload) => {
  let url = `${API_ENDPOINT}/extras/summary-report`;
  const headers = headerHandler();

  return axios.post(url, payload, { headers, responseType: "arraybuffer" });
};

export const fetchReportBlob = async (payload) => {
  const res = await axios.get(payload);
  const blob = await res.blob();
  return blob;
};

//Login Apis
export const loginAPI = (payload) => {
  const url = `${AUTH_URL}/users/login`;
  return axios.post(url, payload);
};

export const sendFPOTP = (email, otpType) => {
  const url = `${AUTH_URL}/users/emailotp/${email}/${otpType}`;
  return axios.get(url);
};

export const resendFPOTP = (oid, otpType) => {
  const url = `${AUTH_URL}/users/resendemailotp/${oid}/${otpType}`;
  return axios.get(url);
};

export const validateOTP = (otp, oid) => {
  const url = `${AUTH_URL}/users/validateotp?otp=${otp}&oid=${oid}`;
  return axios.get(url);
};

export const setNewPassword = (oid, password) => {
  const url = `${AUTH_URL}/users/setnewpassword/${oid}/${password}`;
  return axios.post(url);
};

export const sendReport = (payload) => {
  const url = `${API_ENDPOINT}/extras/sendMail`;

  const headers = headerHandler();
  return axios.post(url, payload, { headers });
};

//Notification Apis
export const getNotifications = (payload) => {
  const url = `${API_ENDPOINT}/notifications/user/${payload.userId}`;
  const headers = headerHandler();
  return axios.get(url, { headers });
};
// update all user notifications
export const markReadAllNotifications = (payload) => {
  const url = `${API_ENDPOINT}/notifications/user/${payload.userId}`;

  const headers = headerHandler();
  return axios.put(url, { headers });
};
export const readNotification = (payload) => {
  const url = `${API_ENDPOINT}/notifications/${payload}`;
  const headers = headerHandler();
  return axios.put(url, { headers });
};
export const getAllItems = (payload) => {
  const url = `${API_ENDPOINT}/inspections/${JSON.stringify(payload)}/items`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};
//Analytical Dashboard Apis

export const getAnalyticsData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }

  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Bar Chart Data
export const getBarChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/inspections-insights?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }
  if (payload.unit) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}type=${payload.unit}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Doughnut Chart Data
export const getDoughnutChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/commodity-health-insights?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Quantity Insights
export const getQuantityInsightsData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/quantity-insights?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Warehouse Insights
export const getWarehouseInsightsData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/origin-insights?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined &&
    payload.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Line Chart Data
export const getPortfolioLineChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights/portfolio?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getInspectionLineChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights/inspections?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getQuantityLineChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/insights/quantity?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const getMapChartData = (payload) => {
  let url = `${API_ENDPOINT}/analytics/geo-insights?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Commodities Data
export const getCommoditiesData = (payload) => {
  let url = `${API_ENDPOINT}/inspections/commodities?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

//Profile dropdown Account page apis

export const getPreDefinedURL = (payload) => {
  let url = `${API_ENDPOINT}/extras/pre-signed-url`;
  const headers = headerHandler();

  return axios.post(url, { fileName: payload }, { headers });
};
export const uploadProfileImage = (payload) => {
  let url = payload.uploadURL;
  var headers = {
    "x-ms-blob-type": "BlockBlob",
    "x-ms-date": new Date().toUTCString(),
  };

  var response = fetch(url, {
    method: "PUT",
    headers: headers,
    body: payload.fileBlob,
    muteHttpExceptions: true,
  });
  return response;
};
export const getUserProfile = (payload) => {
  let url = `${API_ENDPOINT}/users/profile/${payload.id}`;

  const headers = headerHandler();
  return axios.get(url, { headers });
};
export const updateUserProfile = (payload) => {
  let url = `${API_ENDPOINT}/users/profile/${payload.id}`;

  const headers = headerHandler();
  return axios.put(url, payload.payload, { headers });
};
export const userPasswordReset = (payload) => {
  let url = `${API_ENDPOINT}/users/reset-password`;

  const headers = headerHandler();
  return axios.put(url, payload.passwordData, { headers });
};
export const getSearchData = (payload) => {
  let url = `${API_ENDPOINT}/search/inspections?`;
  if (payload.query !== null && payload.query !== "") {
    url = `${url}q=[${JSON.stringify(payload.query)}]`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};
export const getWarehouseScore = (payload) => {
  let url = `${API_ENDPOINT}/analytics/warehouse-score?`;
  let isDoubleQuery = false;
  if (
    payload?.warehouseId !== null &&
    payload?.warehouseId !== "" &&
    payload?.warehouseId !== undefined
  ) {
    url = `${url}origins=[${JSON.stringify(payload.warehouseId)}]`;
    isDoubleQuery = true;
  }
  if (payload.commodities) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}commodities=[${JSON.stringify(payload.commodities)}]`;
  }
  if (payload.location) {
    if (isDoubleQuery) url += "&";
    url = `${url}locations=[${JSON.stringify(payload.location)}]`;
    isDoubleQuery = true;
  }
  if (payload.start_dt && payload.end_dt) {
    if (isDoubleQuery) url += "&";
    else isDoubleQuery = true;
    url = `${url}start_dt=${payload.start_dt}&end_dt=${payload.end_dt}`;
  }
  const headers = headerHandler();
  return axios.get(url, { headers });
};

//Roles & Permission APIs

export const userRoles = (query) => {
  let url = `${API_ENDPOINT}/user-roles`;
  if (query) {
    url = `${API_ENDPOINT}/user-roles${query}`;
  }
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const editRole = (roleId) => {
  // let url = `${API_ENDPOINT}/roles/${roleId}`;/
  let url = `${API_ENDPOINT}/roles`;

  if (roleId) {
    url = `${API_ENDPOINT}/roles/${roleId}`;
  }

  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const permissionsData = () => {
  let url = `${API_ENDPOINT}/perms-set`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const postUserData = (payload) => {
  let url = `${API_ENDPOINT}/roles`;
  const header = headerHandler();
  return axios.post(url, payload, { headers: header });
};

export const rolePermissionData = (roleId) => {
  let url = `${API_ENDPOINT}/role-perms-set/roles/${roleId}`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const userDetails = async (userIds) => {
  const filteredUserIds = userIds.filter((id) => id !== undefined);
  if (filteredUserIds.length == 0) {
    return;
  }
  let promiseArray = filteredUserIds.map((id) => {
    let url = `${API_ENDPOINT}/users/profile/${id}`;
    const header = headerHandler();
    return axios.get(url, { headers: header });
  });
  const res = await Promise.all(promiseArray);
  return res;
};

export const updateRoleData = (payload) => {
  const { roleId, newRoleObject } = payload;
  // console.log(newRoleObject);
  let url = `${API_ENDPOINT}/roles/${roleId}`;
  const header = headerHandler();
  return axios.put(url, newRoleObject, { headers: header });
};

export const userAssignedData = (roleId) => {
  let url = `${API_ENDPOINT}/user-roles/roles/${roleId}`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const deleteRole = (payload) => {
  const { roleId, newRoleId } = payload;

  const assignParam = newRoleId ? `?assign=${newRoleId}` : "";
  let url = `${API_ENDPOINT}/roles/${roleId}${assignParam}`;
  const header = headerHandler();
  return axios.delete(url, { headers: header });
};

export const getSingleUsers = (roleId) => {
  let url = `${API_ENDPOINT}/user-roles/roles/${roleId}/assigned/active`;
  const header = headerHandler();
  return axios.get(url, { headers: header });
};

export const deactivateRole = (payload) => {
  const { roleId, newRoleInfo } = payload;
  // console.log(roleId);
  // console.log(newRoleInfo);
  let url = `${API_ENDPOINT}/roles/deactivate/${roleId}`;
  const headers = headerHandler();
  if (newRoleInfo) {
    return axios.put(url, newRoleInfo, { headers });
  }
  return axios.put(url, {}, { headers });
};

export const activateRole = (payload) => {
  const { roleId, isRevokeAssignments } = payload;
  const revokeInfo = { isRevokeAssignments };
  let url = `${API_ENDPOINT}/roles/activate/${roleId}`;
  const headers = headerHandler();

  return axios.put(url, revokeInfo, { headers });
};

/* export const login = (payload) => {
  const url = `${API_ENDPOINT}/auth/login`;
  console.log({ url: url });
  return API.post(url, payload);
}; */
