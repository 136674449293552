import { createSlice } from "@reduxjs/toolkit";
export const initialState = {
  loading: false,
  tableLoading: false,
  emailLoader: null,
  reportLoader: null,
  data: null,
  tableData: null,
  warehousesData: null,
  inspectionIdArr: [],
  addFilterDropdown: [],
  analyticsData: null,
  inspectionCount: null,
  downloading: false,
  downloadingSheet: false,
  error: null,
  emailLoader: null,
  reportLoader: null,
  fetchGeneratedReportMonthsLoading: false,
  generatedReportMonths: [],
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    fetchData(state, action) {
      state.loading = true;
    },
    fetchDataSuccess(state, action) {
      let requiredData = action?.payload || [];
      state.data = requiredData;
      state.inspectionIdArr = requiredData.map((obj) => {
        return obj.id;
      });
      state.loading = false;
    },
    fetchDataFailure(state, action) {
      state.loading = false;
    },
    fetchAddFilterDropdown(state, action) {
      // state.loading = true;
    },
    fetchAddFilterDropdownSuccess(state, action) {
      let requiredData = action?.payload || [];
      if (state.addFilterDropdown.length === 0) {
        state.addFilterDropdown = requiredData;
      }
      // state.loading = false;
    },
    fetchAddFilterDropdownFailure(state, action) {
      // state.loading = false;
    },
    fetchAllData(state, action) {
      state.tableLoading = true;
    },
    fetchAllDataSuccess(state, action) {
      let requiredData = action?.payload || [];
      state.tableData = requiredData;
      state.tableLoading = false;
    },
    fetchAllDataFailure(state, action) {
      state.tableLoading = false;
    },
    fetchWarehouses(state, action) {
      state.loading = true;
    },
    fetchWarehousesSuccess(state, action) {
      state.warehousesData = action.payload;
      state.loading = false;
    },
    fetchWarehousesFailure(state, action) {
      state.loading = false;
    },
    viewReport(state, action) {
      state.reportLoader = true;
    },
    viewReportSuccess(state, action) {
      state.reportLoader = false;
    },
    viewReportFailure(state, action) {
      state.reportLoader = false;
    },
    downloadReport(state, action) {
      state.downloading = true;
    },
    downloadReportSuccess(state, action) {
      state.downloading = false;
    },
    downloadReportFailure(state, action) {
      state.downloading = false;
    },
    sendEmail(state, action) {
      state.emailLoader = true;
    },
    sendEmailSuccess(state, action) {
      state.emailLoader = false;
    },
    sendEmailFailure(state, action) {
      state.emailLoader = false;
    },
    fetchInspectionsCountData(state, action) {
      state.tableLoading = true;
    },
    fetchInspectionsCountDataSuccess(state, action) {
      state.inspectionCount = action.payload.length;
      state.tableLoading = false;
    },
    fetchInspectionsCountDataFailure(state, action) {
      state.tableLoading = false;
    },
    downloadSummarySheet(state, action) {
      state.downloadingSheet = true;
    },
    downloadSummarySheetSuccess(state, action) {
      state.downloadingSheet = false;
    },
    downloadSummarySheetFailure(state, action) {
      state.downloadingSheet = false;
    },
    fetchGeneratedReportMonths(state, action) {
      state.fetchGeneratedReportMonthsLoading = true;
    },
    fetchGeneratedReportMonthsSuccess(state, action) {
      state.fetchGeneratedReportMonthsLoading = false;
      state.generatedReportMonths = action.payload;
    },
    fetchGeneratedReportMonthsFailure(state, action) {
      state.fetchGeneratedReportMonthsLoading = false;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.data = "";
      state.tableData = "";
      state.warehousesData = "";
      state.inspectionIdArr = "";
      state.addFilterDropdown = "";
      state.analyticsData = "";
      state.inspectionCount = "";
      state.generatedReportMonths = "";
    },
    clearStateFailure(state, action) {},
  },
});

export const { actions, reducer, name: sliceKey } = dashboardSlice;
