import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./slice";

const selectDomain = (state) => state.homePage || initialState;

export const selecReviewData = createSelector(
  [selectDomain],
  (state) => state.reviewData
);
export const selectWorkflowId = createSelector(
  [selectDomain],
  (state) => state.workflow && state.workflow.id
);

export const selectSections = createSelector(
  [selectDomain],
  (state) => state.sections
);

export const selectWorkflow = createSelector(
  [selectDomain],
  (state) => state.workflow
);

export const selectSectionsForBlocks = createSelector(
  [selectDomain],
  (state) => state.sections || []
);

export const selectBlocksForComposites = createSelector(
  [selectDomain],
  (state) => state.blocks || []
);

export const selectCompositesForComponents = createSelector(
  [selectDomain],
  (state) => state.composites || []
);

export const selectComponentsForElements = createSelector(
  [selectDomain],
  (state) => state.components || []
);

export const selectSectionList = createSelector(
  [selectDomain],
  (state) =>
    state.sections &&
    [...state.sections].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectBlockList = createSelector(
  [selectDomain],
  (state) =>
    state.blocks &&
    [...state.blocks].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectCompositeList = createSelector(
  [selectDomain],
  (state) =>
    state.composites &&
    [...state.composites].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);

export const selectComponentList = createSelector(
  [selectDomain],
  (state) =>
    state.components &&
    [...state.components].reduce((acc, cv) => {
      const newObj = { value: cv.id, label: cv.title };
      acc.push(newObj);
      return acc;
    }, [])
);
export const selectReviewDataLoading = createSelector(
  [selectDomain],
  (state) => state.reviewDataLoading
);
export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);