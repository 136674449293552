import React, { useCallback, useEffect, useState } from "react";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "./slice";
import { useParams } from "react-router-dom";
import { workflowDataSaga } from "./saga";
import * as selectors from "./selectors";
import { useSelector, useDispatch } from "react-redux";
import HeaderGray from "views/Workflow/components/headerGray";
import { VStack, Text, Box, useMediaQuery } from "@chakra-ui/react";
import Section from "./section";
import { convertToCamelCase, generateUUId } from "utils/utils";
import _ from "lodash";

function hasPageNumber(arr, pageNumber) {
  let res = -1;
  arr.forEach((el, i) => {
    if (el.pageNumber === pageNumber) {
      res = i;
    }
  });
  return res;
}

export default function View() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: workflowDataSaga });

  const dispatch = useDispatch();
  const { workflowId } = useParams();

  const [files, setFiles] = useState([]);

  const [formData, setFormData] = useState({});

  const [navIndex, setNavIndex] = useState(null);

  const [page, setPage] = useState(0);

  const [previewData, setPreviewData] = useState([]);

  const handleFilesChange = (file) => {
    const newFiles = [...files];
    newFiles.push(file);
    setFiles(newFiles);
  };

  const [isMobileScreen] = useMediaQuery("(max-width: 872px)");

  const workflow = useSelector(selectors.selectWorkflow);

  let sections = useSelector(selectors.selectSections);

  const sectionsForBlocks = useSelector(selectors.selectSectionsForBlocks);

  const blocksForComposites = useSelector(selectors.selectBlocksForComposites);

  const compositesForComponents = useSelector(
    selectors.selectCompositesForComponents
  );

  const componentsForElements = useSelector(
    selectors.selectComponentsForElements
  );

  const getElementsState = useCallback(() => {
    const elements = componentsForElements.reduce((acc, cv) => {
      const elementsCurr = cv.structure.elements.map((element) => element);
      elementsCurr.forEach((currEl) => {
        acc[currEl.id] = {
          title: currEl.label,
          name: convertToCamelCase(currEl.label),
          value: "",
          attachments: [],
          itemType: currEl.htmlType,
          mimeType: "NA",
          itemOrder: currEl.order,
          parentId: cv.id,
          parentType: "component",
          parentInfo: {
            id: cv.id,
            title: cv.title,
          },
          id: generateUUId(),
          workflowId: workflow[0].id,
          templateItemId: currEl.id,
          itemInfo: currEl.properties ? currEl.properties : {},
          inspectionId: workflow[0].inspectionId,
          mandatory: currEl.mandatory,
          tableName: "inspectionItems",
        };
      });
      return acc;
    }, {});
    return elements;
  }, [componentsForElements]);

  const getElements = useCallback(
    (sectionId) => {
      let elementDisplayData = [];
      if (
        sectionsForBlocks &&
        blocksForComposites &&
        compositesForComponents &&
        componentsForElements
      ) {
        sectionsForBlocks.forEach((section) => {
          section.structure.blocks.forEach((block) => {
            blocksForComposites.forEach((blockForComposites) => {
              if (block.id === blockForComposites.id) {
                blockForComposites.structure.composites.forEach((composite) => {
                  compositesForComponents.forEach((compositeForComponents) => {
                    if (composite.id === compositeForComponents.id) {
                      compositeForComponents.structure.components.forEach(
                        (component) => {
                          componentsForElements.forEach(
                            (componentForElements) => {
                              if (componentForElements.id === component.id) {
                                componentForElements.structure.elements.forEach(
                                  (element) => {
                                    elementDisplayData.push({
                                      sectionId: section.id,
                                      element,
                                    });
                                  }
                                );
                              }
                            }
                          );
                        }
                      );
                    }
                  });
                });
              }
            });
          });
        });
      }
      const elements = [];
      elementDisplayData.forEach((element) => {
        if (element.sectionId === sectionId) {
          elements.push(element);
        }
      });
      return elements;
    },
    [
      sectionsForBlocks,
      blocksForComposites,
      compositesForComponents,
      componentsForElements,
    ]
  );

  const getDisplayData = useCallback(() => {
    let displayData = [];
    if (sectionsForBlocks) {
      sectionsForBlocks.forEach((section) => {
        if (hasPageNumber(displayData, section.pageNumber) !== -1) {
          const newSections =
            displayData[hasPageNumber(displayData, section.pageNumber)]
              .sections;
          newSections.push(section);
          displayData[
            hasPageNumber(displayData, section.pageNumber)
          ].sections = newSections;
        } else {
          displayData.push({
            pageNumber: section.pageNumber,
            sections: [section],
          });
        }
      });
    }
    displayData = displayData.sort((a, b) => a.pageNumber - b.pageNumber);
    return displayData;
  }, [sectionsForBlocks]);

  const handleFormDataChange = (e, val, id, mimeType, files) => {
    if (_.isArray(val)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: [...val],
          files,
          mimeType,
        },
      }));
      return;
    }
    if (_.isArray(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          attachments: e,
          mimeType,
        },
      }));
      return;
    }
    if (_.isString(e) || _.isDate(e)) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: e,
        },
      }));
      return;
    }
    if (val && id) {
      setFormData((prevState) => ({
        ...prevState,
        [id]: {
          ...prevState[id],
          value: val,
        },
      }));
      return;
    }
    const { name, value, checked } = e.target;
    if (!val) {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: value || value === "" || value === 0 ? value : checked,
        },
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: {
          ...prevState[name],
          value: val,
        },
      }));
    }
  };

  let getPreviewData = useCallback(() => {
    if (_.isArray(sections)) {
      let data = [...sections].sort((a, b) => {
        return a.order - b.order;
      });
      setPreviewData(data);
    }
  }, [sections]);

  useEffect(() => {
    dispatch(actions.fetchWorkflowData({ id: workflowId }));
  }, [dispatch]);

  useEffect(() => {
    getPreviewData();
  }, [sections]);

  useEffect(() => {
    setFormData(getElementsState());
  }, [getElementsState]);

  return (
    <VStack mt={"75px"} w="100%" gap={"36px"}>
      <Box w="100%">
        <HeaderGray
          workflow={workflow && workflow[0]}
          pageNumber={page}
          numPages={getDisplayData().length}
        />
        <Box w="100%" style={{ background: "#F9F9FB", position: "relative" }}>
          <VStack
            w={isMobileScreen ? "100%" : "740px"}
            style={{
              margin: "0 auto",
              padding: "64px 24px",
            }}
            gap="64px"
            area-label="sections"
            justify="flex-start"
            align="flex-start"
          >
            {formData !== undefined &&
              sections &&
              sections.length &&
              previewData.length !== 0 &&
              previewData.map((section) => {
                return (
                  <Section
                    key={section.id}
                    section={section}
                    data={getElements(section.id)}
                    formData={formData}
                    handleFormDataChange={handleFormDataChange}
                    handleFilesChange={handleFilesChange}
                    setPage={setPage}
                    navIndex={navIndex}
                    setNavIndex={setNavIndex}
                  />
                );
              })}
          </VStack>
        </Box>
      </Box>
    </VStack>
  );
}
