export const CmHeader = [
  {
    displayText: "CM Name",
    keyText: "cmName",
    hasSort: true,
    hasTooltip: false,
  },
  {
    displayText: "Average Warehouse Score",
    keyText: "avgScore",
    hasSort: true,
  },
  {
    displayText: "Warehouse Count",
    keyText: "warehouseCount",
    hasSort: true,
  },

  {
    displayText: "Inspection Count",
    keyText: "inspectionCount",
    hasSort: true,
  },
  { displayText: "Quantity (MT)", keyText: "quantity", hasSort: true },
  { displayText: "No. Of Bags", keyText: "bagCount", hasSort: true },
];
// bagCount: "10370";
// cmName: "GOGREEN WAREHOUSES PRIVATE LIMITED";
// location: "Deesa";
// quantity: 364.7169999999999;
// warehouseCode: "2AN0001060";
// warehouseName: "SHREE KHETIWADI UTPANN BAZAR SAMITI GODOWN NO 2";
// warehouseScore: "52.00";
export const CmDetailsHeader = [
  {
    displayText: "Warehouse Name",
    keyText: "originName",
    hasSort: true,
    hasTooltip: false,
  },
  {
    displayText: "Warehouse Code",
    keyText: "externalCode",

    hasSort: true,
  },
  {
    displayText: "Average Warehouse Score",
    keyText: "warehouseScore",
    hasSort: true,
  },
  {
    displayText: "Inspection Count",
    keyText: "inspectionCount",
    hasSort: true,
  },
  { displayText: "No. Of Bags", keyText: "bagCount", hasSort: true },
  { displayText: "Quantity (MT)", keyText: "quantity", hasSort: true },
];
