import { Flex } from "@chakra-ui/react";
import DropdownWithCheckboxesLatest from "components/DropdownWithCheckboxes/DropdownWithCheckBoxesLatest";
import React, { useState } from "react";
import { useEffect } from "react";

const style = {
  /* height: 72px; */
  backgroundColor: "white",
  borderRadius: "15px",
  gap: "16px",
  padding: "16px",
  justifyContent: "space-between",
  boxShadow: "0px 3.49px 5.5px 0px rgba(0, 0, 0, 0.02)",
};

const UserRoleFilter = ({
  allPages,
  allActions,
  allStatus,
  allRoles,
  filterRoles,
}) => {
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleSelectChange = (itemId, property) => {
    switch (property) {
      case "Roles":
        setSelectedRoles((prevSelectedItems) =>
          handleSelection(prevSelectedItems, itemId)
        );
        break;
      case "Permissions":
        setSelectedPermissions((prevSelectedItems) =>
          handleSelection(prevSelectedItems, itemId)
        );
        break;
      case "Pages":
        setSelectedPages((prevSelectedItems) =>
          handleSelection(prevSelectedItems, itemId)
        );
        break;
      case "Status":
        setSelectedStatus(itemId);
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    filterRoles(
      selectedRoles,
      selectedPermissions,
      selectedPages,
      selectedStatus
    );
  }, [selectedRoles, selectedPermissions, selectedPages, selectedStatus]);

  const handleSelection = (prevSelectedItems, itemId) => {
    if (prevSelectedItems.includes(itemId)) {
      return prevSelectedItems.filter((id) => id !== itemId);
    } else {
      return [...prevSelectedItems, itemId];
    }
  };

  return (
    <Flex sx={style}>
      <DropdownWithCheckboxesLatest
        dropDownList={allRoles}
        property="Roles"
        handleSelectChange={handleSelectChange}
        selectedItems={selectedRoles}
        title="Roles"
        flex="1"
        showCheckBox
      />
      <DropdownWithCheckboxesLatest
        dropDownList={allActions}
        property="Permissions"
        handleSelectChange={handleSelectChange}
        selectedItems={selectedPermissions}
        title="Permissions"
        flex="1"
        showCheckBox
      />
      <DropdownWithCheckboxesLatest
        dropDownList={allPages}
        property="Pages"
        handleSelectChange={handleSelectChange}
        selectedItems={selectedPages}
        title="Pages"
        flex="1"
        showCheckBox
      />
      <DropdownWithCheckboxesLatest
        dropDownList={allStatus}
        property="Status"
        handleSelectChange={handleSelectChange}
        selectedItems={selectedStatus}
        title="Status"
        flex="1"
        showCheckBox={false}
      />
    </Flex>
  );
};

export default UserRoleFilter;
