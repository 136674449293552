import React, { useState } from "react";
import { Image } from "@chakra-ui/react";
import Banner from "components/Layout/auth/Banner/Banner.js";
import perfeqtLogo from "assets/perfeqt Logoo-01.png";
import "./index.css";
import { loginAPI } from "services/apis";
import { LoginForm } from "@agnext/reactlib";
import { useHistory } from "react-router-dom";

function SignIn() {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const history = useHistory();

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setEmailError("");
    setError("");
  };

  const passwordHandler = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
    setError("");
  };

  const handleForgotPassword = () => {
    history.push("/auth/forgot-password");
  };

  const loginHandler = async (e) => {
    e.preventDefault();
    const isValidEmail = validateType(email);
    if (!isValidEmail) {
      setEmailError("Please provide a valid email");
      return;
    } else if (password === "") {
      setPasswordError("Please enter password");
      return;
    }
    try {
      const loginResponse = await loginAPI({ email, password });
      const loginResponseData = loginResponse.data.payload;
      if (loginResponseData.token) {
        localStorage.setItem(
          "inspectionDashboard",
          JSON.stringify(loginResponseData)
        );
        setEmail("");
        setPassword("");
        history.push("/admin/dashboard");
      } else {
        setError("Wrong email or password");
      }
    } catch (e) {
      if (
        e.response &&
        e.response.data &&
        e.response.data.reason === "Email not registered"
      ) {
        setError("Email not registered");
      } else if (
        e.response &&
        e.response.data &&
        e.response.data.reason === "Password does not match"
      ) {
        setError("Incorrect Password");
      } else {
        setError("Internal server error");
      }
    }
  };
  const validateType = (email, password) => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  return (
    <LoginForm
      type="signin"
      leftComponent={<Banner />}
      logo={<Image src={perfeqtLogo} alt="perfeqt-logo" />}
      email={email}
      password={password}
      emailErrorMessage={emailError}
      passwordErrorMessage={passwordError}
      onEmailChange={emailHandler}
      onPasswordChange={passwordHandler}
      handleForgotPassword={handleForgotPassword}
      handleLogin={loginHandler}
      error={error}
    />
  );
}
export default SignIn;
