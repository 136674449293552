import React from "react";
import { HStack, Input } from "@chakra-ui/react";
import { MdOutlineAlternateEmail } from "react-icons/md";

export default function Email({ formData, handleFormDataChange, data }) {
  return (
    <HStack
      w="100%"
      h="48px"
      style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
    >
      <HStack w="48px" h="48px" justify="center" align="center">
        <MdOutlineAlternateEmail size={18} style={{ fill: "#718096" }} />
      </HStack>
      <Input
        type="email"
        variant="unstyled"
        placeholder={data.element.placeholder || "Email"}
        required={data.element.mandatory}
        color="#2D3748"
        _placeholder={{ color: "#A0AEC0" }}
        name={data.element.id}
        value={formData[data.element.id].value}
        onChange={handleFormDataChange}
      />
    </HStack>
  );
}
