import { Box, Flex, Image } from "@chakra-ui/react";
import DateRangePicker from "./DateRangePicker";
import { SearchBar } from "./SearchBar";
import WarehouseFilter from "../../../shared/WarehouseFilter";
import ColumnFilter from "./ColumnFilter";
import { VerticalSeparator } from "components/Separator/VerticalSeperator";
import ToggleInsights from "./ToggleInsights";
import AddFilter from "./AddFilter";
import Seperator from "assets/svg/seperator.svg";
import React, { useState, useEffect } from "react";

export default function SearchAndFilterOptions({
  warehouseOptions,
  handleSearchQuery,
  searchQuery,
  dropDownList,
  filters,
  handleFromDateChange,
  handleToDateChange,
  checkedOptions,
  handleCheckedOptions,
  handleReset,
  handleDragUpdate,
  handleApplyButton,
}) {
  const allFilterCategories = [
    { title: "CM Name", property: "cmName" },
    {
      title: "Auditor Name",
      property: "auditorId",
      extraProperty: "auditorName",
    },
    { title: "Location", property: "originLocation" },
  ];

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Flex className="search-filter-container">
      <Flex
        w={{ base: "100%", md: "63%" }}
        gap={{ base: "10px", md: "16px" }}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex gap={{ base: "16px" }}>
          <Box flex={{ base: "1", md: "auto" }}>
            <WarehouseFilter warehouseOptions={warehouseOptions} />
          </Box>
          {isMobile && (
            <Box display={{ base: "block", md: "none", lg: "none" }}>
              <Flex flexDirection="row" gap="16px">
                <AddFilter
                  allFilterCategories={allFilterCategories}
                  warehouseOptions={warehouseOptions}
                  dropDownList={dropDownList}
                  handleApplyButton={handleApplyButton}
                  isMobile={isMobile}
                />
                <ColumnFilter
                  checkedOptions={checkedOptions}
                  handleCheckedOptions={handleCheckedOptions}
                  handleReset={handleReset}
                  handleDragUpdate={handleDragUpdate}
                  isMobile={isMobile}
                />
              </Flex>
            </Box>
          )}
        </Flex>

        <Box>
          <DateRangePicker
            filters={filters}
            handleFromDateChange={handleFromDateChange}
            handleToDateChange={handleToDateChange}
          />
        </Box>
      </Flex>

      <Box display={{ base: "none", md: "block" }}>
        {!isMobile && (
          <Flex flexDirection="row" gap="16px">
            <Box>
              <Image src={Seperator} />
            </Box>

            <AddFilter
              allFilterCategories={allFilterCategories}
              warehouseOptions={warehouseOptions}
              dropDownList={dropDownList}
              handleApplyButton={handleApplyButton}
            />
            <ColumnFilter
              checkedOptions={checkedOptions}
              handleCheckedOptions={handleCheckedOptions}
              handleReset={handleReset}
              handleDragUpdate={handleDragUpdate}
              isMobile={isMobile}
            />
          </Flex>
        )}
      </Box>
    </Flex>
  );
}
