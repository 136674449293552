import React from "react";
import { useHistory } from "react-router-dom";
import Banner from "components/Layout/auth/Banner/Banner";

import perfeqtLogo from "assets/perfeqt Logoo-01.png";

import { LoginForm } from "@agnext/reactlib";

const PasswordResetSuccessful = () => {
  const history = useHistory();

  const handleBackToSignin = () => {
    history.push("/auth/signin");
  };

  return (
    <LoginForm
      type="passwordResetSuccessful"
      leftComponent={<Banner />}
      logo={<Image src={perfeqtLogo} alt="perfeqt-logo" />}
      handleGoBack={handleBackToSignin}
    />
  );
};
export default PasswordResetSuccessful;
