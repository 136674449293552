import { Select } from "@agnext/reactlib";
import _ from "lodash";

const elementOptions = [
  { label: "Radio", value: "radio" },
  { label: "Checkbox", value: "checkbox" },
  { label: "Text Input", value: "textInput" },
  { label: "Number Input", value: "numberInput" },
  { label: "Dropdown", value: "dropdown" },
  { label: "Switch", value: "switch" },
  { label: "Textarea", value: "textarea" },
  { label: "Datepicker", value: "datepicker" },
  { label: "Rangepicker", value: "rangepicker" },
  { label: "DateTimePicker", value: "dateTimepicker" },
  { label: "Link (anchor tag)", value: "link" },
  { label: "Container", value: "container" },
  { label: "Tooltip", value: "tooltip" },
  { label: "Button", value: "button" },
  { label: "Editable", value: "editable" },
  { label: "Single File UPload", value: "singleFileUpload" },
  { label: "Multi File Upload", value: "multiFileUpload" },
  { label: "Email", value: "email" },
  { label: "Mobile", value: "mobile" },
];

const getElement = (value) => {
  let el = {};
  elementOptions.forEach((option) => {
    if (option.value === value) {
      el = { ...option };
    }
  });
  return el;
};

export default function SelectElements({
  element,
  onElementChange,
  focusBorderColor,
}) {
  return (
    <Select
      value={element.value}
      options={_.orderBy(elementOptions, "value", "asc")}
      onChange={(e) => {
        onElementChange(getElement(e.target.value));
      }}
      placeholder="Select HTML element"
      focusBorderColor={focusBorderColor}
    />
  );
}
