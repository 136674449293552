import { useState } from "react";
import { useHistory } from "react-router-dom"; // Import useHistory
import "./index.css";
import { Box, Button, Flex, Image, Input, Text } from "@chakra-ui/react";
import Banner from "components/Layout/auth/Banner/Banner";

import perfeqtLogo from "assets/perfeqt Logoo-01.png";

import { sendFPOTP } from "services/apis";
import { SpinnerIcon, WarningIcon } from "@chakra-ui/icons";
import { LoginForm } from "@agnext/reactlib";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory(); // Initialize the useHistory hook

  const emailHandler = (e) => {
    setEmail(e.target.value);
    setEmailError("");
    setError("");
  };

  const handleSendOTP = async () => {
    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      setEmailError("Please provide a valid email");
      return;
    }
    setLoading(true);
    try {
      const fpResponse = await sendFPOTP(email, "forgot_password");
      const otpId = fpResponse.data.payload.otpId;
      history.push(`/auth/send-otp?oid=${otpId}&email=${email}`);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.reason) {
        setError(error.response.data.reason);
      } else {
        setError("Internal server error");
      }
      setLoading(false);
    }
  };

  const handleBackToSignin = () => {
    history.push("/auth/signin");
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  return (
    <LoginForm
      type="forgotPassword"
      leftComponent={<Banner />}
      logo={<Image src={perfeqtLogo} alt="perfeqt-logo" />}
      email={email}
      emailErrorMessage={emailError}
      onEmailChange={emailHandler}
      handleGoBack={handleBackToSignin}
      handleSubmit={handleSendOTP}
      error={error}
    />
  );
};
export default ForgotPassword;
