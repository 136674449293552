// import * as API from "../utils/apiHelper";
import axiosBuilder from "../axios";

const headerHandler = (token) => {
  const storedLoginData = localStorage.getItem("inspectionDashboard");
  const loginData = JSON.parse(storedLoginData);
  return {
    authorization: token || loginData?.token,
    "Content-Type": "application/json",
  };
};

export const postWorkflowData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    axiosBuilder.post("/forms/create", payload, { headers });
  } else {
    const headers = headerHandler();
    axiosBuilder.post("/forms/create", payload, { headers });
  }
};

export const fetchWorkflows = () => {
  // if (payload.token) {
  //   const { token } = payload;
  //   const headers = headerHandler(token);
  //   return axios.get("/forms/");
  // } else {
  const headers = headerHandler();
  return axiosBuilder.get("/forms/", { headers });
  // }
};

export const fetchWorkflowData = (payload) => {
  //console.log(payload);
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/forms/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/forms/${payload.id}`, { headers });
  }
};

export const fetchSectionData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/section/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/section/${payload.id}`, { headers });
  }
};

export const fetchBlockData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/block/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/block/${payload.id}`, { headers });
  }
};

export const fetchCompositeData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/composite/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/composite/${payload.id}`, { headers });
  }
};

export const fetchComponentData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.get(`/component/${payload.id}`, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.get(`/component/${payload.id}`, { headers });
  }
};

export const fetchSasurl = (payload) => {
  //console.log({ payload });
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.post("/extras/pre-signed-url", payload, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.post("/extras/pre-signed-url", payload, { headers });
  }
};

export const uploadFile = (payload) => {
  let url = payload.sasURL;
  var headers = {
    "x-ms-blob-type": "BlockBlob",
    "x-ms-date": new Date().toUTCString(),
  };
  var response = fetch(url, {
    method: "PUT",
    headers,
    body: payload.file,
    muteHttpExceptions: true,
  });
  return response;
};

export const postData = (payload) => {
  if (payload.token) {
    const { token } = payload;
    const headers = headerHandler(token);
    return axiosBuilder.post("/inspections/items", payload, { headers });
  } else {
    const headers = headerHandler();
    return axiosBuilder.post("/inspections/items", payload, { headers });
  }
};

export const getJWTTokenForGodfrey = (token) => {
  console.log({token})
  const headers = token ? headerHandler(token) : headerHandler();
  return axiosBuilder.get("/misc/ennoventureToken", { headers });
};
