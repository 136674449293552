import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "../Create/slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";

export function* handleFetchWorkflowData({ payload }) {
  try {
    const res = yield call(API.fetchWorkflowData, payload);
    const { data } = res;
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleFetchSectionData({ payload }) {
  try {
    const res = yield call(API.fetchSectionData, payload);
    const { data } = res;
    yield put(actions.fetchSectionDataSuccess(data.payload[0]));
  } catch (err) {
    //console.log({ err });
    yield put(actions.fetchSectionDataFailure(err?.response?.data));
  }
}

export function* handleFetchBlockData({ payload }) {
  try {
    const res = yield call(API.fetchBlockData, payload);
    const { data } = res;
    yield put(actions.fetchBlockDataSuccess(data.payload[0]));
  } catch (err) {
    yield put(actions.fetchBlockDataFailure(err?.response?.data));
  }
}

export function* handleFetchCompositeData({ payload }) {
  try {
    const res = yield call(API.fetchCompositeData, payload);
    const { data } = res;
    yield put(actions.fetchCompositeDataSuccess(data.payload[0]));
  } catch (err) {
    yield put(actions.fetchCompositeDataFailure(err?.response?.data));
  }
}

export function* handleFetchComponentData({ payload }) {
  try {
    const res = yield call(API.fetchComponentData, payload);
    const { data } = res;
    yield put(actions.fetchComponentDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchComponentDataFailure(err?.response?.data));
  }
}

export function* handlePostWorkflowData({ payload }) {
  try {
    const res = yield call(API.postWorkflowData, payload.data);
    payload.onSuccess();
    //console.log({ res });
    // let data = JSON.parse(res.data);
    // yield put(actions.postWorkflowDataSuccess(data));
  } catch (err) {
    payload.onError();
    //console.log({ err });
    yield put(actions.postWorkflowDataFailure(err?.response?.data));
  }
}

/***
 * Root saga manages watcher lifecycle
 ***/

export function* UpdateWorkflowDataSaga() {
  yield takeLatest(actions.postWorkflowData.type, handlePostWorkflowData);
  yield takeLatest(actions.fetchWorkflowData.type, handleFetchWorkflowData);
  yield takeEvery(actions.fetchSectionData.type, handleFetchSectionData);
  yield takeEvery(actions.fetchBlockData.type, handleFetchBlockData);
  yield takeEvery(actions.fetchCompositeData.type, handleFetchCompositeData);
  yield takeEvery(actions.fetchComponentData.type, handleFetchComponentData);
}
