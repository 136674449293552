import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../../services/apis";
import { downloadPdf, downloadSummarySheet } from "utils/commonFunctions";
//Generator Functions
export function* handleFetchData({ payload }) {
  try {
    const res = yield call(API.getInspection, payload.payload);
    const { data } = res;
    yield put(actions.fetchDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchDataFailure(err?.response?.data));
  }
}

export function* handleFetchAddFilterDropdown({ payload }) {
  try {
    const res = yield call(API.getAddFilterDropdown, payload.payload);
    const { data } = res;
    yield put(actions.fetchAddFilterDropdownSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchAddFilterDropdownFailure(err?.response?.data));
  }
}

export function* handlefetchAllData({ payload }) {
  try {
    const res = yield call(API.getAllItems, payload.payload);
    const { data } = res;
    if(data.success){
      payload.onSuccess(false)
    }
    yield put(actions.fetchAllDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchAllDataFailure(err?.response?.data));
  }
}

export function* handleFetchWarehousesData({ payload }) {
  try {
    const res = yield call(API.getWarehouses, payload);
    const { data } = res;
    yield put(actions.fetchWarehousesSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWarehousesFailure(err?.response?.data));
  }
}

export function* handleViewReport({ payload }) {
  try {
    const { source, fileName } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: fileName,
    });
    const { url } = res.data;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    yield put(actions.viewReportSuccess());
  } catch (err) {
    yield put(actions.viewReportFailure());
  }
}

export function* handleDownloadReport({ payload }) {
  try {
    const { source, inspectionDetails } = payload;
    const res = yield call(API.fetchSASUrl, {
      source,
      blobName: inspectionDetails?.fileName,
    });
    downloadPdf(res.data.url, inspectionDetails);
    yield put(actions.downloadReportSuccess());
  } catch (err) {
    yield put(actions.downloadReportFailure());
  }
}
export function* handleDownloadSummarySheet({ payload }) {
  try {
    const { month, year } = payload;
    // //console.log("Requesting summary sheet for:", month, year);

    const res = yield call(API.fetchSummarySheet, { month, year });

    downloadSummarySheet(res);
  } catch (error) {
    console.error("Error downloading summary sheet:", error);
  }
}
function* sendEmails({ payload }) {
  try {
    const response = yield call(API.sendReport, payload.data);
    yield put(actions.sendEmailSuccess());
    payload.onSuccess();
  } catch (error) {
    yield put(actions.sendEmailFailure(error?.response?.data));
    payload.onFailure(error?.message);
  }
}
export function* handleFetchInspectionsCountData({ payload }) {
  try {
    const res = yield call(API.getInspectionCountData, payload.payload);
    const { data } = res;
    yield put(actions.fetchInspectionsCountDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchInspectionsCountDataFailure(err?.response?.data));
  }
}
// to get the month number inspection was done
export function* handleFetchGeneratedReportMonths() {
  try {
    const res = yield call(API.getBarChartData, { unit: "month" });
    const { data } = res;
    yield put(actions.fetchGeneratedReportMonthsSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchGeneratedReportMonthsFailure(err?.response?.data));
  }
}
export function* handleClearState() {
  console.log("hihi")
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* dashboardSaga() {
  yield takeLatest(actions.fetchData.type, handleFetchData);
  yield takeLatest(actions.fetchWarehouses.type, handleFetchWarehousesData);
  yield takeLatest(actions.viewReport.type, handleViewReport);
  yield takeLatest(actions.sendEmail.type, sendEmails);
  yield takeLatest(actions.downloadReport.type, handleDownloadReport);
  yield takeLatest(actions.fetchAllData.type, handlefetchAllData);
  yield takeLatest(
    actions.fetchInspectionsCountData.type,
    handleFetchInspectionsCountData
  );
  yield takeLatest(
    actions.fetchAddFilterDropdown.type,
    handleFetchAddFilterDropdown
  );
  yield takeLatest(
    actions.downloadSummarySheet.type,
    handleDownloadSummarySheet
  );
  yield takeLatest(
    actions.fetchGeneratedReportMonths.type,
    handleFetchGeneratedReportMonths
  );
  yield takeLatest(actions.clearState.type, handleClearState);
}
