import { call, put, takeLatest, takeEvery, delay } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";
import * as API1 from "services/apis";
import { downloadImage } from "utils/commonFunctions";

export function* handleFetchReviewData({ payload }) {
  try {
    const res = yield call(API1.getAllItems, payload.payload);
    const { data } = res;
    yield put(actions.fetchReviewDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchReviewDataFailure(err?.response?.data));
  }
}
export function* handleFetchWorkflowData({ payload }) {
  try {
    const res = yield call(API.fetchWorkflowData, payload);
    const { data } = res;
    yield delay(100);
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchWorkflowDataFailure(err?.response?.data));
  }
}

export function* handlePostWorkflowData({ payload }) {
  try {
    const res = yield call(API.postWorkflowData, payload.data);
    // let data = JSON.parse(res.data);
    // yield put(actions.postWorkflowDataSuccess(data));
  } catch (err) {
    yield put(actions.postWorkflowDataFailure(err?.response?.data));
  }
}

export function* handleDownloadImage({ payload }) {
  try {
    const { source, imageName } = payload;
    const res = yield call(API1.fetchSASUrl, {
      source,
      blobName: imageName,
    });
    downloadImage(res.data.url, imageName);
    yield put(actions.downloadImageSuccess());
  } catch (err) {
    yield put(actions.downloadImageFailure());
  }
}
export function* handleClearState() {
  try {
    yield put(actions.fetchClearDataSuccess());
  } catch (err) {
    yield put(actions.fetchClearDataFailure());
  }
}
/***
 * Root saga manages watcher lifecycle
 ***/

export function* workflowDataSaga() {
  yield takeEvery(actions.postWorkflowData.type, handlePostWorkflowData);
  yield takeEvery(actions.fetchWorkflowData.type, handleFetchWorkflowData);
  yield takeLatest(actions.fetchReviewData.type, handleFetchReviewData);
  yield takeLatest(actions.downloadImage.type, handleDownloadImage);
  yield takeLatest(actions.clearData.type, handleClearState);
}
