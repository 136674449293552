import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
import * as API from "../../services/apis";

export function* handleFetchUserData({ payload }) {
  try {
    const res = yield call(API.getUserProfile, payload);
    const { data } = res;
    yield put(actions.fetchUserDataSuccess(data.payload));
  } catch (err) {
    yield put(actions.fetchUserDataFailure(err?.response?.data));
  }
}
export function* handleUpdateProfile({ payload }) {
  try {
    const res = yield call(API.updateUserProfile, payload);
    const { data } = res;
    yield put(actions.updateProfileSuccess(data?.payload));
    if (data?.success) {
      payload.onSuccess();
    }
  } catch (err) {
    // //console.log(err);
    yield put(actions.updateProfileFailure(err?.response?.data));
  }
}
export function* handlePasswordReset({ payload }) {
  try {
    const res = yield call(API.userPasswordReset, payload);
    const { data } = res;
    // //console.log(res);
    yield put(actions.passwordResetSuccess(data?.payload?.message));
    if (res?.status === 200) {
      payload.onSuccess("Password Reset Successful.");
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      payload.onSuccess("Current password is incorrect.");
    }
    // else if()
    // //console.log(err?.response?.status);
    yield put(actions.passwordResetFailure(err?.response?.data));
  }
}
export function* handleFetchPreDefinedURL({ payload }) {
  try {
    const res = yield call(API.getPreDefinedURL, payload.payload);
    const { data } = res;
    yield put(actions.getPreDefinedURLSuccess(data?.payload?.url));
    if (res.status === 200) {
      payload.onSuccess();
    }
  } catch (err) {
    yield put(actions.getPreDefinedURLFailure(err?.response?.data));
  }
}
export function* handleUploadFileBlob({ payload }) {
  try {
    const res = yield call(API.uploadProfileImage, payload.payload);
    yield put(actions.uploadFileBlobSuccess(res.url));
    if (res.status === 201) {
      // payload.onSuccess()
    }
  } catch (err) {
    yield put(actions.uploadFileBlobFailure(err?.response?.data));
  }
}
export function* handleClearState() {
  try {
    yield put(actions.clearStateSuccess());
  } catch (err) {
    yield put(actions.clearStateFailure(err));
  }
}
/**
 * Root saga manages watcher lifecycle
 */
export function* userAccountSaga() {
  yield takeLatest(actions.fetchUserData.type, handleFetchUserData);
  yield takeLatest(actions.updateProfile.type, handleUpdateProfile);
  yield takeLatest(actions.passwordReset.type, handlePasswordReset);
  yield takeLatest(actions.getPreDefinedURL, handleFetchPreDefinedURL);
  yield takeLatest(actions.uploadFileBlob, handleUploadFileBlob);
  yield takeLatest(actions.clearState.type, handleClearState);
}
