import React, { useEffect, useState } from "react";
import * as APIs from "../../../../services/index";

export default function EnnoventureComp() {
  const [result, setResult] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [warning, setWarning] = useState("");
  const [qrText, setQrText] = useState("");
  const [vyuInstance, setVyuInstance] = useState(null);

  const showWarning = ({ type, err }) => {
    if (type === "TimedOut") {
      setResult("");
      setWarning(`Session aborted: ${err}`);
    }

    if (type === "Location" || type === "Camera") {
      setWarning(err);
    }
  };

  const showResult = ({ data }) => {
    setResult(`Status: ${data.status}`);
    setSessionId(`Session id: ${data.sessionId}`);
  };

  const handleCaptureClick = async () => {
    if (result === "" && warning === "" && sessionId === "") {
      setResult("Status: Verifying..");
      await vyuInstance.takeSnapshot(({ data }) => {
        showResult({ data });
      });
    } else {
      cleanDisplayText();
      handleCaptureClick();
    }
  };

  const cleanDisplayText = () => {
    setResult("");
    setWarning("");
    setSessionId("");
  };

  const handleCaptureButtonClick = async () => {
    if (vyuInstance) {
      handleCaptureClick();
    }
  };

  const handleScanAgainButtonClick = async () => {
    cleanDisplayText();
    await vyuInstance.reInitiateProductScan(() => {});
  };

  useEffect(() => {
    let vyuInstance;
    const detectWithVyu = async () => {
      const vyuDiv = document.getElementById("vyu");

      const token = localStorage.getItem("token");

      const authToken = token ? JSON.parse(token) : null;

      const jwtTokenData = await APIs.getJWTTokenForGodfrey(authToken);

      vyuInstance = new Vyu(
        vyuDiv,
        {
          apiKey: "ce3a2612-d8f9-43dc-b656-ddb857ca0a7f",
          serverBaseUrl: "https://stage-verification-api.pre.enncrypto.com",
          accessToken: jwtTokenData.data.payload,
        },
        {
          identificationType: "QR",
          errorCallbackFn: ({ type, err }) => {
            showWarning({ type, err });
          },
          onQrScannerLoadCallbackFn: ({}) => {
            setQrText("Scan QR");
          },
          analyticsTools: {
            capture: (eventName, data) => {
              console.log(eventName, data);
            },
          },
        }
      );

      setVyuInstance(vyuInstance);

      vyuInstance.identifyProduct(
        () => {},
        (error) => {
          console.log(error);
        },
        () => {}
      );

      detectWithVyu();
    };

    return () => {
      if (vyuInstance) {
        vyuInstance.destroy();
      }
    };
  }, []);

  return (
    <div>
      <div id="vyu"></div>
      <div id="qr-text" style={{ textAlign: "center", marginTop: "10px" }}>
        {qrText}
      </div>
      <span id="result-display" style={{ display: "block", margin: "10px" }}>
        {result}
      </span>
      <span
        id="warning-display"
        style={{ display: "block", margin: "10px", color: "red" }}
      >
        {warning}
      </span>
      <span id="session-display" style={{ display: "block", margin: "10px" }}>
        {sessionId}
      </span>
      <button
        id="vyu-capture"
        style={{
          display:
            result === "" && warning === "" && sessionId === ""
              ? "block"
              : "none",
          height: "50px",
          width: "100%",
          marginTop: "20px",
        }}
        onClick={handleCaptureButtonClick}
      >
        Capture
      </button>
      <button
        id="vyu-scan-again"
        style={{
          display:
            result !== "" || warning !== "" || sessionId !== ""
              ? "block"
              : "none",
          height: "50px",
          width: "100%",
          marginTop: "10px",
        }}
        onClick={handleScanAgainButtonClick}
      >
        Scan Again
      </button>
    </div>
  );
}
