import React, { useEffect } from "react";
import { Box, Text, Stack, Flex, VStack, Image, Badge } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import IconBox from "components/Icons/IconBox";
import perfeqtLogo from "assets/perfeqt Logoo-02.png";
import drawer from "assets/svg/default.svg";
import Support from "../../assets/svg/MdSupportAgent.svg";
import "./index.css";
import { Separator } from "components/Separator/Separator";
import { useSidebar } from "../../context/SidebarContext";
import { userStorage } from "utils/helper";
import SideBarRoutes from "SidebarRoutes";
import { useDispatch, useSelector } from "react-redux";
import * as myApprovalSelectors from "views/Users/pages/myApprovals/selectors";
import {
  actions as myApprovalsAction,
  sliceKey,
  reducer,
} from "views/Users/pages/myApprovals/slice";
import { myApprovalSaga } from "views/Users/pages/myApprovals/saga";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
const imageStyle = {
  height: "65%",
};

const SidebarContent = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: myApprovalSaga });
  const { sidebarOpen, setIsSidebarOpen } = useSidebar();

  const location = useLocation();
  const dispatch = useDispatch();

  const isButtonActive = (buttonRoute) => {
    return location.pathname === buttonRoute;
  };
  const handleSidebarOpen = () => {
    setIsSidebarOpen(!sidebarOpen);
  };

  const submittedArrayWithoutPages = useSelector(
    myApprovalSelectors.selectSubmittedDataWithoutPages
  );

  const local = localStorage.getItem("inspectionDashboard");
  const loginObject = JSON.parse(local);

  let displayedRoutes = JSON.parse(JSON.stringify(SideBarRoutes));
  const userRoles = userStorage.get()?.roles || [];
  const isManager = userRoles.includes("manager");
  const isClient = userRoles.includes("client");
  const isSuperAdmin = userRoles.includes("super-admin");
  const isAuditor = userRoles.includes("auditor");

  if (isAuditor) {
    displayedRoutes = displayedRoutes.filter(
      (route) => route.name === "Bank Audit"
    );
  } else if (isManager || isClient) {
    displayedRoutes = displayedRoutes.filter((route) => {
      if (isClient && ["Pending Approvals"].includes(route.name)) {
        return;
      } else if (
        !["Workflow", "Roles & Permissions", "Bank Audit"].includes(route.name)
      ) {
        return route;
      }
    });
  } else if (isSuperAdmin) {
    displayedRoutes = displayedRoutes.filter(
      (route) => route.name !== "Bank Audit"
    );
  }
  //Getting number of pending approvals list
  useEffect(() => {
    dispatch(
      myApprovalsAction.fetchSubmittedDataWithoutPages({
        payload: {
          warehouseId: "",
          start_dt: "",
          end_dt: "",
          query: "",
          status: "submitted",
          page: "",
        },
      })
    );
  }, []);
  // //console.log(SideBarRoutes.length, displayedRoutes.length);
  return (
    <>
      <VStack w={"100%"}>
        <Flex className="logo-container">
          <Link to="/admin/dashboard">
            {
              <Image
                src={perfeqtLogo}
                className="agnext-logo"
                alt="logo"
                style={{
                  width: "120px",
                  height: "80px",
                  display: sidebarOpen ? "block" : "none",
                }}
              />
            }
          </Link>

          <Image
            src={drawer}
            className={
              sidebarOpen ? "drawer-logo" : "drawer-logo-closed logo-position"
            }
            alt="logo"
            style={{ width: "90px", cursor: "pointer" }}
            onClick={handleSidebarOpen}
          />
        </Flex>
        <Separator
          width={sidebarOpen ? "110%" : "20%"}
          className={sidebarOpen ? "" : "sidebar-close-seperator"}
        ></Separator>
      </VStack>
      <Stack direction="column" mb="40px" mt={sidebarOpen ? "20px" : "82px"}>
        {displayedRoutes.map((route) => {
          return `${loginObject?.user?.orgName}` === "HDFC" &&
            route.name === "Inspections" ? (
            ""
          ) : (
            <Link to={`${route.layout + route.path}`} key={route.path}>
              <Box
                key={route.path}
                className={sidebarOpen ? "sidebar-content-container" : ""}
                style={{
                  background:
                    isButtonActive(`${route.layout + route.path}`) &&
                    sidebarOpen
                      ? "white"
                      : "",
                  boxShadow:
                    isButtonActive(`${route.layout + route.path}`) &&
                    sidebarOpen
                      ? "0px 7px 11px rgba(0, 0, 0, 0.04)"
                      : "",
                }}
              >
                <Box
                  className={sidebarOpen ? "icon-box-sidebar" : "icon-box"}
                  style={{
                    background:
                      isButtonActive(`${route.layout + route.path}`) &&
                      !sidebarOpen
                        ? "white"
                        : "",
                  }}
                >
                  <IconBox
                    h={"30px"}
                    w={"30px"}
                    bg={
                      isButtonActive(`${route.layout + route.path}`)
                        ? "#4FD1C5"
                        : "white"
                    }
                    my="auto"
                    boxShadow="0px 7px 11px rgba(0, 0, 0, 0.04)"
                  >
                    <Image
                      src={
                        isButtonActive(`${route.layout + route.path}`)
                          ? route.activeIcon
                          : route.inActiveIcon
                      }
                      style={imageStyle}
                      alt={route.name + " Logo"}
                    />
                  </IconBox>
                </Box>
                <Text
                  className={sidebarOpen ? "sidebar-content" : "sidebar-close"}
                  position={"relative"}
                >
                  {route.name === "Pending Approvals" ? (
                    <>
                      {route.name}
                      {submittedArrayWithoutPages ? (
                        <Badge
                          px="2px"
                          bg={"#718096"}
                          position={"absolute"}
                          color={"#FFFFFF"}
                          lineHeight={"16px"}
                          borderRadius={"10px"}
                          fontSize={"9px"}
                          textAlign={"center"}
                          w={"20px"}
                          left={sidebarOpen ? "-22px" : "46px"}
                          bottom={sidebarOpen ? "12px" : "25px"}
                        >
                          {submittedArrayWithoutPages?.length}
                        </Badge>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    route.name
                  )}
                </Text>
              </Box>
            </Link>
          );
        })}
      </Stack>
    </>
  );
};

export default SidebarContent;
