import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, sliceKey, reducer } from "../Create/slice";
import { WorkflowsSaga } from "./saga";
import * as selectors from "./selectors";
import { Table, Button } from "@agnext/reactlib";
import { Box, HStack } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import WorkflowAction from "../../Components/WorkflowAction";

const headerList = [
  {
    displayText: "ID",
    keyText: "id",
  },
  {
    displayText: "Title",
    keyText: "title",
    hasTooltip: true,
  },
  {
    displayText: "Description",
    keyText: "description",
    hasTooltip: true,
  },
  {
    displayText: "Application",
    keyText: "application",
    hasTooltip: true,
  },
  { keyText: "client", displayText: "Client", hasTooltip: true },
  { keyText: "actions", displayText: "Actions" },
];

export default function Workflows() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: WorkflowsSaga });

  const dispatch = useDispatch();

  const workflows = useSelector(selectors.selectWorkflows) || [];

  const dataList = [...workflows].map((workflow) => {
    return {
      ...workflow,
      actions: <WorkflowAction id={workflow.id} />,
    };
  });

  useEffect(() => {
    dispatch(actions.fetchWorkflows());
  }, [dispatch]);
  return (
    <>
      {/* <HStack
        w="100%"
        h="60px"
        style={{ border: "1px solid #EFEFEF" }}
      ></HStack> */}
      <HStack w="100%" gap="0px" alignItems="flex-start">
        {/* <Box
          w="240px"
          h="calc(100vh - 60px)"
          style={{ border: "1px solid #EFEFEF" }}
        ></Box> */}
        <Box w="100% " mt="75px" minH="calc(100vh - 75px)">
          <HStack w="100%" justify="flex-end" style={{ marginBottom: "32px" }}>
            <Link to="/admin/workflow/create">
              <Button
                size="sm"
                colorScheme="login"
                leftIcon={<AddIcon />}
                label="New Workflow"
              />
            </Link>
          </HStack>
          <Box
            borderRadius={"15px"}
            backgroundColor={"#fff"}
            padding="16px 24px"
          >
            <Table headerList={headerList} dataList={dataList} />
          </Box>
        </Box>
      </HStack>
    </>
  );
}
