import React from "react";
import { HStack, Input, Box } from "@chakra-ui/react";
import { IN } from "country-flag-icons/react/3x2";

export default function Mobile({ data, formData, handleFormDataChange }) {
  return (
    <HStack
      w="100%"
      h="48px"
      style={{ border: "1px solid #E2E8F0", borderRadius: "8px" }}
    >
      <HStack w="48px" h="48px" justify="center" align="center">
        <Box w="24px">
          <IN title="India" />
        </Box>
      </HStack>
      <Input
        type="number"
        variant="unstyled"
        placeholder={data.element.placeholder}
        required={data.element.mandatory}
        color="#2D3748"
        _placeholder={{ color: "#A0AEC0" }}
        name={data.element.id}
        value={formData[data.element.id].value}
        onChange={handleFormDataChange}
      />
    </HStack>
  );
}
