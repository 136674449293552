import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function Delete({ deleteHandler }) {
  return (
    <>
      <Button
        leftIcon={<DeleteIcon color="main.icon" />}
        colorScheme="login"
        variant="ghost"
        onClick={deleteHandler}
      />
    </>
  );
}

export default Delete;
