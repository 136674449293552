import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import MobileAuthLayout from "layouts/MobileAuth";
import { configureAppStore } from "./store/configureStore";
import { SidebarProvider } from "./context/SidebarContext";
import { WarehouseFilterProvider } from "./views/Dashboard/context/WarehouseFilterContext";
import { AddFilterProvider } from "./views/Dashboard/context/AddFilterContext";
import { userStorage } from "utils/helper";
import { ReviewAddFilterProvider } from "views/Users/pages/myApprovals/context/ReviewAddFilterContext";

const store = configureAppStore();

//console.log(userStorage.get()?.roles[0] === "auditor");
const App = () => (
  <Provider store={store}>
    <AddFilterProvider>
      <ReviewAddFilterProvider>
        <WarehouseFilterProvider>
          <SidebarProvider>
            <BrowserRouter>
              <Switch>
                <Route path={`/auth`} component={AuthLayout} />
                <Route path={`/admin`} component={AdminLayout} />
                <Route path={`/mobile/auth`} component={MobileAuthLayout} />
                <Redirect
                  from={`*`}
                  to={
                    userStorage.get()?.roles[0] === "auditor"
                      ? "/admin/view-form"
                      : "/admin/dashboard"
                  }
                />
              </Switch>
            </BrowserRouter>
          </SidebarProvider>
        </WarehouseFilterProvider>
      </ReviewAddFilterProvider>
    </AddFilterProvider>
  </Provider>
);

const root = createRoot(document.getElementById("root"));

root.render(<App />);
