// import
import SignIn from "views/Auth/SignIn.js";
import activeDashboardSVG from "assets/svg/MdDashboard.svg";
import dashboardSVG from "assets/svg/MdDashboard-teal.svg";
import activeInspectionSVG from "assets/svg/HiDocumentSearch.svg";
import inspectionSVG from "assets/svg/HiDocumentSearch-teal.svg";
import activeApprovalSVG from "assets/svg/MdOutlineApproval.svg";
import approvalSVG from "assets/svg/MdOutlineApproval-teal.svg";

import {
  HomeIcon,
  StatsIcon,
  // CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  // SupportIcon,
} from "components/Icons/Icons";

import Dashboard from "views/Dashboard/Inspection Table";
import Notifications from "views/Notification";
import ForgotPassword from "views/Auth/ForgotPassword/ForgotPassword";
import SendOTP from "views/Auth/SendOTP/SendOTP";
import SetNewPassword from "views/Auth/SetNewPassword/SetNewPassword";
import PasswordResetSuccessful from "views/Auth/PasswordResetSuccessful/PasswordResetSuccessful";
import Account from "views/Accounts";
import AnalyticsDashboard from "views/Dashboard/Analytics Dashboard";
import GenerateWorkflow from "views/Workflow/pages/generateWorkflow";
import { WorkflowSubmitted } from "views/Workflow/pages/generateTemplate/WorkflowSubmitted";
import { MobileWorkflowSubmitted } from "views/Workflow/pages/generateTemplate/WorkflowSubmitted";
import GenerateMobileWorkflow from "views/Workflow/pages/generateMobileWorkflow";
import { Inspections } from "views/Users/pages/myApprovals";
import ReviewWorkflow from "views/Workflow/pages/reviewWorkflow";
import Warehouses from "views/Dashboard/Warehouses/pages/Warehouses/Warehouses";
import CmTable from "views/Dashboard/Collateral Manager/Pages/Collateral Manager Table/CmTable";
import CreateRole from "views/RoleAndPermission/CreateRole";
import EditRole from "views/RoleAndPermission/EditRole";
import ViewRole from "views/RoleAndPermission/ViewRole";
import UserRole from "views/RoleAndPermission/UserRole";
import Create from "views/Workflow Builder/pages/Create";
import Update from "views/Workflow Builder/pages/Update";
import Workflows from "views/Workflow Builder/pages/Workflows";
import View from "views/Workflow Builder/pages/View";
import SummaryNCCF from "views/Dashboard/SummaryNCCF";
// import EnnoventureComp from "views/Workflow/components/ennoventureComp";

var dashRoutes = [
  // {
  //   path: "/dashboard/summary",
  //   name: "Summary",
  //   component: SummaryNCCF,
  //   layout: "/admin",
  //   activeIcon: activeDashboardSVG,
  //   inActiveIcon: dashboardSVG,
  // },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: AnalyticsDashboard,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/inspections",
    name: "Inspections",
    component: Dashboard,
    layout: "/admin",
    activeIcon: activeInspectionSVG,
    inActiveIcon: inspectionSVG,
  },
  {
    path: "/notifications",
    name: "Notifications",
    icon: <HomeIcon color="inherit" />,
    component: Notifications,
    layout: "/admin",
  },
  {
    path: "/account",
    name: "Account",
    icon: <HomeIcon color="inherit" />,
    component: Account,
    layout: "/admin",
  },
  {
    path: "/view-form",
    name: "Bank Audit",
    component: GenerateWorkflow,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/form-submitted",
    name: "Bank Audit ",
    component: WorkflowSubmitted,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/form-view/:id",
    name: "Mobile Form View",
    component: GenerateMobileWorkflow,
    layout: "/mobile/auth",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/form-submitted/:id",
    name: "Bank Audit",
    component: MobileWorkflowSubmitted,
    layout: "/mobile/auth",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/warehouse",
    name: "Warehouses",
    component: Warehouses,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/cm-manager",
    name: "Collateral Managers",
    component: CmTable,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/my-approvals/inspections",
    name: "Pending Approvals",
    component: Inspections,
    layout: "/admin",
    activeIcon: activeApprovalSVG,
    inActiveIcon: approvalSVG,
  },
  {
    path: "/my-approval/inspections/reviewing/:inspectionId",
    name: "Review Form",
    component: ReviewWorkflow,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/user-roles/create-role",
    name: "Create Role",
    component: CreateRole,
    layout: "/admin",
  },
  {
    path: "/user-roles/edit-role/:roleId",
    name: "Edit Role",
    component: EditRole,
    layout: "/admin",
  },
  {
    path: "/user-roles/view-role/:roleId",
    name: "View Role",
    component: ViewRole,
    layout: "/admin",
  },

  {
    path: "/user-roles",
    name: "User Roles",
    component: UserRole,
    layout: "/admin",
  },
  {
    path: "/workflow/create",
    name: "Create Workflow",
    component: Create,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/update/:workflowId",
    name: "Update Workflow",
    component: Update,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/workflows",
    name: "Workflows",
    component: Workflows,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/view/:workflowId",
    name: "View Workflow",
    component: View,
    layout: "/admin",
    activeIcon: activeDashboardSVG,
    inActiveIcon: dashboardSVG,
  },
  {
    path: "/signin",
    name: "Sign In",
    component: SignIn,
    layout: "/auth",
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/send-otp",
    name: "Send OTP",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SendOTP,
    layout: "/auth",
  },
  {
    path: "/new-password",
    name: "New Password",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: SetNewPassword,
    layout: "/auth",
  },
  {
    path: "/password-reset-successful",
    name: "Password Reset Successful",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: PasswordResetSuccessful,
    layout: "/auth",
  },
  // {
  //   path: "/signup",
  //   name: "Sign Up",
  // rtlName: "لوحة القيادة",
  //   icon: <RocketIcon color="inherit" />,
  //   secondaryNavbar: true,
  //   component: SignUp,
  //   layout: "/auth",
  // },
  //   ],
  // },
];
export default dashRoutes;
