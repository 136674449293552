import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import FiUser from "assets/svg/FiUser.svg";
import MdDate from "assets/svg/MdDateRange.svg";
import Edit2 from "assets/svg/edit2.svg";
import { VerticalSeparator } from "components/Separator/VerticalSeperator";
import { Link } from "react-router-dom";

const ViewRoleTopCard = ({ roleDetails, createdBy, updatedBy }) => {
  const creationTimeStamp = new Date(roleDetails?.creationDt);
  const updationTimeStamp = new Date(roleDetails?.updateDt);

  // Extracting day, month, and year
  const creationDay = creationTimeStamp.getUTCDate();
  const creationMonth = creationTimeStamp.toLocaleString("default", {
    month: "long",
  });
  const creationYear = creationTimeStamp.getUTCFullYear();

  const updationDay = updatedBy && updationTimeStamp.getUTCDate();
  const updationMonth =
    updatedBy &&
    creationTimeStamp.toLocaleString("default", {
      month: "long",
    });
  const updationYear = updatedBy && creationTimeStamp.getUTCFullYear();

  // Creating the new date string in the desired format
  const creationDate = `${creationDay} ${creationMonth} ${creationYear}`;
  const updationDate =
    updatedBy && `${updationDay} ${updationMonth} ${updationYear}`;
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: "white",
        padding: "24px",
        borderRadius: "15px",
      }}
    >
      <Box>
        <VStack justify={"flex-start"} align={"flex-start"} gap="16px">
          <Flex>
            <Text style={{ fontWeight: "700", textTransform: "capitalize" }}>
              Role: {roleDetails?.roleName}
            </Text>
            <Flex>
              <Box
                style={{
                  padding: "4.5px 8.5px",
                }}
              >
                <Box
                  style={{
                    width: "7px",
                    height: "7px",
                    background: "#4FD1C5",
                    borderRadius: "50%",
                    marginTop: "4px",
                  }}
                />
              </Box>

              <Text
                style={{
                  color: "#718096",
                  fontSize: "14px",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  padding: "2px",
                  textTransform: "capitalize",
                }}
              >
                {roleDetails?.status}
              </Text>
            </Flex>
          </Flex>
          <Text
            style={{
              color: "#718096",
              fontSize: "16px",
              fontWeight: "400",
              wordWrap: "break-word",
              padding: "1px",
            }}
          >
            {roleDetails?.description}
          </Text>
          <Stack
            direction={{ base: "column", md: "row" }}
            align="flex-start"
            spacing={{ base: 2, md: "16px" }}
          >
            <Stack direction="row" align="center" height="100%">
              <Box>
                <Image src={FiUser} alt="Dan Abramov" />
              </Box>
              <Text
                style={{
                  color: "#718096",
                  fontSize: "16px",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  padding: "1px",
                }}
              >
                Created By:
              </Text>

              <Button
                colorScheme="white"
                color="black"
                size="xs"
                borderColor="#718096"
                borderWidth={1}
                borderRadius={3}
              >
                {createdBy?.data?.payload?.firstName
                  ? createdBy?.data?.payload?.firstName
                  : "Admin"}
              </Button>
            </Stack>

            <Stack direction="row" align="center" height="100%">
              <Box>
                <Image src={MdDate} alt="Dan Abramov" />
              </Box>
              <Text
                style={{
                  color: "#718096",
                  fontSize: "16px",
                  fontWeight: "400",
                  wordWrap: "break-word",
                  padding: "1px",
                }}
              >
                Created By Date:
              </Text>
              <Text
                style={{
                  color: "#718096",
                  fontSize: "16px",
                  fontWeight: "600",
                  wordWrap: "break-word",
                  padding: "1px",
                }}
              >
                {creationDate}
              </Text>
            </Stack>

            {/* <VerticalSeparator /> */}
            {updatedBy && (
              <>
                <Stack direction="row" align="center" height="100%">
                  <Box>
                    <Image src={FiUser} alt="Dan Abramov" />
                  </Box>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    Modified By:
                  </Text>

                  <Button
                    colorScheme="white"
                    color="black"
                    size="xs"
                    borderColor="#718096"
                    borderWidth={1}
                    borderRadius={3}
                  >
                    {updatedBy?.data?.payload?.firstName}
                  </Button>
                </Stack>{" "}
                <Stack direction="row" align="center" height="100%">
                  <Box>
                    <Image src={MdDate} alt="Dan Abramov" />
                  </Box>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "400",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    Last Modified Date:
                  </Text>
                  <Text
                    style={{
                      color: "#718096",
                      fontSize: "16px",
                      fontWeight: "600",
                      wordWrap: "break-word",
                      padding: "1px",
                    }}
                  >
                    {updationDate}
                  </Text>
                </Stack>
              </>
            )}
          </Stack>
        </VStack>
      </Box>
      <Link to={`/admin/user-roles/edit-role/${roleDetails?.id}`}>
        <Box
          background={"teal.400"}
          height={"40px"}
          width={"48px"}
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRadius={"12px"}
        >
          <Image src={Edit2} alt="" />
        </Box>
      </Link>
    </Box>
  );
};

export default ViewRoleTopCard;
