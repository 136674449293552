import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

export function generateUUId() {
  return uuidv4();
}

export function convertToCamelCase(str) {
  return _.camelCase(str);
}

export function getDataForFormSubmission({ formData, components }) {}
