import React from "react";
import styles from "./styles.module.css";
import { Stack, HStack, VStack } from "@chakra-ui/react";
import { ArrowDownIcon, AddIcon } from "@chakra-ui/icons";
import { Text } from "@chakra-ui/react";
import { Button } from "@agnext/reactlib";

function MoveDown({ moveDownHandler, isDisabled }) {
  return (
    <>
      <Button
        leftIcon={
          <ArrowDownIcon
            color={isDisabled ? "main.iconDisabled" : "main.icon"}
          />
        }
        colorScheme="login"
        variant="ghost"
        onClick={moveDownHandler}
        // label="Move Down"
      />
    </>
  );
}

export default MoveDown;
