import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";

export function* handlePostWorkflowData({ payload }) {
  try {
    const res = yield call(API.postWorkflowData, payload.data);
    payload.onSuccess();
    let data = JSON.parse(res.data);
    yield put(actions.postWorkflowDataSuccess(data));
  } catch (err) {
    payload.onError();
    yield put(actions.postWorkflowDataFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* postWorkflowDataSaga() {
  yield takeLatest(actions.postWorkflowData.type, handlePostWorkflowData);
}
