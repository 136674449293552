import { call, put, takeLatest } from "redux-saga/effects";
import { actions } from "../Create/slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";

export function* handleFetchWorkflows() {
  try {
    //console.log("saga was hit");
    const res = yield call(API.fetchWorkflows);
    //console.log("res", res);
    const data = res.data.payload;
    yield put(actions.fetchWorkflowsSuccess(data));
  } catch (err) {
    //console.log(err);
    yield put(actions.fetchWorkflowsFailure(err?.response?.data));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* WorkflowsSaga() {
  yield takeLatest(actions.fetchWorkflows.type, handleFetchWorkflows);
}
