import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  error: null,
  workflow: [],
  sections: [],
  blocks: [],
  composites: [],
  components: [],
  reviewData: [],
};

const homePage = createSlice({
  name: "homePage",
  initialState,
  reducers: {
    // Fetch Data
    fetchWorkflowData: (state, action) => {
      state.loading = true;
      // state.reviewData = [...state.reviewData];
    },
    fetchWorkflowDataSuccess: (state, action) => {
      state.loading = false;
      // state.reviewData = [...state.reviewData];
      state.workflow = [JSON.parse(JSON.stringify(action.payload.form))];
      state.sections = action.payload.sections;
      state.blocks = action.payload.blocks;
      state.composites = action.payload.composites;
      state.components = action.payload.components;
    },
    fetchWorkflowDataFailure: (state, action) => {
      state.loading = false;
    },

    // post
    postWorkflowData: (state, action) => {
      state.loading = true;
    },

    postWorkflowDataSuccess: (state, action) => {
      state.loading = false;
    },

    postWorkflowDataFailure: (state, action) => {
      state.loading = false;
      // state.error = action.payload;
    },
    fetchReviewData: (state, action) => {
      state.loading = true;
    },
    fetchReviewDataSuccess: (state, action) => {
      state.loading = false;
      state.reviewData = action.payload;
    },
    fetchReviewDataFailure: (state, action) => {
      state.loading = false;
    },
    fetchSingleInspection: (state, action) => {
      state.loading = true;
    },
    fetchSingleInspectionSuccess: (state, action) => {
      state.loading = false;
      state.inspectionDetail = action.payload;
    },
    fetchSingleInspectionFailure: (state, action) => {
      state.loading = false;
    }
  },
});

export const { actions, reducer, name: sliceKey } = homePage;
