import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { actions } from "./slice";
//import * as API from "../../../../../services/apis";
import * as API from "services/index";
import * as API1 from "services/apis";
export function* handleFetchWorkflowData({ payload }) {
  try {
    const res = yield call(API.fetchWorkflowData, payload);
    const { data } = res;
    //console.log("rerererer", { data: data.payload });
    yield put(actions.fetchWorkflowDataSuccess(data.payload));
  } catch (err) {
    //console.log("rerererer error", err);
    yield put(actions.fetchWorkflowDataFailure(err?.response?.data));
  }
}

/***
 * Root saga manages watcher lifecycle
 ***/

export function* workflowDataSaga() {
  yield takeEvery(actions.fetchWorkflowData.type, handleFetchWorkflowData);
}
