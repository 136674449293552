import { Tooltip } from "@chakra-ui/react";
const { format } = require("date-fns");
import {
  differenceInMinutes,
  differenceInSeconds,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
  parseISO,
  differenceInCalendarDays,
  parse,
  getISOWeek,
  addDays,
  getYear,
  addWeeks,
} from "date-fns";

export const truncateWords = (text) => {
  const words = text.split(" ");
  if (words.length <= 2) {
    return text;
  }
  const truncated = words.slice(0, 2).join(" ");
  return `${truncated}...`;
};

export const formatNumber = (number, title) => {
  number = Number(number);
  if (number !== undefined) {
    const roundedNumber = Math.round(number);
    if (roundedNumber >= 999) {
      const formatted = roundedNumber.toString();
      const firstThreeDigits = formatted.slice(0, -3);
      return (
        <Tooltip label={number} aria-label="number-tooltip">
          <span>{`${firstThreeDigits}K`}</span>
        </Tooltip>
      );
    }
    return title.includes("MT") ? number.toFixed(2) : number;
  }
};

export const downloadPdf = async (url, inspection) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const anchor = document.createElement("a");
  anchor.href = URL.createObjectURL(blob);
  anchor.download =
    "Inspection Report " +
    inspection.warehouseCode +
    " " +
    handleAuditDate(inspection.auditDate) +
    ".pdf";
  anchor.click();
};

export const downloadImage = async (url, imageName) => {
  // //console.log(url);
  // //console.log(imageName);
  const response = await fetch(url);
  const blob = await response.blob();
  const anchor = document.createElement("a");
  anchor.href = URL.createObjectURL(blob);
  anchor.download = imageName;
  anchor.click();
};

export const downloadSummarySheet = (response) => {
  const blob = new Blob([response.data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const anchor = document.createElement("a");
  anchor.href = URL.createObjectURL(blob);
  anchor.download = "Inspection Report.xlsx";
  anchor.click();
};

const handleAuditDate = (date) => {
  const inputDate = new Date(date);
  const formattedDate = format(inputDate, "dd-MM-yyyy");
  return formattedDate;
};

// export const getUniqueObjectsByProperty = (arr, property) => {
//   // if (arr[property] != null)
//   return arr?.reduce((uniqueObjects, currentObject) => {
//     const exists = uniqueObjects.some(
//       (obj) =>
//         obj[property]?.trim().toLowerCase() ===
//         currentObject[property]?.trim()?.toLowerCase()
//     );
//     if (!exists) {
//       uniqueObjects.push(currentObject);
//     }
//     return uniqueObjects;
//   }, []);
// };

export const getUniqueObjectsByProperty = (arr, property) => {
  return arr?.reduce((uniqueObjects, currentObject) => {
    const currentPropertyValue = currentObject[property];

    if (currentPropertyValue !== undefined && currentPropertyValue !== null) {
      const exists = uniqueObjects.some(
        (obj) =>
          obj[property] !== undefined &&
          obj[property] !== null &&
          obj[property]?.trim()?.toLowerCase() ===
            currentPropertyValue?.trim()?.toLowerCase()
      );

      if (!exists) {
        uniqueObjects.push(currentObject);
      }
    }

    return uniqueObjects;
  }, []);
};

export function formatTimeDifference(responseDate) {
  const responseDateTime = parseISO(responseDate);
  const currentDate = new Date();
  const timeDifferences = [
    { unit: "year", diff: differenceInYears(currentDate, responseDateTime) },
    { unit: "month", diff: differenceInMonths(currentDate, responseDateTime) },
    { unit: "day", diff: differenceInDays(currentDate, responseDateTime) },
    { unit: "hour", diff: differenceInHours(currentDate, responseDateTime) },
    {
      unit: "minute",
      diff: differenceInMinutes(currentDate, responseDateTime),
    },
    {
      unit: "second",
      diff: differenceInSeconds(currentDate, responseDateTime),
    },
  ];

  for (const { unit, diff } of timeDifferences) {
    if (diff > 0) {
      return `${diff} ${unit}${diff !== 1 ? "s" : ""}`;
    }
  }

  return "just now";
}

export const formatNumberUnit = (inputNumber) => {
  inputNumber = Number(inputNumber);

  if (inputNumber >= 10000000) {
    return (inputNumber / 10000000)?.toFixed(2) + "Cr";
  } else if (inputNumber >= 100000) {
    return (inputNumber / 100000)?.toFixed(2) + "L";
  } else {
    return inputNumber.toFixed(2) + "Rs";
  }
};

export const getDateDifferenceInfo = (fromDate, toDate) => {
  const daysDiff = differenceInCalendarDays(toDate, fromDate);
  if (fromDate && toDate) {
    if (daysDiff <= 20) {
      return "day";
    } else if (daysDiff > 20 && daysDiff <= 90) {
      return "week";
    }
  }
  return "month";
};

export const convertMonthNumberToName = (monthNumber, year) => {
  if (monthNumber && year) {
    const date = parse(`${monthNumber}/${year}`, "M/yyyy", new Date());
    return format(date, "MMM");
  }
};

export const getMonthForWeek = (week, year) => {
  const januaryFirst = new Date(year, 0, 1);
  const daysToWeekStart = (week - 1) * 7;
  const weekStartDate = new Date(januaryFirst);
  weekStartDate.setDate(januaryFirst.getDate() + daysToWeekStart);
  const month = weekStartDate.getMonth() + 1;
  return month;
};

export const getMonthArray = (lineChartData, unit) => {
  let monthNumberArray = [];
  if (lineChartData.length > 0) {
    if (unit === "day") {
      lineChartData = lineChartData.flat();
      return formatDate(lineChartData, "audtiDate");
    }
    for (const array of lineChartData) {
      const firstTimestamp = array[0]["auditDate"].split("-")[1];
      const year = array[0]["auditDate"].split("-")[0];
      monthNumberArray.push({ monthNumber: firstTimestamp, year });
    }
  }
  monthNumberArray = monthNumberArray.map((obj) => {
    return convertMonthNumberToName(obj.monthNumber, obj.year);
  });
  return monthNumberArray;
};

//Day /Week / Month wise summed array according to unit
export const getDataArray = (lineChartData, unit, key) => {
  let summedArray = [];
  if (lineChartData.length > 0) {
    if (unit === "day") {
      lineChartData = lineChartData.flat();
      lineChartData.forEach((obj) => {
        let key = Object.keys(obj)[1];
        summedArray.push(obj[`${key}`]);
      });
      return summedArray;
    } else if (unit === "week") {
      let arrayObj = weekWiseArray(lineChartData, key);
      return parseDataArray(arrayObj, key);
    }
    for (const array of lineChartData) {
      let keys = Object.keys(array[0])[1];
      const sum = array.reduce(
        (total, item) => Number(total) + Number(item[keys]),
        0
      );
      summedArray.push(sum);
    }
  }

  return summedArray;
};

export const getTotalSum = (arr, check) => {
  if (arr) {
    let sum = arr?.reduce((a, b) => Number(a) + Number(b), 0);
    return check.includes("Inspection") ? sum : sum.toFixed(2);
  }
};

export const summedData = (data) => {
  return data?.reduce(
    (sum, array) => {
      array.forEach((obj) => {
        sum.totalNoOfBags += obj.totalNoOfBags;
      });
      return sum;
    },
    {
      totalNoOfBags: 0,
    }
  );
};
export const getPercentage = (data, sum, unit, key, check) => {
  if (data.length > 0) {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");
    let flattenedArr = data?.flat();
    if (sum === 0 || sum === undefined) {
      return (
        0 +
        (check.includes("Portfolio")
          ? "%"
          : check.includes("Quantity")
          ? " MT"
          : " counts") +
        " vs last " +
        unit
      );
      // return 0 + "% vs last " + unit;
    }
    if (unit === "day" || unit === "week") {
      if (
        formattedDate !== flattenedArr[flattenedArr.length - 1]["auditDate"]
      ) {
        return (
          0 +
          (check.includes("Portfolio")
            ? "%"
            : check.includes("Quantity")
            ? " MT"
            : " counts") +
          " vs last day"
        );
      } else {
        if (check.includes("Portfolio")) {
          let result =
            (flattenedArr[flattenedArr.length - 1][key] /
              (sum - flattenedArr[flattenedArr.length - 1][key])) *
            100;
          return (
            (hasDecimalPlace(result) ? result.toFixed(2) : result) +
            "% vs last day"
          );
        }
        if (check.includes("Sites") || check.includes("Commodity")) {
          let result = flattenedArr[flattenedArr.length - 1][key].length;
          return (
            (hasDecimalPlace(result) ? result.toFixed(2) : result) +
            " counts vs last day"
          );
        }
        let result = flattenedArr[flattenedArr.length - 1][key];
        return (
          result +
          (check.includes("Quantity") ? " MT" : " counts") +
          " vs last day"
        );
      }
    }
    if (check.includes("Sites") || check.includes("Commodity")) {
      let monthArray = getUniqueId(data, key);
      let lastMonthIdArr = [
        ...new Set(flattenedArr[flattenedArr.length - 1][key]),
      ];
      let lastMonthCount = lastMonthIdArr.filter(
        (item) => !monthArray.includes(item)
      );
      return lastMonthCount.length === 0
        ? "0% vs last month"
        : lastMonthCount.length + " % vs last month";
    }
    let monthwiseArray = getDataArray(data, "month");
    // if (check.includes("Sites") || check.includes("Commodity")) {
    //   let monthwiseArray =
    // }
    if (monthwiseArray[monthwiseArray.length - 1] < sum) {
      return " 0 % vs last month";
    }
    let result =
      (monthwiseArray[monthwiseArray.length - 1] /
        (sum - monthwiseArray[monthwiseArray.length - 1])) *
      100;
    return (
      (hasDecimalPlace(result) ? result.toFixed(2) : result) + "% vs last month"
    );
  } else {
    if (sum === 0 || sum === undefined) {
      return (
        0 +
        (check.includes("Portfolio")
          ? "%"
          : check.includes("Quantity")
          ? " MT"
          : " counts") +
        " vs last " +
        unit
      );
    }
  }
};
function hasDecimalPlace(number) {
  return number !== Math.floor(number);
}
//All month unique id array except last month
const getUniqueId = (data, key) => {
  const result = [];
  data = data?.flat();
  data?.forEach((obj, index) => {
    if (obj[key] && index !== data.length - 1) {
      result.push(...obj[key]);
    }
  });
  const uniqueResult = [...new Set(result)];

  return uniqueResult;
};
//Nested array Id count
export const uniqueIdsArray = (data, key) => {
  const result = [];
  data = data?.flat();
  data?.forEach((obj) => {
    if (obj[key]) {
      result.push(...obj[key]);
    }
  });
  const uniqueResult = [...new Set(result)];

  return uniqueResult.length;
};

//Calculate Default from date for range picker
export const getFromDate = () => {
  var today = new Date();
  var daysAgo = new Date(today);
  daysAgo.setDate(today.getDate() - 90);
  return daysAgo;
};

export const arraySum = (arr) => {
  if (arr?.length > 0) {
    const sum = arr.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sum;
  }
};
export const parseDataArray = (chartData, key) => {
  if (chartData?.length > 0) {
    chartData = chartData.map((item) => {
      if (item[key] !== 0) {
        return parseInt(item[key]);
      }
      return item[key];
    });
    return chartData;
  }
};
export const weekWiseArray = (lineChartData, key) => {
  lineChartData = lineChartData.flat();
  const dataWithWeekNumbers = lineChartData.reduce((result, item) => {
    const date = parse(item.auditDate, "yyyy-MM-dd", new Date());
    const weekNumber = getISOWeek(date);

    if (result[weekNumber]) {
      result[weekNumber][key] += item[key];
    } else {
      result[weekNumber] = {
        weekNumber,
        [key]: item[key],
        monthName: format(date, "MMMM"),
        year: getYear(date),
      };
    }

    return result;
  }, {});
  return Object.values(dataWithWeekNumbers);
};
export const formattedDates = (data, key) => {
  data = data.map((item) => {
    const date = parseISO(item[key]);
    const adjustedDate = addDays(date, -1); // Substract -1 day to adjust to Sunday
    return format(adjustedDate, "do MMM");
  });
  return data;
};
//use timezoned timestamp
export const weekStartingDates = (chartData) => {
  let data = chartData?.map((item) => {
    const startDate = getStartDateOfWeek(item.year, item.weekNumber);
    return format(startDate, "do MMMM");
  });
  return data;
};
const getStartDateOfWeek = (year, weekNumber) => {
  const firstDayOfYear = new Date(year, 0, 1);
  const startDate = addWeeks(firstDayOfYear, weekNumber - 1);
  return startDate;
};
//use formatted date 07-01-1995
const formatDate = (data) => {
  data = data.map((item) => {
    const date = new Date(item.auditDate);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });

    // Function to get the day suffix (e.g., 1st, 2nd, 3rd)
    const getDaySuffix = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDate = `${getDaySuffix(day)} ${month}`;
    return formattedDate;
  });
  return data;
};

//Result array become Thead fot table
export function transformArray(inputArray) {
  const resultArray = [];
  // const inputArrCopy = JSON.parse(JSON.stringify(inputArray)).map(
  //   (item) => item?.itemType !== "multiFileUpload"
  // );
  inputArray?.forEach((obj) => {
    const existingItem = resultArray.find((item) => item.value === obj.title);

    if (!existingItem) {
      resultArray.push({
        display: obj.title,
        value: obj.title,
        visible: true,
      });
    }
  });
  return resultArray.filter(
    (item) => item.display.toLowerCase() !== "powder formation"
  );
}

//Grouping inspection items  belonging to same inspection Id
export function transformArrayOfArrayOfObjects(inputArray, data) {
  let arrayOfObj = JSON.parse(JSON.stringify(inputArray));
  const dataMap = new Map();
  const inspectionMap = new Map();
  for (const item of arrayOfObj) {
    const id = item.inspectionId;
    if (!inspectionMap.has(id)) {
      inspectionMap.set(id, {});
    }
    if (
      item.itemType === "datepicker" &&
      item.value !== null &&
      item.value !== undefined &&
      item.value.trim() !== ""
    ) {
      // //console.log("hello", item.value.slice(0, 10).length);
      item.value = item.value.slice(0, 10);
    }

    inspectionMap.get(id)[item.title] =
      item?.value?.trim() ||
      item?.attachment?.trim() ||
      item?.attachments?.join(", ") ||
      "N.A.";
  }

  for (const item of data) {
    const id = item.id;
    if (!dataMap.has(id)) {
      dataMap.set(id, { ...item });
    }
  }
  let mergedMap = mergeMaps(dataMap, inspectionMap);
  return Array.from(mergedMap.values());
}

function mergeMaps(targetMap, sourceMap) {
  for (const [key, value] of sourceMap) {
    if (targetMap.has(key)) {
      // If the key exists in both maps, merge the values
      const existingValue = targetMap.get(key);
      const mergedValue = { ...existingValue, ...value };
      targetMap.set(key, mergedValue);
    }
  }
  return targetMap;
}
export function mergeArraysByCommonId(array1, array2) {
  const mergedArray = [];

  // Create a map for quick lookup based on the common identifier
  const map = new Map();

  // Populate the map with the items from the first array
  for (const item of array1) {
    const id = item.id; // Replace 'id' with your common identifier property
    map.set(id, { ...item }); // Copy the item to avoid modifying the original objects
  }

  // Merge the items from the second array into the map
  for (const item of array2) {
    const id = item.id; // Replace 'id' with your common identifier property

    if (map.has(id)) {
      // If the item with the same id exists in the map, merge the properties
      Object.assign(map.get(id), item);
    } else {
      // If the item doesn't exist in the map, add it
      map.set(id, { ...item });
    }
  }

  // Convert the map values back to an array
  mergedArray.push(...map.values());

  return mergedArray;
}

export const replaceNullWithNA = (obj) => {
  const newObj = {};
  for (const key in obj) {
    newObj[key] =
      obj[key] === null || obj[key] === "" || obj[key] === undefined
        ? "N.A."
        : obj[key];
  }
  return newObj;
};

export const calculateTotalPages = (totalEntries, entriesPerPage) => {
  if (totalEntries <= 0 || entriesPerPage <= 0) {
    return 0; // Handle invalid input
  }
  return Math.ceil(totalEntries / entriesPerPage);
};

//Capitaise function after the text is in Lower case
export const capitalizeString = (inputString) => {
  if (inputString !== null || inputString !== undefined) {
    const words = inputString?.split(" ");
    const capitalizedWords = words?.map((word) => {
      if (word.length === 0) {
        return ""; // Skip empty words
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords?.join(" ");
  }
  return inputString;
};

export const capitalizeStringUpdated = (inputString) => {
  if (inputString !== null || inputString !== undefined) {
    inputString = inputString?.toLowerCase();
    const words = inputString?.split(" ");
    const capitalizedWords = words?.map((word) => {
      if (word.length === 0) {
        return ""; // Skip empty words
      }
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return capitalizedWords?.join(" ");
  }
  return inputString;
};

export const groupByKey = (arrayOfObjects) => {
  const groupedObjectsMap = {};

  arrayOfObjects.forEach((obj) => {
    const { cmName, ...rest } = obj;

    if (!groupedObjectsMap[cmName]) {
      groupedObjectsMap[cmName] = [{ cmName, ...rest }];
    } else {
      groupedObjectsMap[cmName].push({ cmName, ...rest });
    }
  });
  // //console.log("hi", groupedObjectsMap);
  const allWarehousesArray = Object.keys(groupedObjectsMap).map((key) => {
    const allWarehouses = groupedObjectsMap[key].map(
      ({
        originName,
        externalCode,
        warehouseScore,
        originLocation,
        fundedBagsCount,
        fundedNetWeight,
        cmName,
      }) => ({
        cmName,
        originName,
        externalCode,
        warehouseScore,
        location: originLocation,
        bagCount: fundedBagsCount,
        quantity: fundedNetWeight,
      })
    );

    return { [key]: allWarehouses };
  });
  // //console.log("allWarehousesArray", allWarehousesArray);
  return allWarehousesArray;
};
