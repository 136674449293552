import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  analyticsLoading: false,
  barChartLoading: false,
  doughnutChartLoading: false,
  quantityInsightsLoading: false,
  warehouseInsightsLoading: false,
  portfolioLineChartLoading: false,
  inspectionsLineChartLoading: false,
  quantityLineChartLoading: false,
  mapChartLoading: false,
  commoditiesLoading: false,
  warehouseScore: null,
  warehousesData: null,
  commoditiesData: null,
  portfolioLineChartData: null,
  inspectionsLineChartData: null,
  quantityLineChartData: null,
  quantityInsightsData: null,
  warehouseInsightsData: null,
  doughnutChartData: null,
  analyticsData: null,
  barChartData: null,
  mapChartData: null,
  error: null,
};

const analyticsDashboardSlice = createSlice({
  name: "analyticsDashboard",
  initialState,
  reducers: {
    fetchAnalyticsData(state, action) {
      state.analyticsLoading = true;
    },
    fetchAnalyticsDataSuccess(state, action) {
      state.analyticsData = action.payload;
      state.analyticsLoading = false;
    },
    fetchAnalyticsDataFailure(state, action) {
      state.analyticsLoading = false;
      state.error = true;
    },
    fetchBarChartData(state, action) {
      state.barChartLoading = true;
    },
    fetchBarChartDataSuccess(state, action) {
      state.barChartData = action.payload;
      state.barChartLoading = false;
    },
    fetchBarChartDataFailure(state, action) {
      state.barChartLoading = false;
      state.error = true;
    },
    fetchDoughnutChartData(state, action) {
      state.doughnutChartLoading = true;
    },
    fetchDoughnutChartDataSuccess(state, action) {
      state.doughnutChartData = action.payload;
      state.doughnutChartLoading = false;
    },
    fetchDoughnutChartDataFailure(state, action) {
      state.doughnutChartLoading = false;
      state.error = true;
    },
    fetchQuantityInsightsData(state, action) {
      state.quantityInsightsLoading = true;
    },
    fetchQuantityInsightsDataSuccess(state, action) {
      state.quantityInsightsData = action.payload;
      state.quantityInsightsLoading = false;
    },
    fetchQuantityInsightsDataFailure(state, action) {
      state.quantityInsightsLoading = false;
      state.error = true;
    },
    fetchWarehouseInsightsData(state, action) {
      state.warehouseInsightsLoading = true;
    },
    fetchWarehouseInsightsDataSuccess(state, action) {
      state.warehouseInsightsData = action.payload;
      state.warehouseInsightsLoading = false;
    },
    fetchWarehouseInsightsDataFailure(state, action) {
      state.warehouseInsightsLoading = false;
      state.error = true;
    },
    fetchPortfolioLineChartData(state, action) {
      state.portfolioLineChartLoading = true;
    },
    fetchPortfolioLineChartDataSuccess(state, action) {
      state.portfolioLineChartData = action.payload;
      state.portfolioLineChartLoading = false;
    },
    fetchPortfolioLineChartDataFailure(state, action) {
      state.portfolioLineChartLoading = false;
      state.error = true;
    },
    fetchInspectionsLineChartData(state, action) {
      state.inspectionsLineChartLoading = true;
    },
    fetchInspectionsLineChartDataSuccess(state, action) {
      state.inspectionsLineChartData = action.payload;
      state.inspectionsLineChartLoading = false;
    },
    fetchInspectionLineChartDataFailure(state, action) {
      state.inspectionsLineChartLoading = false;
      state.error = true;
    },
    fetchQuantityLineChartData(state, action) {
      state.quantityLineChartLoading = true;
    },
    fetchQuantityLineChartDataSuccess(state, action) {
      state.quantityLineChartData = action.payload;
      state.quantityLineChartLoading = false;
    },
    fetchQuantityLineChartDataFailure(state, action) {
      state.quantityLineChartLoading = false;
      state.error = true;
    },
    fetchMapChartData(state, action) {
      state.mapChartLoading = true;
    },
    fetchMapChartDataSuccess(state, action) {
      state.mapChartData = action.payload;
      state.mapChartLoading = false;
    },
    fetchMapChartDataFailure(state, action) {
      state.mapChartLoading = false;
      state.error = true;
    },
    fetchCommoditiesData(state, action) {
      state.commoditiesLoading = true;
    },
    fetchCommoditiesDataSuccess(state, action) {
      state.commoditiesData = action.payload;
      state.commoditiesLoading = false;
    },
    fetchCommoditiesDataFailure(state, action) {
      state.commoditiesLoading = false;
      state.error = true;
    },
    fetchWarehouses(state, action) {
      state.loading = true;
    },
    fetchWarehousesSuccess(state, action) {
      state.warehousesData = action.payload;
      state.loading = false;
    },
    fetchWarehousesFailure(state, action) {
      state.loading = false;
    },
    fetchWarehouseScore(state, action) {
      state.loading = true;
    },
    fetchWarehouseScoreSuccess(state, action) {
      state.warehouseScore = action.payload.warehouseScore;
      state.loading = false;
    },
    fetchWarehouseScoreFailure(state, action) {
      state.loading = false;
    },
    clearState(state, action) {},
    clearStateSuccess(state, action) {
      state.warehouseScore = null;
      state.warehousesData = null;
      state.commoditiesData = null;
      state.portfolioLineChartData = null;
      state.inspectionsLineChartData = null;
      state.quantityLineChartData = null;
      state.quantityInsightsData = null;
      state.warehouseInsightsData = null;
      state.doughnutChartData = null;
      state.analyticsData = null;
      state.barChartData = null;
      state.mapChartData = null;
    },
  },
  clearStateFailure(state, action) {},
});

export const { actions, reducer, name: sliceKey } = analyticsDashboardSlice;
